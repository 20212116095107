
export class FXPulseContext {

    FOREIGN_EXCHANGE_TOGGLE: boolean = false;
    IS_FX_PULSE_USER: boolean = false;
    cachedRateResponse?: any;

    setFOREIGN_EXCHANGE_TOGGLE(FOREIGN_EXCHANGE_TOGGLE: boolean){
        this.FOREIGN_EXCHANGE_TOGGLE = FOREIGN_EXCHANGE_TOGGLE;
    }
     
    getFOREIGN_EXCHANGE_TOGGLE(): boolean{
        return this.FOREIGN_EXCHANGE_TOGGLE;
    }

    setIS_FX_PULSE_USER(FX_PULSE_USER: boolean){
        this.IS_FX_PULSE_USER = FX_PULSE_USER;
    }
     
    getIS_FX_PULSE_USER(): boolean{
        return this.IS_FX_PULSE_USER;
    }

    setCachedRateResponse(cachedRateResponse: any){
        this.cachedRateResponse = cachedRateResponse;
    }
     
    getCachedRateResponse(): any{
        return this.cachedRateResponse;
    }
    
    resetToggle() {
        this.IS_FX_PULSE_USER = false;
        this.FOREIGN_EXCHANGE_TOGGLE = false;
    }
}

const __instance__ = new FXPulseContext();
export default __instance__;