import LeftMenuAccordion from "../../ui-kit/left-menu-accordion/left-menu-accordion.component";
import { useEffect } from 'react';
import { focusToElementWithid } from '../../utils/common.util';
import kLEFT_MENU from '../../common/constants/LEFT_MENU';

const CookiePolicy = () => {

    const cookiePolicy = [
        {
            header: kLEFT_MENU.COOKIE_POLICY,
            content: kLEFT_MENU.COOKIE_POLICY_LINK.COOKIE_POLICY
        }
    ];


    useEffect(() => {
        //focus to privacy-statement-accordion on page load
        const header: HTMLElement | null = document.querySelector('.citi-accordion');
        if (header) {
            focusToElementWithid('accordion-item-header-container-0', 400);
        }
    }, [])

    const createUI = () => {
        return <>
            <LeftMenuAccordion accordionContent={cookiePolicy} />
        </>
    }
    return createUI();
}

export default CookiePolicy;