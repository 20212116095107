import './count-label.style.scss';

interface Props {
    count: number | string;
    height?: string | number;
    width: string | number;
    fontSize: string | number;
    className?: string;
    ariaLabel?: string;
    ariaHidden?: boolean;
    role?: string;
    tabIndex?: number;
    onClick?: React.MouseEventHandler;
}

const CountLabel = (props: Props) => {
    const {
        count,
        height,
        width,
        fontSize,
        className,
        ariaLabel,
        ariaHidden,
        role,
        tabIndex,
        onClick
    } = props;

    const _labelStyle = () => {
        return {
            height: height,
            width: width,
            fontSize: fontSize,
            lineHeight: height
        }
    }

    return <span
        className={`count-label ${className}`}
        aria-label={ariaLabel}
        aria-hidden={ariaHidden}
        role={role}
        tabIndex={tabIndex}
        style={{ ..._labelStyle()}}
        onClick={onClick}>
        {
            count
        }
    </span>
}

export default CountLabel;