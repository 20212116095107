const Constants = {
    APP_VERSION: "17_0_0",

    //Header
    WELCOME_1: 'CitiBusiness® Online Mobile',
    WELCOME_HEADER_1: 'CitiBusiness® Online Mobile',
    WELCOME_CDCB_HEADER_1: 'CitiDirect® Commercial Banking',
    FORGOT_PIN_TITLE: "Forgot PIN",

    //MFA
    REGISTER_A_TOKEN: "Register a Token",
    LOG_IN_TO_BANKING: "Log in to \nCitiBusiness® Online Mobile Banking",

    //Input
    INPUT_ENTER_BUSINESS_CODE: "Business Code",
    INPUT_ENTER_USER_ID: "User ID",
    PASSWORD_EXPIRED_MESSAGE:
        "For security purposes please create a new password",
    TEMPORARY_PASSWORD_MESSAGE:
        "You signed in with a temporary password, please create a new password to proceed",
    CONDITIONS_APPLY: "The following conditions apply:",
    INPUT_ENTER_PASSWORD: "Password",
    INPUT_CURRENT_PASSWORD: "Current Password",
    INPUT_CURRENT_OR_TEMP_PASSWORD: "Current/Temporary Password",
    INPUT_ENTER_NEW_PASSWORD: "New Password",
    INPUT_RE_ENTER_NEW_PASSWORD: "Re-Enter New Password",

    INPUT_ENTER_OTP1: "One-Time Password (OTP1)",
    INPUT_ENTER_PASSCODE: "Enter Passcode",
    INPUT_ENTER_VERIFICATION_CODE: "Enter Verification Code",
    INPUT_CREATE_A_PIN: "Create a New 6-digit alphanumeric PIN",
    INPUT_RETYPE_PIN: "Re-type the new PIN",

    //Register Token
    REGISTER_TOKEN: "Register Token",
    REGISTER_TOKEN_DONE: "You’re Almost done!",
    REGISTER_TOKEN_ENTER_CITI_INFO_TITLE:
        "Enter your CitiBusiness® Online information",
    REGISTER_TOKEN_VERIFICATION_CODE:
        "A verification code has been sent to you via email. Please enter that code below.",
    CREATE_A_PIN: "Create a PIN",
    REGISTER_TOKEN_SUCCESS: "Your Mobile Token has been successfully registered",
    FORGOT_PIN_SUCCESS: "You have successfully created a new PIN",
    VERIFY_TOKEN: {
        KEEP_ALIVE_TIME_OUT: 9,
    },
    EXCEED_ATTMPTS_ERROR:
        "You have exceeded the number of attempts allowed. Please restart the registration process.",
    REGISTER_TOKEN_AVAILABLE_TOKEN_WARNING:
        "This is your last available Mobile Token Registered instance, Once signed in, please go to 'Mobile Token Management' page within the app to delete the no longer used mobile token instances.",
    REGISTER_TOKEN_AVAILABLE_TOKEN_WARNING_9:
        "This is your 9th available Mobile Token Registered instance, Once signed in, please go to 'Mobile Token Management' page within the app to delete the no longer used mobile token instances.",
    REGISTER_TOKEN_AVAILABLE_TOKEN_WARNING_10:
        "This is your 10th available Mobile Token Registered instance, Once signed in, please go to 'Mobile Token Management' page within the app to delete the no longer used mobile token instances.",
    VERIFICATION_CODE: "Verifcation Code",

    //Generate Token
    GENERATE_TOKEN_SWITCH_MOBILE_TOKEN: "Mobile Tokens",
    GENERATE_TOKEN_SWITCH_MOBILE_APP: "Mobile App",
    GENERATE_TOKEN_SWITCH_NOTIF: "Notifications",
    //CitiBusiness® Online
    GENERATE_TOKEN_MOBILE_APP_TITLE_1:
        "Log in to",
    GENERATE_TOKEN_MOBILE_APP_TITLE_2: 'CitiBusiness® Online Mobile Banking',
    GENERATE_TOKEN_CDCB_MOBILE_APP_TITLE_1:
        "Log in to",
    GENERATE_TOKEN_CDCB_MOBILE_APP_TITLE_2: 'CitiDirect® Commercial Banking',
    //change pin
    CHANGE_PIN_OLD_PIN: "Current 6-digit alphanumeric PIN",
    CHANGE_PIN_NEW_PIN: "New 6-digit alphanumeric PIN",
    CHANGE_PIN_REENTER_NEW_PIN: "Re-type new PIN",
    CHANGE_PIN_BUTTON_CHANGE_PIN: "Change PIN",
    CHANGE_PIN_BUTTON_CHANGING_PIN: "PIN Change in progress...",
    CHANGE_PIN_SUCCESS_MESSAGE: "PIN Successfully Changed.",
    CHANGE_PIN_PAGE: {
        ERRORS: {
            PINS_MATCH: "New and Confirm PINs must match",
            OLD_PIN_REQUIRED: "old PIN required",
            NEW_PIN_REQUIRED: "new PIN required",
            VERIFY_PIN_REQUIRED: "Verify PIN required",
            VALID_ALPHA_NUMERIC_PIN: "Enter a valid 6 digit alphanumeric PIN",
        },
    },

    //Sign On
    SIGN_IN: "Sign In",
    LOG_IN: "Log In",
    REMEMBER_CODE_AND_ID: "Remember Code and ID",

    //Log out
    LOG_OUT: "Log Out",

    //Forgot Password
    FORGOT_PASSWORD: "Forgot Password",
    SWITCH_ACCOUNTS: "Switch Accounts",
    FORGOT_PASSWORD_TIP: "To reset your password, please call\n 1-800-285-1709",

    //enter passcode
    GENERATE_ENTER_PASSCODE:
        "For token user, please generate and enter OTP1.\nFor new user or non-token user, please check your verified primary email for passcode.",

    //OTP1 or Enter Passcode
    OTP1_OR_ENTER_PASSCODE: "OTP1 or Enter Passcode",

    //Send Email for forgot password
    SEND_EMAIL_PASSWORD:
        "Your temporary password will be sent to the following email address:",
    SEND_EMAIL_PASSCODE:
        "Your passcode has been sent to the following email address:",
    SEND_EMAIL_SUCCEED:
        "Your temporary password has been sent to your email address.",

    //Error message
    ERROR_INCORRECT_INFORMATION:
        'The information provided does not match our records. Please contact customer service at <a href="tel:18002851709" >1(800) 285 1709</a> for further assistance.',
    INVALID_BCODE: "Invalid Business Code",
    INVALID_USER_ID: "Invalid User Id",
    INVALID_OTP1: "Invalid OTP1",
    INVALID_VERIFICATION_CODE: "Invalid Verification Code. Please re-enter.",
    ERROR_PIN_NOT_MATCH: "The two PINs you entered do not match",
    ERROR_NO_NETWORK_CONNECTION: "No network connection....",
    ERROR_LOGIN_EXCEPT_MAXIMUM_BAD_PASSWORD_1:
        "Please check to ensure you have entered your credentials accurately. If this problem persists, try again or use",
    ERROR_LOGIN_EXCEPT_MAXIMUM_BAD_PASSWORD_2: " Forgot Password",
    ERROR_LOGIN_EXCEPT_MAXIMUM_BAD_PASSWORD_3:
        ". For assistance, contact your System Administrator or Customer Service at (800) 285 1709. For speech and/or hearing impaired callers, we accept 711 and other Relay Service calls.",
    ERROR_INCORRECT_EMAIL_FORMAT: "Enter correct email format",
    ERROR_CONFIRM_EMAIL_MISMATCH: "Confirm Primary Email cannot be different",
    ERROR_NEW_USER_REGISTRATION_NOT_COMPLETE: "Please Sign On through CitiBusiness Online Full Site Version for completing New User Registration.",

    //Session Suspended
    SESSION_SUSPENDED: "Session Suspended",
    SESSION_SUSPENDED_INFORMATION:
        "Your CitiBusiness® Online session has been suspended as it is launched in another tab. Please access the application in the other tab.",
    SESSION_SUSPENDED_INFORMATION_LOGIN:
        "Your account is locked due to maximum number of failed verification code challenges. Please contact your Citibank Representative or Customer Service at (800) 285 1709 for assistance. For TTY: We accept 711 or other Relay service.",
    SESSION_SUSPENDED_INFORMATION_WIRE_SETUP:
        "An error has occurred processing your request. Please contact Customer Service at (800) 285 1709 or your Citibank Representative for assistance. For hearing impaired call (800) 788 0002.",
    
    //Network
    NO_NETWORK: "noNetwork",

    RESET_PASSWORD: "Reset Password",
   
    //Bottom Navigation
    NAVIGATION: {
        HOME: "Home",
        ACCOUNTS: "Accounts",
        APPROVALS: "Approvals",
        PAYMENTS: "Payments",
        DEPOSITS: "Deposits",
        DEPOSITS_MAP: "mobileCheckDeposit",
    },    

    //Card for Home screen, Loans, Foreign Exchange
    NAVIGATION_CARD: {
        LOANS: {
            title: "Loans",
            details: "View Loans information",
        },
        FOREIGN_EXCHANGE: {
            title: "Foreign Exchange",
            details: "View rates for all foreign transactions",
        },
        CASH: {
            title: "Cash Accounts",
            details: "View Home page",
        }
    },    

    // TMX Challenge
    TMX_CHALLENGE_HEADER: "Confirm Your Identity",
    // TMX_CHALLENGE_CONTENT_1: 'In order to provide you with extra security, we occasionally need to ask for additional information when you use certain features in CitiBusiness® Online.',
    TMX_CHALLENGE_CONTENT_2:
        "If you are having problems completing this process, please call Customer Service at (800) 285 1709. For hearing impaired call (800) 788 0002.",
    TMX_CHALLENGE_EMAIL: {
        CONTENT: "Please enter the Verification Code sent to ",
        LABEL: "Verification Code",
    },
    TMX_CHALLENGE_SESSION_SUSPENDED: "Session Suspended",
    TMX_SESSION_SUSPENDED_LOGIN_CONTENT:
        "Your account is locked due to maximum number of failed verification code challenges. Please contact your Citibank Representative or Customer Service at (800) 285 1709 for assistance. For TTY: We accept 711 or other Relay service.",
    TMX_SESSION_SUSPENDED_WIRE_CONTENT:
        "An error has occurred processing your request. Please contact Customer Service at (800) 285 1709 or your Citibank Representative for assistance. For hearing impaired call (800) 788 0002.",

    //Banking Enrollment
    ENROLLMENT_MESSAGE:
        "Our records indicate you do not have access to use CitiBusiness® Mobile Banking App. Please request entitlement for access from your security administrator.",
    ENROLLMENT_RETURN: "Return to Home",

    TOKEN_REQUIRED_CONTENT:
        "Keeping your contact phone number associated with your CitiBusiness® Online Profile up to date helps us to provide you with an improved, more secure digital experience. Having an up-to-date phone number allows us to contact you in case any suspicious activity is detected.\n\nPlease enter your One Time Password (OTP1) from your token in order to verify your phone number. If you do not have your token now, select cancel to do this later.\n\n",
    TOKEN_REQUIRED_DAYS:
        "days left to update your phone number after which time you will not be able to access CitiBusiness® Online.",
    TOKEN_REQUIRED_INPUT_LABEL: "Enter OTP1 Token",
    TOKEN_VERIFE_FAILED: "The information you have entered is not correct.",

    // change your password
    CHANGE_YOUR_PASSWORD: {
        CHARACTER_SIX_TO_TEN_LIMIT: "Must be between 6 - 10 characters",
        CHARACTER_LIMIT: "Must be between 8 - 10 characters",
        NUMBER_LIMIT: "Must contain at least 1 number",
        UPPERCASE_LIMIT: "Must contain at least 1 upper case letter",
        LOWERCASE_LIMIT: "Must contain at least 1 lower case letter",
        IDENTICAL_CHARACTER_LIMIT:
            "Cannot have more than 2 consecutive identical characters",
        SPACES_LIMIT: "Cannot contain spaces",
        // CONTAIN_SPECIAL_CHARACTER: 'Must contain at least one special character:\n~ ` ! @ # $ ^ & * ( ) _ - \\ / |',
        CONTAIN_SPECIAL_CHARACTER:
            "Must contain at least one special character:\n~ ` ! @ # $ ^ * ( ) _ - / |",
        SPECIAL_CHARACTER_OR_SPACES_LIMIT:
            "Cannot contain special characters or spaces",
        PASSWORD_MATCH: "New passwords must match",
        PASSWORD_MATCH_LAST_6_PASSWORDS_LIMT:
            "Passwords are case sensitive and cannot match any of your last 6 passwords",
    },

    //Message on Header
    MESSAGE_TYPE: {
        MESSAGE: "CITI_MESSAGE",
    },

    //Signin Home
    VIEW_ONLY_MODE: "You are in View-Only mode",
    ENTER_TOKEN:
        "Please enter an OTP token during log-in to access full features.",

    //Actions
    QUICK_ACTIONS: "Quick Actions",
    TRANSACTIONS_APPROVALS: "Transactions Awaiting Approval",
    POSITIVE_PAY_EXCEPTION: "ARP Positive Pay Exceptions",
    SYSTEM_ADMIN_APPROVALS: "System Admin Approvals",
    ARP_DISPOSITIONED_SUMMARY: "ARP Positive Pay Dispositioned Summary",

    INSTANT_PAYMENTS: "Instant Payments",
    INSTANT_PAYMENTS_INITIATE: "Instant Payments Initiate",
    INSTANT_PAYMENTS_TRANSACTION_DETAILS: "Instant Payments Transaction Details",


    DEPOSIT_CHEQUE: {
        ERROR_ENTITLEMENT_HEADER:
            "Sorry! Looks like you’re not entitled to use CitiBusiness® Mobile Deposit yet",
        ERROR_ENTITLEMENT_EROR_DESCRIPTION:
            "Please reach out to your Citi Representative to sign up and start depositing anytime and anywhere within the U.S.”",
        EXTRA_INFO_2:
            "You can now deposit your checks in the Citibusiness Mobile App.",
        EXTRA_INFO: "DID YOU KNOW?",
        DISCLAIMER_DESC:
            "© 2021 Citigroup Inc. Citibank, N.A. Member FDIC. Equal Credit Opportunity Lender. All rights reserved. Citi, Citi and Arc Design and other marks used herein are service marks of Citigroup Inc. or its affiliates, used and registered throughout the world.",
        DISCLAIMER_DESC_2:
            "Please see terms and conditions related to Remote Check Deposit here",
    },

    BUILD_CONSTANTS: {
        ENV: {
            DEV: "development",
            TEST: "test",
            PROD: "production",
        },
        CITI_ENV: {
            PROD: "https://mobile.citibusinessonline.com",
            RELATIVE: "/",
        },
    },

    REMOTE_CHECK_DEPOSITS: {
        BTN_COMPLETE_DEPOSIT: "Complete Deposit",
        BTN_CANCEL: "Cancel",
        BTN_SELECT_AN_ACCOUNT: "Select a Account",
        BTN_TAP_TO_CHANGE: "Tap to Change Account",
        TAP_TO_TAKE_PHOTO: "Tap to take photo of your check",
        MENU_DEPOSIT_CHECK: "Deposit Check",
        MENU_DEPOSIT_HISTORY: "Deposit History",
        LABEL_DEPOSIT_TO: "Deposit to",
        LABEL_CHECK_IMAGE: "Check Image",
        LABEL_ENTER_AMOUNT: "Enter Amount",
        LABEL_MEMO: "Memo (Optional)",

        CHECK_DEPOSIT_SUCCESS_MODAL_TEXT: "Your check has been deposited.",
    },

    POSITIVE_PAY_EXCEPTIONS: {
        NON_DISPOSITIONED_MESSAGE: 'There are no exception items for today.',
        DISPOSITIONED_MESSAGE: 'No items have been dispositioned today.'
    },

    CURRENCY_CONVERTER: {
        FROM: 'From',
        TO: 'To',
        AMOUNT: 'Amount',
        CALCULATE: 'Calculate',
        EXCHANGE_FROM_TO_CURRENCY: 'Exchange From and To Currency',
        SELECT_FROM_CURRENCY: 'Select From Currency',
        SELECT_TO_CURRENCY: 'Select To Currency',
    }
};

export default Constants;