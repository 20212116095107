import _ from "lodash";
import __constants from '../../../common/constants';

export function parseMessageData(data: Array<{ [key: string]: any }>): string {
    let count = '';

    _.each(data, (val) => {
        if (val.notificationType === __constants.MESSAGE_TYPE.MESSAGE && _.isNumber(val.notificationCount)) {

            count = val.notificationCount.toString();
            if (count.length > 2) {
                count = '99+';
            }
        }
    });

    return count;
}