import React, { ReactNode, useState } from 'react';
import AccordionItem from './accordion-item.component';
import './accordion.style.scss'

export interface AccordionItemContent {
    header?: ReactNode | string;
    content?: ReactNode | string;

    htmlHeader?: string;
    htmlContent?: string;
}

interface Props {
    content: Array<AccordionItemContent>
    contentClass?: string;
}


export const RAccordion = (props: Props) => {

    const [activeItemIndex, setActiveItemIndex] = useState(-1);

    const accordionItemClick = (itemIndex: number) => {
        const _activeItemIndex = (itemIndex === activeItemIndex) ? -1 : itemIndex;
        setActiveItemIndex(_activeItemIndex);
    }

    const accordionItems = () => {
        return props.content.map((item: AccordionItemContent, index: number) => {
            const isActive = (index === activeItemIndex);
            const accordionItem = item;
            return <AccordionItem key={index} isActive={isActive} accordionItem={accordionItem}
                contentClass={props.contentClass}
                index={index}
                onClick={accordionItemClick}
            />
        });
    }

    return (<>
        <div className="citi-accordion">
            {
                accordionItems()
            }
        </div>
    </>);

}