import { DEVICE_TYPE } from "./xdevice";


/**
 * This method is called by the native as soon as javascript interface in 
 * native is attached to the WebView
 * This method requires data, about the app
 *  - appVersion
 *  - deviceOs
 *  - model
 */
export const ACTIVATE_DEVICE_WORKER = (d) => {
    setInterval(() => { UPDATE_DEVICE_CONFIG(d) }, 9 * 1000);
}

/**
 * Spread out data
 * @param {d} d initial configuration data
 */
const UPDATE_DEVICE_CONFIG = (d) => {

    console.info('update native data')

    const majorVersion = Number(d.majorVersion);

    window.DEVICE_CONFIG_DATA = { ...d, majorVersion };

    if (d.os !== undefined) {
        if (d.mobileOs === 'Android') {
            window.THE_DEVICE_TYPE = DEVICE_TYPE.ANDROID;
        }
        else if (d.mobileOs === 'ios') {
            window.THE_DEVICE_TYPE = DEVICE_TYPE.IOS;
        }
    }

}