import http from './api.core';
import { RequestMethod } from './api.core';
import kCONSTANTS from "../common/constants/GENERAL";
import kEVENT_TRACKING from "../common/constants/EVENT_TRACKING";
import { AxiosResponse } from 'axios';
// import 
let MOCKED_API: any = undefined;
//  from './mocks/mocks';

export interface PreLoginResponse {

}

export class PreLoginApi {

    constructor() {
        const isProd = (process.env.NODE_ENV === 'production');
        const m = require(isProd ? './mocks/prod.mock.ts' : './mocks/mocks.ts').default
        // const m = require(isProd ? './mocks/prod.mock.ts' : './mocks/mocks.copy.ts').default
        MOCKED_API = m
    }

    preLogin(callBack?: Function) {

        return http.makeHttpRequest(
            RequestMethod.GET,
            `preLogin/${kCONSTANTS.APP_VERSION_DIR}/${kCONSTANTS.LANG}`,
            null,
            null,
            callBack,
            kEVENT_TRACKING.ChannelEnum.PRE_LOGIN, "preLogin",
            MOCKED_API?.PRE_LOGIN) as Promise<AxiosResponse<PreLoginResponse>>;
    }

}

const __instance__ = new PreLoginApi();

export default __instance__;
