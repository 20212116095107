
export class WireInitiateContext {

    countries?: Array<{ [key: string]: any; }>;
    internationalCountries?: Array<{ [key: string]: any; }>;
    cashedCountries?: Array<{ [key: string]: any; }>;
    abaDetailResp?: Array<{ [key: string]: any; }>;
    userInfo?: { [key: string]: any; } | null = {};
    fxWiresInitiateToggle?: boolean = false;
    irsFedTaxPayToggle?: boolean = false;

    setIRSFedTaxPayToggle(irsFedTaxPayToggle: boolean) {
        this.irsFedTaxPayToggle = irsFedTaxPayToggle
    }
    getIRSFedTaxPayToggle() {
        return this.irsFedTaxPayToggle;
    }


    setFxWiresInitiateToggle(fxWiresInitiateToggle: boolean) {
        this.fxWiresInitiateToggle = fxWiresInitiateToggle;
    }

    getFxWiresInitiateToggle() {
        return this.fxWiresInitiateToggle;
    }

    setCountries(countries: any) {
        this.countries = countries;
    }

    getCountries(): any {
        return this.countries;
    }

    setInternationalCountries(internationalCountries: any) {
        this.internationalCountries = internationalCountries;
    }

    getInternationalCountries(): any {
        return this.internationalCountries;
    }

    setCashedCountries(cashedCountries: any) {
        this.cashedCountries = cashedCountries;
    }

    getCashedCountries(): any {
        return this.cashedCountries;
    }

    setABADetailResp(abaDetailResp: any) {
        this.abaDetailResp = abaDetailResp;
    }

    getABADetailResp(): any {
        return this.abaDetailResp;
    }

    setUserInfo(newUserInfo: { [key: string]: any }) {
        this.userInfo = { ...this.userInfo, ...newUserInfo };
    }

    getUserInfo(): any {
        return this.userInfo;
    }

    clearContext() {
        this.fxWiresInitiateToggle = false;
        this.userInfo = undefined;
    }
}

const __instance__ = new WireInitiateContext();
export default __instance__;