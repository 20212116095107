const kLEFT_MENU = {
    CONTACT_US: "Contact Us",
    FAQS: "Frequently Asked Questions",
    USER_AGREEMENT: "CitiBusiness® Online User Agreement",
    TERM_OF_USE: "Mobile Terms of Use",
    PRIVACY_STATEMENT: "Privacy Statement",
    CHANGE_PASSWORD: "Change Your Password",
    MOBILE_TKN_MANAGEMENT: "Mobile Token Management",
    SYSTEM_ADMIN: "System Administration",
    MANAGE_COOKIE_PREFERENCES: "Manage Cookie Preferences",
    COOKIE_POLICY: "Cookie Policy",
    PUSH_NOTIFICATION_STATUS: "Push Notification Device Status",

    LEFT_MENU_MAP: {
        USER_ADMIN: "User Administration",
        TOKEN_MANAGEMENT: "Mobile Token Management",
        SYSTEM_ADMIN: "System Admin Functions",
        PUSH_NOTIFICATIONS_DEVICE_STATUS: "Push Notification Device Enrollment Status",
    },

    CONTACTUS_VERSION: "Version",

    //Privacy
    NOTICE_AT_COLLECTION: "Notice at Collection",
    CA_PRIVACY_HUB: "CA Privacy Hub",
    PRIVACY_LINKS: {
        PRIVACY_STATEMENT: "https://icg.citi.com/icghome/privacy", //original link - https://www.citibank.com/commercialbank/en/privacy.html'
        NOTICE_AT_COLLECTION:
            "https://icg.citi.com/rcs/icgPublic/storage/public/ICG-Global-PN-California-Supplement.pdf", //original link - https://www.citibank.com/commercialbank/en/privacy.html#information
        CA_PRIVACY_HUB: "https://online.citi.com/US/ag/dataprivacyhub/home",
    },
    VIEW_LINK: "View link on your Browser",

    //Cookie Policy
    COOKIE_POLICY_LINK: {
        COOKIE_POLICY: "https://www.citigroup.com/global/institutional-clients/cookiepolicy",
    },

    //Mobile Token Management
    MOBILE_TOKEN_MANAGEMENT: {
        HEADER: "Mobile Token Management",
        HEADER_2: "Mobile Token Registration Summary",
        HEADER_CURRENT_ACTIVE: "Currently Active",
        HEADER_AVAILABLE_USE: "Available for Use",
        NOTE_HEADER: "Please Note",
        NOTE_CONTENT:
            "You can only register 10 mobile token at one time. Please delete the registrations that you are no longer using.",
        BTN_VIEW_DETAILS: "View Details",
        BTN_CANCEL: "Close",
        BTN_DELETE_TOKEN: "Delete",
        HEADER_ACTIVE_REGISTRATIONS: "Mobile Token Active Registrations",
    },
}

export default kLEFT_MENU;