import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import CountLabel from '../../../ui-kit/count-label/count-label.component';
import _ from 'lodash';
import __req from '../../../api/common.api';
import { parseMessageData } from './message.util';
import { WAIT_FOR_IOS_CALLBACK } from '../../../api/api.core';
import { APP_LOCATIONS } from '../../../routes/routes';
import './message.style.scss';
import { Icon } from "@citi-icg-172888/icgds-react";


function MessageView() {
    const history = useHistory();

    const [count, setCount] = useState('');

    useEffect(() => {

        __req
            .messageCounts(_successMessageCounts)
            .then((response) => {
                if (response.status !== WAIT_FOR_IOS_CALLBACK)
                    _successMessageCounts(response.data)
                else
                    console.log('waiting for ios call', response)
            })
    }, []);

    function _successMessageCounts(resp: any) {
        const data = _.get(resp, 'notifications', null);
        if (data && _.isArray(data) && data.length > 0) {
            setCount(parseMessageData(data));
        }
    }

    function _handleMessageClicked() {
        history.push(APP_LOCATIONS.MessageLanding);
    }
    const role = count.length > 2 ? 'none' : 'button'

    return <div className='header-message-container'>
        <span
            className='header-message'
            onClick={_handleMessageClicked}>
            <Icon 
                type="email-o"
                tabIndex={count.length > 2 ? undefined : 0}
                aria-label={`${count.length > 2 ? undefined : `Notification, button`}`}
                aria-hidden={count.length > 2 ? true : false}
                role={role}
                // className="lmnicon lmnicon-envelope" 
                />
        </span>

        {
            count.length > 0
                ? <CountLabel
                    count={count}
                    height='0.9rem'
                    width='1.5rem'
                    fontSize='0.6rem'
                    className='header-message-count'
                    ariaLabel={`${count.length > 2 ? 'Messages, 99 plus new, button' : undefined}`}
                    ariaHidden={count.length > 2 ? false : true}
                    role={`${count.length > 2 ? 'button' : 'none'}`}
                    onClick={_handleMessageClicked}
                />
                : null
        }
    </div>
}

export default MessageView;