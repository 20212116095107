export function defaultData() {
    const data = {
        accept: "Accept",
        content1: "In this Agreement, the words &quot;bank&quot; and &quot;Citibank&quot;, &quot;we&quot;,&quot;us&quot; and &quot;our&quot;  mean  Citibank, N.A..&nbsp; The words  &quot;you&quot; and &quot;your&quot; mean the Citibank account owner or the owner's authorized representative(s) (each, a &quot;User&quot;).",
        content2: "A. What This Agreement Covers",
        content3: "This Agreement between you and Citibank governs the use of CitiBusiness&#174; Online, an electronic banking and information service that permits authorized Users to access eligible bank accounts and services through the Internet using a personal computer or compatible mobile device.",
        content4: "B. Separate Account Agreements; Statements",
        content5: "The accounts and services which you manage  or use through CitiBusiness Online may also be governed by separate agreements including, but not limited to, the CitiBusiness Client Manual and Supplements, Master Account Service and Terms Agreement, and the Citibank Client Manual - Consumer Accounts (applicable to linked personal accounts).&nbsp; From time to time, accounts and services provided by organizations other than Citibank may be accessible by you through CitiBusiness Online.&nbsp; Those accounts and services will be governed by separate agreements between you and the organization that holds the accounts and provides the services.",
        content6: "Instead of receiving a paper statement through the mail, you may choose to enroll in our paperless statement service when you manage your accounts through CitiBusiness Online.&nbsp; If you chose to use the paperless statement service, we will make your statement available to you and other Users through CitiBusiness Online.&nbsp; Paperless Statements will be deemed delivered to you when they are made available through CitiBusiness Online.&nbsp; This service is subject to separate terms and conditions that will be provided to you and accepted by your CitiBusiness Online System Administrator.&nbsp; The paperless statement option may not be available with all accounts.",
        content7: "C. User Materials",
        content8: "CitiBusiness Online services and the manner in which they will be provided are described within the CitiBusiness Online Enrollment Form, the CitiBusiness Online Maintenance Form, CitiBusiness Online Help (including terms applicable to access through a mobile device browser) and the terms and instructions appearing on a screen when using CitiBusiness Online (collectively, &quot;User Materials&quot;).&nbsp; The User Materials are an integral part of this Agreement.",
        content9: "D. Maintaining Accounts; Users",
        content10: "In order to use CitiBusiness Online, an open and active CitiBusiness checking account is required.&nbsp; You may also select other eligible accounts for access through CitiBusiness Online, including personal accounts under limited circumstances.&nbsp; Accounts eligible for access through CitiBusiness Online are subject to Citibank&rsquo;s approval.&nbsp; You agree to properly maintain these accounts, to comply with their specific terms and conditions, and to pay any related fees.&nbsp; You agree that access to personal accounts is provided for your convenience only and that you will maintain and use such accounts primarily for personal, family or household purposes.&nbsp; <b>Please Note:</b>  All messages on CitiBusiness Online can be read by any authorized User including those pertaining to a linked personal account.<br>Users are authorized by either account signers and System Administrators. Each User must have and maintain a verified email address in order to access and use CitiBusiness Online.",
        content11: "E. Bill Payment Service",
        content12: "When you pay a bill through CitiBusiness Online, you authorize Citibank to withdraw the necessary funds from the Citibank account you designate.&nbsp; You agree that you will instruct Citibank to make a withdrawal only when a sufficient balance is or will be available in your designated account at the time of withdrawal.&nbsp; Citibank will not be obligated to act on any withdrawal instruction from you if sufficient funds, including overdraft lines of credit, are not available in the account you designate.",
        content13: "Citibank processes an online bill payment request in one of two ways:",
        content14: "1. By transferring funds to the payee electronically",
        content15: "2. By sending a Citibank bank check through the U.S. mail to individual payees",
        content16: "If the payment is made <b>by check</b>, you should allow at least <b>5-7 business days</b> for the  payee to receive payment.&nbsp; Checks are mailed via USPS First-Class Mail.&nbsp; Please note that checks are mailed without postmark, as Citibank uses pre-printed envelopes with postal permit numbers for mailing.&nbsp; In addition, please be aware that many payees including government agencies - do not accept electronic payments initiated from online banking websites.&nbsp; Because payments to government agencies are commonly made by check (e.g., for payment of taxes), we recommend that you schedule your payments with this in mind as Citibank is not responsible for any fees you may incur as a result of a late delivery of a check through the postal system.",
        content17: "For an electronic funds transfer, we generally deduct the money from your account on the payment date you select when you set up the bill payment, and we electronically transmit the payment to the payee on that day; however, if you specify a date that falls on a Saturday, Sunday, or holiday, we will deduct the money from your account on that day and electronically transmit it to the payee on the next business day.",
        content18: "In the case of payments made by Citibank bank check, we generally deduct the money from your account on the payment date you select when you set up the bill payment and mail the check on that day (including Saturdays); However, if you specify a date that falls on a Sunday or holiday, we will deduct the money from your account on that day and mail the check on the next business day. ",
        content19: "Citibank is not responsible for postal delays or processing delays by the payee.",
        content20: "If there are insufficient funds available in your designated account on the day a bill payment is scheduled to be made, Citibank will automatically attempt to debit your designated account for five consecutive days.&nbsp; A message will be sent each day funds are insufficient.&nbsp; After the fifth attempt, the payment will be canceled.&nbsp; ",
        content21: "You can cancel a future bill payment through CitiBusiness Online or by writing or calling Citibank at the address/phone numbers listed in Section S of this Agreement.&nbsp; We must receive your notice by 11:59 p.m. Eastern Time of the day prior to the date you specified for such payment or transfer. We may ask you to confirm phone instructions in writing within two weeks. ",
        content22: "For details on current rules, policies and procedures for processing bill payments (including limitations on frequency and dollar amount of transactions) refer to CitiBusiness Online Help.",
        content23: "F. Wire Transfers; Remittance Transfers",
        content24: "These terms cover funds transfer requests initiated through CitiBusiness Online or Direct File Transmission (&quot;DFT&quot;) to transfer funds from your Citibank account(s) to an account at another institution including wire transfers and Remittance Transfers.",
        content25: "Federal law provides certain rights for &quot;Remittance Transfers&quot;.&nbsp;A Remittance Transfer is an electronic transfer of funds initiated from a consumer account by a consumer primarily for personal, family or household purposes to a designated recipient (beneficiary) in a foreign country scheduled.&nbsp; As noted throughout this Section F, different rules apply to Remittance Transfers.&nbsp; Also, when initiating Remittance Transfers Citibank will provide you with certain disclosures required by federal law at the time you initiate the transfer.&nbsp; To the extent the provisions of this Section F are inconsistent with the disclosures provided to you for a specific Remittance Transfer, the provisions of such disclosures shall govern. ",
        content26: "1. Authority to Transfer Funds",
        content27: "You authorize Citibank to execute and charge your account(s) for any requests for the transfer of funds, subject to any applicable limit as to amount or beneficiary - when such requests are given by you, and are executed in accordance with the procedures established by Citibank.&nbsp; You understand and acknowledge that Citibank has no obligation to execute any funds transfer request that is not initiated in accordance with such procedures.&nbsp; You further acknowledge that the acceptance and processing of a funds transfer request is subject to the terms and conditions stated in this Section F. ",
        content28: "2. Reliance by Citibank",
        content29: "Citibank will rely on the information you provide in making a funds transfer on your behalf.&nbsp; You understand that it is your responsibility to provide Citibank with accurate information regarding that transfer, including the account number of the beneficiary of the funds transfer (&quot;IBAN&quot;) and where applicable, the number (such as &quot;BIC&quot; or &quot;SORT&quot;) identifying the beneficiary institution, since payment will be processed based on the number(s) provided by you.&nbsp; Should you provide an incorrect account number and/or beneficiary institution identifier, you understand that any losses resulting from the funds being credited to the wrong account will be your responsibility.&nbsp; For certain Remittance Transfers, you may be required at the time you initiate the request to provide Citibank with information regarding the currency in which the beneficiary account is maintained.&nbsp; You understand and acknowledge that Citibank will rely on this information in executing your instructions in accordance with the provisions set forth in the &quot;Currency of Funds Transfer&quot; section of this Agreement.",
        content30: "3. Execution of a Funds Transfer Request",
        content31: "<b>a.&nbsp; Immediate Funds Transfer Request.</b>&nbsp; If your funds transfer request is received by Citibank on a day that is not a business day or on a business day after the established cut-off hour, as will be disclosed to you at the time you request a funds transfer, it will not be processed until the next business day.",
        content32: "<b>b.&nbsp; Scheduled or Recurring Future-Dated Funds Transfer Requests for a Domestic Wire Transfer and International Wire Transfers.</b>&nbsp; If the date of a scheduled or recurring future-dated funds transfer request falls on a day that is not a business day, you understand and agree that your request will be executed on the next business day.&nbsp; Citibank will make the determination to pay or reject your funds transfer request on the day on which it is executed based on the available balance in your account at the time the funds transfer request is processed.",
        content33: "<b>c.&nbsp; Note: Citibank does not accept new instructions for the execution of Scheduled or Recurring Future-dated Remittance Transfers.</b>&nbsp; Should this service resume, the dates on which these future transfers will be executed will be described in the disclosures that are provided to you  when you establish such a transfer or transfers in accordance with federal law.&nbsp; Please Note: Instructions for a scheduled or recurring future-dated international wire from a consumer account established before October 28, 2013 will continue to be processed provided there are no changes made by you to those instructions.",
        content34: "4. Erroneous Funds Transfer Request",
        content35: "If you make a funds transfer request (including a request to recall or amend a request) which is erroneous in any way, you agree to pay the amount of such funds transfer and all related fees, whether or not the error could have been detected by any security procedure, unless such payment is prohibited by applicable laws governing Remittance Transfers.",
        content36: "5. Rejection of Funds Transfer Request",
        content37: "We reserve the right to reject your funds transfer request without cause or prior notice.&nbsp; We may reject your request if the dollar value of one or more of your transfer requests exceeds your daily transfer limit, if you have insufficient available funds in your account for the amount of the funds transfer and applicable fee, if the information you provide in connection with that transfer is incomplete or unclear, if we are unable to confirm the identity or authority of the person providing the request, or if we are unable to fulfill your request for any other reason. If we reject a request for a funds transfer, you will be informed of the rejection during your online session or as soon thereafter as we have determined to reject the request by either delivering a message via CitiBusiness Online or by notification through other means.",
        content38: "With respect to requests for a) domestic wire transfers, b) international wire transfers from a business account, and c) scheduled or recurring international wire transfers from a consumer account set up prior to October 28, 2013, you understand and agree that: if we reject a request related to a scheduled or recurring funds transfer for any of the reasons set forth above 1) you will receive a message via CitiBusiness Online each day that the transfer request is rejected; 2) we will continue trying to execute your transfer request for four additional business days; and 3) if not executed after five attempts, that particular funds transfer request will be cancelled.",
        content39: "6. Transfer to Beneficiary Bank ",
        content40: "When you request Citibank to make a funds transfer, you must select a financial institution as the beneficiary bank (i.e., recipient bank) for the transfer.&nbsp; For funds transfers within the United States, the beneficiary bank must be a member of the Federal Reserve System or a correspondent bank of such a member, or a Clearing House Interbank Payment System (CHIPS) member.",
        content41: "You may request that the funds either be deposited into a particular account at the beneficiary bank or be held at the beneficiary bank for your beneficiary.  In either case, the beneficiary bank is responsible for following your instructions and for notifying the beneficiary that funds are available.  After the funds are transferred to the beneficiary bank, they become the property of the beneficiary bank.  The beneficiary bank is responsible to locate, identify, and make payment to your beneficiary.  If you identify a beneficiary by name and account number, the beneficiary&rsquo;s bank may pay the funds to the person identified by the account number, and Citibank&rsquo;s payment may be final even if the account number provided does not correspond to your beneficiary.  If the beneficiary bank is unable to locate an account, the funds may be returned.",
        content42: "Except as may be prohibited by applicable law for Remittance Transfers, any losses resulting from an incorrect account number or other misidentification of the beneficiary provided by you is your responsibility and not Citibank&rsquo;s.",
        content43: "7. Means of Transfer",
        content44: "You understand that Citibank uses a variety of banking channels and facilities to make funds transfers, but will ordinarily use electronic means.&nbsp; We may choose any conventional means that we consider suitable to transfer funds to your beneficiary.",
        content45: "You further understand and agree that, because we do not maintain banking relationships with every bank, Citibank sometimes uses one or more intermediary banks to transfer your funds to the beneficiary bank.&nbsp; After you execute your request through an intermediary bank, that bank is responsible to complete your request.",
        content46: "8. Funds Transfer System Laws and Rules",
        content47: "Except as otherwise provided in this Section F, you agree to be bound by all funds transfer system rules that apply to a funds transfer.&nbsp; You agree not to violate the laws or regulations of the United States, including without limitation, the economic sanctions administered by the U.S. Treasury Office of Foreign Asset Control.&nbsp; Funds transfers may involve one or more funds transfer systems, such as Fedwire or the Clearing House Interbank Payments Systems (CHIPS).&nbsp; In these cases, the rules of the appropriate funds transfer system(s) will apply along with applicable law.&nbsp; Subpart B of Regulation J and Article 4A of the Uniform Commercial Code apply to funds transfers made through Fedwire.&nbsp; Funds transfers made through CHIPS are governed by and subject to CHIPS Rules and Administrative Procedures and the laws of the state where your account is located including Article 4A of the Uniform Commercial Code.&nbsp; Remittance Transfers are also governed by federal Regulation E.",
        content48: "9. Currency of Funds Transfer ",
        content49: "Funds transfers to beneficiaries within the United States are made only in U.S. dollars.&nbsp; For Remittance Transfers, at the time you request a funds transfer, Citibank may ask you whether the account maintained at the beneficiary bank is held in U.S. dollars or in the local currency where the account is maintained.&nbsp; If you indicate that the account is held in U.S. dollars, your funds transfer must be made in U.S. dollars.&nbsp; If you indicate that the account is held in the local currency of the country where the account is maintained, Citibank will send the funds transfer in that local foreign currency unless the transfer in foreign currency is prohibited by local restrictions of the destination country.&nbsp; If you indicate that you do not know the currency in which the beneficiary account is held, you can choose to send the funds transfer in U.S. dollars or in the local currency of the country where the beneficiary account is held.&nbsp; You understand that Citibank has the right to rely on your statement as to the currency of the beneficiary account and that it will not be responsible for any error in the information it provides me concerning the applicable exchange rate and the amount of funds to be received by the beneficiary should your statement be incorrect and a currency conversion occurs after Citibank sends your funds transfer request.",
        content50: "When initiating funds transfers in a foreign currency, information regarding the applicable exchange rate will be provided to you at the time you initiate the transfer. For all funds transfers in a foreign currency, Citibank will convert your U.S. dollar payment to the local currency at Citibank’s exchange rate in effect at that time for similar transactions.  The exchange rate is based on the foreign currency selected and the amount at the time your instruction is set up. This rate is an indication of the rate that will be applied to the wire transfer when sent for processing. Rates are refreshed at regular intervals and may change based on the prevailing rate applicable upon release and processing of the wire transfer.  The exchange rate includes a commission to Citibank for exchanging the currency and is inclusive of any spread (or markup) above the price at which Citi may be able to transact, or has transacted, the foreign exchange conversion, purchase or sale with other counterparties, as such markup is determined by Citi taking into account its costs, its risks, its inventory strategy, its overall risk management strategies, and other business factors and objectives.",
        content51: "10. Indemnity",
        content52: "In consideration of the agreement by Citibank to act upon funds transfer instructions in the manner provided in this Section F, including where Citibank may attempt to amend or recall a funds transfer at your request, you agree to the fullest extent permitted by applicable law to indemnify and hold Citibank harmless from and against any and all claims, suits, judgments, executions, liabilities, losses, damages, costs, and expenses - including reasonable attorney's fees - in connection with or arising out of Citibank acting upon funds transfer instructions pursuant to this Section F.&nbsp; This indemnity shall not be effective to relieve and indemnify Citibank against its gross negligence, bad faith, or willful misconduct.",
        content53: "11. Delays, Non-Execution of Funds Transfer Request",
        content54: "While Citibank will handle a  funds transfer request as expeditiously as possible, you agree that Citibank will not be responsible for any delay, failure to execute, or mis-execution of your funds transfer request or delay in making the funds available to the beneficiary due to circumstances beyond the control of Citibank or any intermediary or beneficiary bank handling the transfer, including, without limitation, any inaccuracy, interruption, delay in transmission, or failure in the means of transmission of your funds transfer request to the bank or execution of such request by the bank, whether caused by strikes, power failures, equipment malfunctions, or delays caused by one or more institutions fraud screening procedures or compliance procedures for anti-money laundering, economic sanctions or similar laws.&nbsp; You further agree that that we may refuse to process or delay processing any request if it would violate any guideline, rule, policy or regulation of any government authority or funds transfer system.&nbsp;",
        content55: "<b>To the extent permitted by law, Citibank makes no warranties, express or implied, with respect to any matter in connection with any funds transfer.</b>",
        content56: "12. Cancellation or Amendment of Funds Transfer Request ",
        content57: "<b>a. Applicable to Remittance Transfers.</b>&nbsp; You have the right to cancel or amend your funds transfer request provided that you cancel or amend your request within thirty (30) minutes of your authorizing payment for that transfer.&nbsp; If you request a cancellation or amendment after this time, the provisions below applicable to all other funds transfer requests will apply.",
        content58: "<b>b. Applicable to all other Funds Transfer Requests.</b>&nbsp; You may cancel or amend your funds transfer request only if Citibank receives your request prior to your execution of the funds transfer request and at a time that provides Citibank with a reasonable opportunity to act upon that request.",
        content59: "If your funds transfer request has been executed by Citibank, you understand and agree that the request to recall or amend the funds transfer will be effective only with the voluntary consent of Citibank and the beneficiary bank.&nbsp; If you decide to recall or amend your funds transfer and your request has already been executed by Citibank, Citibank will first have to check with the beneficiary bank to determine whether or not the beneficiary bank will return your funds.&nbsp; If the beneficiary bank confirms that the funds are returnable and agrees to do so, once the funds are returned to Citibank by the beneficiary bank, Citibank will return the funds to you.",
        content60: "The amount that is returned to you may be less than you originally transferred because of service charges of the beneficiary bank and/or Citibank. Your returned funds will be in U.S. dollars.&nbsp; If your funds transfer was in a foreign currency, your returned funds will be in U.S. dollars at the exchange rate established by Citibank on the date of return.&nbsp; Citibank shall not be liable to you for any loss resulting from the failure of the beneficiary bank to accept a cancellation or amendment of your funds transfer request.",
        content61: "13. Claims; Limitation of Liability",
        content62: "<b>a. Applicable to Remittance Transfers:</b>&nbsp; If you believe there has been an error or you need more information about your funds transfer, you agree that you must contact Citibank within one hundred and eighty (180) days of the date indicated by Citibank that the funds would be available to the beneficiary of the transfer.&nbsp; At that time, you may be asked to provide the following information: 1) your name, address and account number; 2) the name of the person receiving the funds, and if you know it, his or her telephone number or address; 3) the dollar amount of the transfer; 4) the reference code for the transfer; and 5) a description of the error or why you need additional information.&nbsp; Citibank may also ask you to select a choice of remedy (credit to your account in an amount necessary to resolve the error or alternatively, a resend of the transfer in an amount necessary to resolve the error for those cases where bank error is found).&nbsp; Citibank will determine whether an error has occurred within ninety (90) days after you contacted the bank.&nbsp; If Citibank determines that an error has occurred, Citibank will promptly correct that error in accordance with the error resolution procedures under the Electronic Fund Transfer Act and federal Regulation E or in accordance with the laws of the state where your account is located as may be applicable.",
        content63: "In any event, an action or proceeding by you to enforce any obligation, duty or right under these terms must be commenced within one (1) year from the date that such cause of action accrues.&nbsp; In no event shall Citibank be responsible for any incidental or consequential damages or expenses arising in connection with your funds transfer request.",
        content64: "<b>b. Applicable to Domestic Wire Transfers, International Wire Transfers from a Business Account, and International Wire Transfers from a Consumer Account scheduled before October 28, 2013:</b>&nbsp; If you believe there has been an error or you need more information about your funds transfer, you agree that you must contact Citibank within thirty (30) days after you receive notification that your funds transfer request has been executed.&nbsp; If your funds transfer request was delayed or erroneously executed as a result of erroneous information provided by you, you understand that you may be responsible for the amount of that transfer and any associated fees.&nbsp; If your funds transfer request was delayed or erroneously executed as a result of Citibank&rsquo;s error, Citibank&rsquo;s sole obligation to you is to pay or refund such amounts as may be required under the Uniform Commercial Code Article 4A or by other applicable law.",
        content65: "Any claim for interest payable by Citibank shall be at Citibank&rsquo;s published savings account rate in effect within the state of the financial center of the account from which the funds transfer was made.",
        content66: "In any event, an action or proceeding by you to enforce any obligation, duty or right arising under this Section F  must be commenced within one (1) year from the date that such cause of action accrues.",
        content67: "In no event shall Citibank be responsible for any incidental or consequential damages or expenses arising in connection with any funds transfer request.",
        content68: "G. Electronic Instructions",
        content69: "When you use CitiBusiness Online to initiate a transfer of funds between your accounts or to a third party, to make transfers or payments (including wire  transfers), to make a bill payment, to borrow from or make a payment to your credit account, to apply for a new account or service, to terminate a service, or change the way an account or service operates, you authorize Citibank to treat your instructions through CitiBusiness Online as if they had been made in writing and signed by you.&nbsp; When you instruct Citibank to transfer funds at regularly scheduled intervals in the future, you authorize Citibank to treat each transfer as if separately authorized in writing and signed by you, and to withdraw the funds from the account you designated.",
        content70: "H. Security Procedures",
        content71: "CitiBusiness Online has been designed to minimize the possibility of fraud and error by placing the issuance of software, User Identification Number, Passwords and Tokens (collectively, &quot;Codes &quot;) under your control or those individuals authorized by you (See &quot;Authorizations,&quot; below).&nbsp; CitiBusiness Online has been designed so that it may be accessed only upon entry of valid Codes.&nbsp; You authorize Citibank to treat any instruction made on CitiBusiness Online with valid Codes as if the instructions had been made in writing and signed by the authorized User(s) to whom the Codes were issued.&nbsp; Unless there is substantial evidence to the contrary, Citibank records will be conclusive regarding any access to, or action taken through CitiBusiness Online.&nbsp; You are responsible for maintaining the confidentiality of the Codes and you will not allow any person (including another authorized User) to use the Codes issued to you.&nbsp; You accept responsibility for unauthorized access to the system by your employees, your associates or by third parties.&nbsp; You agree to inform Citibank promptly of any discrepancies that you discover.&nbsp; Citibank will therefore consider any access to the CitiBusiness Online system through use of valid Codes to be duly authorized, and Citibank will carry out any instruction given regardless of the identity of the individual who is actually operating the system.",
        content72: "You confirm that you have investigated the security measures employed by CitiBusiness Online and that you have instituted the proper controls for access to CitiBusiness Online through your computers and terminals.&nbsp; You confirm that the security system and controls are commercially reasonable and appropriate for the account owner.&nbsp; When you place an order for a funds transfer (including a wire transfer), Citibank may follow a security procedure established for your protection that may entail a telephone call or other required contact with or from you prior to acting upon your instructions.&nbsp; In certain instances, Citibank may also decline to act upon your instructions.&nbsp; Citibank may employ other controls to verify the identity of an authorized User as a condition to granting access including the collection and use of data that authenticates an authorized User or an authorized Users computer.&nbsp; You agree to these security procedures, and acknowledge that if contacted, either by telephone or electronically, you will act or respond in compliance with requests resulting from these security procedures and will be bound by any resulting transfer or decision not to act upon your instructions or to deny access to persons purporting to be an authorized User.",
        content73: "I.  Authorizations",
        content74: "You represent that each individual who has been issued the Codes and designated as an authorized User has general authority from you to give instructions within the access capability associated with such Codes (including general authority over the movement of your funds and over accounts with Citibank) and that Citibank has full authorization from the account owner to rely and act upon instructions identified by such Codes.",
        content75: "Multiple accounts owned by the same business can be linked to a single CitiBusiness Online Code.&nbsp; You can manage the specific authority of each User and to control access to and restrict transactions from accounts through the methods and functions described in the User Materials.&nbsp; All transactions by a User are considered authorized by the account owner whether or not the User is a signer on the account(s) upon which they are perform a transaction.&nbsp; In the event of any conflict between the authority of a User (including the entitlement to initiate and approve financial transactions on the account owners behalf) as evidenced in the applicable Client Manual or other account agreement, banking resolution, power of attorney or other means and that granted through this User Agreement (including the User Materials), the terms of this User Agreement shall have precedence.&nbsp; You acknowledge that each user must sign on at least once within each 365 day period in order to retain an active status on CitiBusiness Online.",
        content76: "J. System Administration",
        content77: "Citibank offers CitiBusiness Online customers the capability to designate certain Users as System Administrators, System Administrators are Users with broad authority to action on behalf of the account owner by controlling the access and capabilities of other Users including account and financial entitlement.&nbsp; Some System Administrators can add and remove Users (including other System Administrators) and grant and change account access and financial transaction limits for Users and themselves.&nbsp; System Administrators have differing levels of authority.&nbsp; Customers who are enrolled in the cash manager suite of services can assign specific entitlements and designate the authority granted to and by each System Administrator.&nbsp; A System Administrator for a customer who is not enrolled in cash manager suite of services will default to the highest level of authority.&nbsp; The CitiBusiness customer is responsible for managing the level of authority delegated to each System Administrator and other Users through CitiBusiness Online. Limiting assignment of System Administrators to account signers is strongly recommended.&nbsp; The CitiBusiness customer is solely responsible for supervising System Administrators and the actions they perform on CitiBusiness Online.&nbsp; Refer to the User Materials for further details about the System Administrator function including User entitlements.",
        content78: "K.  Fees",
        content79: "You agree that Citibank may charge a monthly fee for the CitiBusiness Online service and other products and services offered through CitiBusiness Online.&nbsp;Any such fee will be automatically deducted from your primary CitiBusiness Checking account.&nbsp; Should sufficient funds not be available in this account when payment is due, Citibank reserves the right to deduct the amount of the fee from any other account you maintain at Citibank.&nbsp; You are also responsible for all charges incurred in connecting to and using CitiBusiness Online,including data usage and other charges by your Internet Service Provider or Mobile Network Operator.",
        content80: "L. Communications Link",
        content81: "It is your responsibility to obtain and maintain your communications link with Citibank, and to ensure that your use of such communications link is in compliance with applicable law, including any requirements of telecommunications authorities.",
        content82: "M. Website Links",
        content83: "<b>This site may contain links to web sites controlled or offered by third parties (non-affiliates of citibank).&nbsp; Citibank hereby disclaims liability for any information, materials, products or services posted or offered at any of the third party sites linked to this web site, by creating a link to a third party web site.&nbsp; Citibank does not endorse or recommend any products or services offered or information contained at these web sites, nor is Citibank liable for any failure of products or services offered or advertised at those sites.&nbsp; Such third party may also have a privacy policy different from that of citibank and their third party website may provide less security than the citibank site.</b>",
        content84: "N. Limit of Citibank Responsibility",
        content85: "Citibank agrees to make every reasonable effort to ensure full performance of the CitiBusiness Online system and, on a timely basis, to resolve disputes that may arise.&nbsp; Citibank will be responsible for acting only on those instructions sent through CitiBusiness Online which are actually received, and cannot assume responsibility for malfunctions or capacity reductions in your equipment or in public communications facilities not under Citibank&rsquo;s control that may affect the accuracy or timeliness of messages you send.&nbsp; Citibank is not responsible should you incorrectly request a withdrawal or transfer to be made.&nbsp; Citibank also cannot warrant the accuracy of any information provided on CitiBusiness Online by an unaffiliated company.&nbsp; Citibank is not responsible for any loss, damage or injury caused by CitiBusiness Online, nor is Citibank liable for any direct, indirect, special or consequential damages arising in any way out of its use.",
        content86: "O. No Warranty",
        content87: "The information and materials contained in this site, including text, graphics, links or other items are provided &quot;as is&quot;, &quot;as available&quot;.&nbsp; Citibank does not warrant the accuracy, adequacy or completeness of this information and materials and expressly disclaims liability for errors or omissions.&nbsp; No warranty of any kind, implied, expressed or statutory, including but not limited to, the warranties of non-infringement of third party rights, title, merchantability, fitness for a particular purpose and freedom from computer virus, is given in conjunction with the information and materials.",
        content88: "P. Exclusive Use",
        content89: "CitiBusiness Online  is furnished for your exclusive use and to access and manage eligible accounts on behalf of the account owner.&nbsp; You agree that you will not sell, assign or transfer this Agreement or any materials furnished to you in connection with it.",
        content90: "Q. Changes in Terms",
        content91: "Citibank may change the terms of this Agreement at any time.&nbsp; Citibank may also add, discontinue or modify services, and change procedures and fees at any time.&nbsp; Citibank will notify you in advance of such changes as required by applicable law, by mail or by electronic message or notification through CitiBusiness Online.",
        content92: "R. Cancellation",
        content93: "This Agreement will remain in effect until you or Citibank terminate it.&nbsp; You understand that you may cancel this Agreement at any time by notifying Citibank of your intent to cancel through the electronic message feature or in writing.&nbsp; You understand that this cancellation applies only to services provided through CitiBusiness Online and will not affect your Citibank accounts.&nbsp; Citibank may cancel this Agreement or terminate your use of CitiBusiness Online, for any reason, at any time.&nbsp; Cancellation will not affect the applicability of this Agreement to transactions and events occurring prior to termination.",
        content94: "S. Client Service",
        content95: "If you have a dispute with Citibank over the CitiBusiness Online service, if you need help in resolving an error, or you need to report a security problem, you should immediately telephone CitiBusiness Online Client Service at 1-800-285-1709, TDD access for the speech or hearing impaired at 1-800-788-0002 or write: ",
        content96: "<b>Online Services<br/> Citicorp Data Systems Incorporated<br/> 100 Citibank Drive<br/> San Antonio, Texas 78245-9989</b>",
        content97: "CitiBusiness Online Client Service can help resolve your CitiBusiness Online problem or provide information about Citibank services, but CitiBusiness Online Client Service is not authorized to waive, modify or amend any provision of this Agreement.",
        content98: "T. Business Days",
        content99: "Our normal business days are Monday through Friday, except for bank holidays.&nbsp; Saturday, Sunday and Monday are considered one business day.&nbsp; Bank holidays are considered part of the following business day.",
        content100: "U. Severability",
        content101: "In the event that any provision of this Agreement shall for any reason be held to be invalid, illegal or unenforceable, the remaining provisions shall remain valid and enforceable.",
        content102: "V. Governing Law",
        content103: "This Agreement shall be governed by Federal laws and regulations.&nbsp; To the extent that such laws and regulations do not apply, this Agreement shall be governed by and be construed in accordance with the laws of the state in which the branch where your account(s) are domiciled or the law designated under your loan agreement(s).",
        content104: "<b>I HAVE READ AND UNDERSTAND THE AGREEMENT AND AGREE TO BE BOUND BY ALL OF ITS TERMS AND CONDITIONS.</b> ",
        content105: "<b>Agreement to Terms and Conditions of the CitiBusiness Online User Agreement</b>",
        content106: "<b>Information Provided Electronically.</b>&nbsp; In order to use the services provided through CitiBusiness Online, you must consent electronically to receive and accept the terms and conditions of the User Agreement presented above and any amendments to it.&nbsp; You may withdraw your consent to having this information provided to you electronically, by calling 1-800-285-1709 and requesting that you be removed as a User.&nbsp; Withdrawing your consent in this manner will not prevent you from re-accepting the User Agreement and using the services.",
        content107: "<b>Required Equipment.</b>&nbsp; In order to use the services and this web site and to review and retain a copy of the terms and conditions contained in this User Agreement, you must have a computer equipped with at least a browser with a 128-bit encryption, and either a printed or a disk drive or other storage device.&nbsp; You can also obtain a copy of the User Agreement by calling 1-800-285-1709.",
        content108: "<b>Consent by User and Agreement to Terms and Conditions.</b>&nbsp; By clicking &quot;I agree&quot; below, you acknowledge and agree: (1) you have software and equipment that satisfies the above requirements; and (2) to receive electronically information about the services provided through the web site and any subsequent amendments to them and (3) you have obtained, read and understand an electronic copy of the User Agreement and agree to be bound all the terms and conditions therein.",
        decline: "Decline",
        definations: "Definitions",
        header: "CitiBusiness&#174; Online User Agreement"
    }

    return parseData(data);
}

export function parseData(data: { [key: string]: string }) {

    const content = [
        {
            htmlHeader: data.content2,
            htmlContent: data.content3
        },
        {
            htmlHeader: data.content4,
            htmlContent: `${data.content5}\n${data.content6}`
        },
        {
            htmlHeader: data.content7,
            htmlContent: data.content8
        },
        {
            htmlHeader: data.content9,
            htmlContent: data.content10
        },
        {
            htmlHeader: data.content11,
            htmlContent: `${data.content12}\n${data.content13}\n${data.content14}\n${data.content15}\n${data.content16}\n${data.content17}\n${data.content18}\n${data.content19}\n${data.content20}\n${data.content21}\n${data.content22}`
        },
        {
            htmlHeader: data.content23,
            htmlContent: `${data.content24}\n${data.content25}\n${data.content26}\n${data.content27}\n${data.content28}\n${data.content29}\n${data.content30}\n${data.content31}\n${data.content32}\n${data.content33}\n${data.content34}\n${data.content35}\n${data.content36}\n${data.content37}\n${data.content38}\n${data.content39}\n${data.content40}\n${data.content41}\n${data.content42}\n${data.content43}\n${data.content44}\n${data.content45}\n${data.content46}\n${data.content47}\n${data.content48}\n${data.content49}\n${data.content50}\n${data.content51}\n${data.content52}\n${data.content53}\n${data.content54}\n${data.content55}\n${data.content56}\n${data.content57}\n${data.content58}\n${data.content59}\n${data.content60}\n${data.content61}\n${data.content62}\n${data.content63}\n${data.content64}\n${data.content65}\n${data.content66}\n${data.content67}`
        },
        {
            htmlHeader: data.content68,
            htmlContent: data.content69
        },
        {
            htmlHeader: data.content70,
            htmlContent: `${data.content71}\n${data.content72}`
        },
        {
            htmlHeader: data.content73,
            htmlContent: `${data.content74}\n${data.content75}`
        },
        {
            htmlHeader: data.content76,
            htmlContent: data.content77
        },
        {
            htmlHeader: data.content78,
            htmlContent: data.content79
        },
        {
            htmlHeader: data.content80,
            htmlContent: data.content81
        },
        {
            htmlHeader: data.content82,
            htmlContent: data.content83
        },
        {
            htmlHeader: data.content84,
            htmlContent: data.content85
        },
        {
            htmlHeader: data.content86,
            htmlContent: data.content87
        },
        {
            htmlHeader: data.content88,
            htmlContent: data.content89
        },
        {
            htmlHeader: data.content90,
            htmlContent: data.content91
        },
        {
            htmlHeader: data.content92,
            htmlContent: data.content93
        },
        {
            htmlHeader: data.content94,
            htmlContent: `${data.content95}\n${data.content96}\n${data.content97}`
        },
        {
            htmlHeader: data.content98,
            htmlContent: data.content99
        },
        {
            htmlHeader: data.content100,
            htmlContent: `${data.content101}\n${data.content102}\n${data.content103}`

        },
        {
            htmlHeader: data.content102,
            htmlContent: `${data.content103}\n${data.content104}\n${data.content105}\n${data.content106}\n${data.content107}\n${data.content108}`
        },
    ];

    return {
        definations: data.definations,
        definationsContent: data.content1,
        content
    }
}