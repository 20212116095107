import React, { useContext, useEffect, useState } from 'react';
import Loading from '../../ui-kit/loading-modal/loadingComponent.component';
import LandingHeader from '../header/header.component';
import ApplicationContext from '../../context/application-context';
import ContextData from '../../context/context-data.interface';
import { RAccordion, AccordionItemContent } from '../../ui-kit/accordion/accordion.component';
import {
    defaultData,
    parseData
} from '../common-ui/user-agreement/user-agreement.utils';
import _ from 'lodash';
import { WAIT_FOR_IOS_CALLBACK } from '../../api/api.core';
import { focusToElementWithClassName, handleBackOnLeftMenus } from '../../utils/common.util';
import UserAgreement from '../common-ui/user-agreement/user-agreement.component';
import kLEFT_MENU from '../../common/constants/LEFT_MENU';
import kCONSTANTS from '../../common/constants/GENERAL';

interface Props {
    history?: any
}

interface State {
    data?: { [key: string]: any };
    showLoading?: Boolean
}

export class XOnlineUserAgreement extends React.Component<Props, State> {

    context: ContextData | undefined;

    constructor(props: Props) {
        super(props);
        this.state = {
            data: defaultData(),
            showLoading: true
        };
    }

    handleBackButtonClick = () => {
        handleBackOnLeftMenus();
    }

    successOnlineUserAgreement = (response: any) => {
        let temp = _.get(response, 'body', null);
        if (temp) {
            this.setState({ data: parseData(temp) });
        }
        this.finallyOnlineUserAgreement();
    }

    errorOnlineUserAgreement = (error: any) => {
        console.log("errorContactUsInfo error::" + error);
        console.log(error);
        this.finallyOnlineUserAgreement();
    }

    finallyOnlineUserAgreement() {
        this.setState({ showLoading: false });
    }

    componentDidMount() {
        this.context?.DEVICE.checkDevice(
            (checkDeviceResponse: any) => {
                if (checkDeviceResponse.wirelessProtocol !== "noNetwork") {
                    this.context?.API.COMMON_API.onlineUserAgreement(this.successOnlineUserAgreement)
                        .then((response: any) => {
                            //console.log('====>', response)
                            if (response.status !== WAIT_FOR_IOS_CALLBACK)
                                this.successOnlineUserAgreement(response.data)
                            else
                                console.log('waiting for ios call', response)
                        })
                        .catch((error: any) => this.errorOnlineUserAgreement(error));
                } else {
                    this.setState({ showLoading: false });
                }

            },
            (error: any) => {
                this.setState({ showLoading: false });
            },
        );

    }

    render() {
        return (
            <>
                <LandingHeader title={kLEFT_MENU.USER_AGREEMENT} showBackBtn onBackClick={this.handleBackButtonClick} />
                <div className="react-container top-40 auto-overflow" tabIndex={0} style={{ width: '100%', maxWidth: '100%' }}>
                    {
                        this.state.showLoading
                            ? <Loading tip={kCONSTANTS.LOADING} horizontal className='loading-center' />
                            : <>
                                <p className='title'>{this.state.data?.definations}</p>
                                <p className='text-medium' dangerouslySetInnerHTML={{ __html: this.state.data?.definationsContent }} />
                                <RAccordion
                                    content={(this.state.data?.content as Array<AccordionItemContent>)}
                                    contentClass='agreement-content' />
                            </>
                    }
                </div>
            </>
        );
    }

}

const OnlineUserAgreement = (p: Props) => {

    const context = useContext(ApplicationContext);

    const [data, setData] = useState<{ [key: string]: any }>(defaultData());

    const [showLoading, setShowLoading] = useState(true);


    useEffect(() => {

        context?.DEVICE.checkDevice(
            (checkDeviceResponse: any) => {
                if (checkDeviceResponse.wirelessProtocol !== "noNetwork") {
                    context?.API.COMMON_API.onlineUserAgreement(successOnlineUserAgreement)
                        .then((response: any) => {
                            if (response.status !== WAIT_FOR_IOS_CALLBACK)
                                successOnlineUserAgreement(response.data)
                            else
                                console.log('waiting for ios call', response)
                        })
                        .catch((error: any) => errorOnlineUserAgreement(error));
                } else {
                    setShowLoading(false);
                }

            },
            (error: any) => {
                setShowLoading(false);
            },
        );

    }, []);

    useEffect(() => {
        if (!showLoading) {
            //ADA | Focus on header on initial load
            focusToElementWithClassName('.header-content .header-text', 400);
        }
    }, [showLoading]);

    const handleBackButtonClick = () => {
        handleBackOnLeftMenus();
    }


    const successOnlineUserAgreement = (response: any) => {
        let temp = _.get(response, 'body', null);
        if (temp) {
            setData(parseData(temp))
        }
        finallyOnlineUserAgreement();
    }

    const errorOnlineUserAgreement = (error: any) => {
        console.log("errorContactUsInfo error::" + error);
        console.log(error);
        finallyOnlineUserAgreement();
    }

    const finallyOnlineUserAgreement = () => {
        console.log("finallyContactUsInfo::");
        setShowLoading(false);
    }


    return (
        <>
            <UserAgreement
                data={data}
                showLoading={showLoading}
                onBackButtonClick={handleBackButtonClick}
            />
        </>
    );


}

export default OnlineUserAgreement;

XOnlineUserAgreement.contextType = ApplicationContext;