import { isValidElement, useEffect, useState } from "react";
import { Modal } from "@citi-icg-172888/icgds-react";
import Button from "../button/button";
import kCONSTANTS from "../../common/constants/GENERAL";
import "./custom-modal.style.scss";
import useEscapeKey from "../../utils/useEscapeKey";
import { focusToElementWithClassName } from "../../utils/common.util";

interface CustomModalProps {
    userName?: string;
    title?: any;
    description?: string;
    descriptionArray?: string[];
    showModal: boolean;
    onContinue?: Function;
    continueButtonTitle?: string;
    onCancel?: Function;
    cancelButtoTitle?: string;
    showLoading?: boolean;
    cancellable?: boolean;
    onClose?: Function;
    isCloseable?: boolean;
    body?: React.ReactNode;
    width?: string;
    cancelText?: string;
    applyText?: string;
    wrapperClass?: string;
    bodyClass?: string;
    isCancelBtnSecondary?: boolean;
    className?: string;
    renderFooter?: Function;
    showNoHeaderBorderBtm?: boolean;
    size?: any;
    hideFooter?: boolean;
    enableBodyScroll?: boolean;
    mask?: boolean
    bodyStyle?: any
    ariaModal?: boolean
    ariaLabelledby?: string
    isModalOverModal?: boolean; //if it is true, then it will not remove the aria-hidden attribute from the parent element on close event
    modalName?: string; //For tracking event of button from modal
}

const CustomModal = (p: CustomModalProps) => {
    const [isCancellable, setIsCancellable] = useState(true);
    const [showNoHeaderBorderBtm, setShowNoHeaderBorderBtm] = useState(false);

    useEffect(() => {
        if (p.showNoHeaderBorderBtm)
            setShowNoHeaderBorderBtm(p.showNoHeaderBorderBtm)
    }, [p.showNoHeaderBorderBtm])



    useEffect(() => {
        setIsCancellable(p.cancellable !== undefined ? p.cancellable : true);
    }, [p.cancellable]);

    /**
     * ADA Implementation to focus on the header of the modal when it is opened
     */
    useEffect(() => {
        if (p.showModal) {
            focusToElementWithClassName(".custom-modal-container .lmn-modal .lmn-modal-content .lmn-modal-header .title-container", 400);
        }

        const wrappers = document.getElementsByClassName("custom-modal-container");
        for (let e of wrappers) {
            e.setAttribute("role", "dialog");
            e.setAttribute("aria-modal", "true");
        }

        const ele: Element | null = document.querySelector("#cbusol-view");
        if (ele) {
            if (p.showModal) {
                ele.setAttribute("aria-hidden", "true");
            }
        }

        return () => {
            if (!p.isModalOverModal) {
                if (ele) {
                    ele.removeAttribute('aria-hidden');
                }
            }
        };
    }, [p.isModalOverModal, p.showModal]);

    /**
     * Close the modal on escape key press
     */
    useEscapeKey(() => {
        if (p.onClose) p.onClose();
    });

    const onContinueClick = () => {
        if (p.onContinue) p.onContinue();
    };

    const onCancel = () => {
        if (p.onCancel) p.onCancel();
    };

    const onCloseClick = () => {
        if (p.onClose) p.onClose();
    };



    const renderTitle = () => {
        return (
            <>
                <div
                    className="title-container"
                    tabIndex={0}
                    aria-label={`${p.userName !== undefined && p.userName.length > 0 ? p.userName : ""
                        } ${p.title !== undefined && p.title !== null && p.title.length > 0 ? p.title : ""}`}
                >
                    {p.userName !== undefined && p.userName.length > 0 ? (
                        <span aria-hidden="true">
                            {p.userName.length > 0 ? p.userName : null}
                        </span>
                    ) : null}

                    {p.title && p.title?.length > 0
                        ? <p
                            aria-hidden="true"
                            className={`text-medium custom-modal-text ${showNoHeaderBorderBtm ? 'scroll-content' : ''} ${p.description !== undefined || p.descriptionArray !== undefined
                                ? "bold"
                                : ""
                                }`}>
                            {p.title}</p>
                        : null
                    }

                    {p.description !== undefined && p.description !== null ? (
                        <p
                            tabIndex={0}
                            className="text-small custom-modal-description"
                            dangerouslySetInnerHTML={{ __html: p.description }}
                        />
                    ) : null}
                </div>
            </>
        );
    };


    const uiRenderBody = () => {
        return <>{p.body} </>
    }

    const uiRenderFooter = () => {
        return (
            <div className="footer-wrap">
                <div className="footer-button-wrap">
                    {isCancellable && (
                        <Button
                            outline
                            color={p.isCancelBtnSecondary ? "outline" : undefined}
                            disabled={p.showLoading}
                            style={{ color: "#0066b3", borderColor: "#0066b3", flex: 1 }}
                            onClick={onCancel}
                            containerView={p.modalName ? p.modalName + ' Modal' : ''}
                        >
                            {p.cancelButtoTitle ? p.cancelButtoTitle : kCONSTANTS.CANCEL}
                        </Button>
                    )}
                    <Button
                        color={"primary"}
                        disabled={p.showLoading}
                        onClick={onContinueClick}
                        showLoading={p.showLoading}
                        containerView={p.modalName ? p.modalName + ' Modal' : ''}
                        style={{ flex: 0.5 }}
                    >
                        {p.continueButtonTitle ? p.continueButtonTitle : kCONSTANTS.CONTINUE}
                    </Button>
                </div>
            </div>
        );
    };

    const renderFooter = () => {
        if (p.renderFooter) {
            return p.renderFooter();
        }
        else {
            return uiRenderFooter();
        }
    }



    const ui = () => {
        return (
            <Modal
                centered
                size={p.size ? p.size : 'large'}
                visible={p.showModal}
                width={p.width ? p.width : "90%"}
                wrapperClass={`custom-modal-container ${showNoHeaderBorderBtm ? 'no-border' : ""} ${p.wrapperClass ? p.wrapperClass : ""}`}
                title={isValidElement(p.title) ? p.title : renderTitle()}
                closable={p.isCloseable}
                onClose={onCloseClick}
                footer={!p.hideFooter && renderFooter()}
                cancelText={p.cancelText}
                applyText={p.applyText}
                bodyClass={`${p.bodyClass ? p.bodyClass : ''}`}
                className={p.className}
                enableBodyScroll={p.enableBodyScroll}
                mask={p.mask}
                bodyStyle={p.bodyStyle}
                aria-modal={p.ariaModal}
                aria-labelledby={p.ariaLabelledby}
            >
                {uiRenderBody()}
            </Modal>
        );
    };

    return ui();
};

export default CustomModal;
