import { AxiosRequestConfig, AxiosResponse } from "axios";
import { goToSessionSuspendedPage } from '../../utils/common.util';
import securityCtx from '../../context/security-context';



export const interceptAllResponse = (response: AxiosResponse<any>) => {
    //console.log('response->intercept success:', response);
    if (response.config.url && response.config.url === 'abnormality') {
        window.ExpireSession();
    }
    return response;
}

export const interceptAllRequest = (request: AxiosRequestConfig) => {

    const localSessionId = window.localStorage.getItem("csId");
    const sessionId = window.sessionStorage.getItem("csId");
    if (localSessionId !== null && sessionId !== null && localSessionId !== sessionId && securityCtx?.isSignedIn()) {
        goToSessionSuspendedPage();
        return;
    }
    return request;
}


export const interceptErrorResponse = (error: any) => {
    console.log('response->intercept error:', error);
    // if(error.contains('302')){
    //     window.ExpireSession();
    // }
    return Promise.reject(error);
}
