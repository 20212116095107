import { Icon } from "@citi-icg-172888/icgds-react";
import __constants from "../../../../common/constants";
import './password-checker.style.scss'

interface PasswordCheckerProps {
    characterLimitFrom8To10: any;
    characterLimitFrom6To10: any;
    atLeast1Number: any;
    atleastOneUpperCaseLetter: any;
    atleastOneLowerCaseLetter: any;
    atleastOneSpecialCharacter: any;
    cannotContainSpecialCharactersOrSpaces: any;
    cannotContainmorethan2IdenticalCharacters: any;
    newPassword: any;
    cannotContainSpaces: any;
    isPasswordMatch: any;
    isResetPassword?: boolean
}

const PasswordCheckerComponent = (props: PasswordCheckerProps) => {
    const { characterLimitFrom8To10,
        characterLimitFrom6To10,
        atLeast1Number,
        atleastOneUpperCaseLetter,
        atleastOneLowerCaseLetter,
        atleastOneSpecialCharacter,
        cannotContainSpecialCharactersOrSpaces,
        cannotContainmorethan2IdenticalCharacters,
        newPassword,
        cannotContainSpaces,
        isPasswordMatch,
        isResetPassword } =
        props;

    const createUI = () => {
        return (
            <>
                <div className="password-checker">
                    {isResetPassword ?
                        <p className={`title validate-title title-text`}>
                            {__constants.PASSWORD_EXPIRED_MESSAGE}
                        </p> : null}

                    <p className={`title validate-title`}>
                        {__constants.CONDITIONS_APPLY}
                    </p>
                    {window.isSplCharPwFriendly ? (
                        <p
                            role="form"
                            tabIndex={0}
                            aria-label={__constants.CHANGE_YOUR_PASSWORD.CHARACTER_LIMIT}
                            className={`title validation-message `}
                        >
                            <span
                                className={`${characterLimitFrom8To10 ? "validation-success-check" : ""
                                    }`}
                            >
                                <Icon aria-hidden="true" type="check-circle" />
                            </span>{" "}
                            {__constants.CHANGE_YOUR_PASSWORD.CHARACTER_LIMIT}
                        </p>
                    ) : (
                        <p
                            role="form"
                            tabIndex={0}
                            aria-label={
                                __constants.CHANGE_YOUR_PASSWORD.CHARACTER_SIX_TO_TEN_LIMIT
                            }
                            className={`title validation-message `}
                        >
                            <span
                                className={`${characterLimitFrom6To10 ? "validation-success-check" : ""
                                    }`}
                            >
                                <Icon aria-hidden="true" type="check-circle" />
                            </span>{" "}
                            {__constants.CHANGE_YOUR_PASSWORD.CHARACTER_SIX_TO_TEN_LIMIT}
                        </p>
                    )}

                    <p
                        role="form"
                        tabIndex={0}
                        aria-label={__constants.CHANGE_YOUR_PASSWORD.NUMBER_LIMIT}
                        className={`title validation-message `}
                    >
                        <span
                            className={`${atLeast1Number ? "validation-success-check" : ""}`}
                        >
                            <Icon aria-hidden="true" type="check-circle" />
                        </span>{" "}
                        {__constants.CHANGE_YOUR_PASSWORD.NUMBER_LIMIT}
                    </p>
                    <p
                        role="form"
                        tabIndex={0}
                        aria-label={__constants.CHANGE_YOUR_PASSWORD.UPPERCASE_LIMIT}
                        className={`title validation-message `}
                    >
                        <span
                            className={`${atleastOneUpperCaseLetter ? "validation-success-check" : ""
                                } `}
                        >
                            <Icon aria-hidden="true" type="check-circle" />
                        </span>{" "}
                        {__constants.CHANGE_YOUR_PASSWORD.UPPERCASE_LIMIT}
                    </p>
                    <p
                        role="form"
                        tabIndex={0}
                        aria-label={__constants.CHANGE_YOUR_PASSWORD.LOWERCASE_LIMIT}
                        className={`title validation-message `}
                    >
                        <span
                            className={`${atleastOneLowerCaseLetter ? "validation-success-check" : ""
                                } `}
                        >
                            <Icon aria-hidden="true" type="check-circle" />
                        </span>{" "}
                        {__constants.CHANGE_YOUR_PASSWORD.LOWERCASE_LIMIT}
                    </p>
                    {window.isSplCharPwFriendly ? (
                        <p
                            role="form"
                            tabIndex={0}
                            aria-label={
                                __constants.CHANGE_YOUR_PASSWORD.CONTAIN_SPECIAL_CHARACTER
                            }
                            className={`title validation-message `}
                        >
                            <span
                                className={`${atleastOneSpecialCharacter ? "validation-success-check" : ""
                                    } `}
                            >
                                <Icon aria-hidden="true" type="check-circle" />
                            </span>{" "}
                            {__constants.CHANGE_YOUR_PASSWORD.CONTAIN_SPECIAL_CHARACTER}
                        </p>
                    ) : null}
                    {window.isSplCharPwFriendly ? null : (
                        <p
                            role="form"
                            tabIndex={0}
                            aria-label={
                                __constants.CHANGE_YOUR_PASSWORD
                                    .SPECIAL_CHARACTER_OR_SPACES_LIMIT
                            }
                            className={`title validation-message `}
                        >
                            <span
                                className={`${cannotContainSpecialCharactersOrSpaces
                                    ? "validation-success-check"
                                    : ""
                                    } `}
                            >
                                <Icon aria-hidden="true" type="check-circle" />
                            </span>{" "}
                            {
                                __constants.CHANGE_YOUR_PASSWORD
                                    .SPECIAL_CHARACTER_OR_SPACES_LIMIT
                            }
                        </p>
                    )}
                    <p
                        role="form"
                        tabIndex={0}
                        aria-label={
                            __constants.CHANGE_YOUR_PASSWORD.IDENTICAL_CHARACTER_LIMIT
                        }
                        className={`title validation-message `}
                    >
                        <span
                            className={`${cannotContainmorethan2IdenticalCharacters &&
                                newPassword.length > 0
                                ? "validation-success-check"
                                : ""
                                } `}
                        >
                            <Icon aria-hidden="true" type="check-circle" />
                        </span>{" "}
                        {__constants.CHANGE_YOUR_PASSWORD.IDENTICAL_CHARACTER_LIMIT}
                    </p>
                    {window.isSplCharPwFriendly ? (
                        <p
                            role="form"
                            tabIndex={0}
                            aria-label={__constants.CHANGE_YOUR_PASSWORD.SPACES_LIMIT}
                            className={`title validation-message `}
                        >
                            <span
                                className={`${cannotContainSpaces && newPassword.length > 0
                                    ? "validation-success-check"
                                    : ""
                                    } `}
                            >
                                <Icon aria-hidden="true" type="check-circle" />
                            </span>{" "}
                            {__constants.CHANGE_YOUR_PASSWORD.SPACES_LIMIT}
                        </p>
                    ) : null}
                    <p
                        role="form"
                        tabIndex={0}
                        aria-label={__constants.CHANGE_YOUR_PASSWORD.PASSWORD_MATCH}
                        className={`title validation-message `}
                    >
                        <span
                            className={`${isPasswordMatch ? "validation-success-check" : ""
                                } `}
                        >
                            <Icon aria-hidden="true" type="check-circle" />
                        </span>{" "}
                        {__constants.CHANGE_YOUR_PASSWORD.PASSWORD_MATCH}
                    </p>
                    <p className={`title validation-message `}>
                        {
                            __constants.CHANGE_YOUR_PASSWORD
                                .PASSWORD_MATCH_LAST_6_PASSWORDS_LIMT
                        }
                    </p>
                </div>
            </>
        );
    };

    return createUI();
};

export default PasswordCheckerComponent;
