import axios, { AxiosInstance, AxiosResponse } from 'axios';
import device from '../device/xdevice';
import { DEVICE_TYPE } from '../device/xdevice';
import __constants from '../common/constants';
import { analytics } from '../utils/event-tracking.util';
import { compareVersion } from '../utils/common.util';

const MODEL = "model";

export const WAIT_FOR_IOS_CALLBACK = 500165;

export enum RequestMethod {
    POST = "post",
    POST_FORM = "postForm",
    GET = "get",
    PUT = "put",
    OPTIONS = "options",
    DELETE = "delete"
}

export class TokenHttpApi {

    http!: AxiosInstance;

    constructor() {
        this.http = axios.create({
            //baseURL: BUILD_UTIL.isProduction()?__constants.BUILD_CONSTANTS.CITI_ENV.RELATIVE:__constants.BUILD_CONSTANTS.CITI_ENV.RELATIVE,
            baseURL: __constants.BUILD_CONSTANTS.CITI_ENV.RELATIVE,
            withCredentials: true
        });
    }

    makeHttpRequest(method: RequestMethod, url: string, headers: any, body: any, callBack: Function = () => { }, channel?: string, callBackHash: string = "", callBackFailure: Function = () => { }): Promise<AxiosResponse<any>> {
        return new Promise((resolve, reject) => {
            this.makeHttpRequestFunction(method, url, headers, body, callBack, callBackFailure, callBackHash).then((resp) => {
                if (channel !== null && channel !== undefined && channel.length > 0) {
                    analytics(url, channel);
                }
                resolve(resp);
            }).catch((error: any) => {
                reject(error);
            })
        });
    }

    makeHttpRequestFunction(method: RequestMethod, url: string, headers: any, body: any, callBack: Function = () => {
        console.debug('No function configured');
    }, callBackFailure: Function = () => {
        console.debug('No function configured');
    }, callBackHash: string = ""): Promise<AxiosResponse<any>> {
        if (window.THE_DEVICE_TYPE === DEVICE_TYPE.IOS && !compareVersion('16')) {
            if (callBackHash !== "" && callBackHash.length > 3) {
                window.CALLBACK_POOL[callBackHash] = callBack;
            } else {
                window.IOS_NETWORK_API_RESPONSE = callBack;
            }
            if (callBackFailure !== null && callBackFailure !== undefined && callBackFailure.length > 0) {
                window.ERROR_CALLBACK_POOL[callBackHash.length > 3 ? callBackHash : 'defaultHash'] = callBackFailure;
            }
            //const device = new DeviceAPI()
            if (url.startsWith("/")) {
                url = window.GLOBAL_ROOT + url.substring(1);
            }
            console.log("makeHttpRequestFunction: ", url);
            const json = { "url": url, "parameter": body, "httpMethod": method, "backbone": false, "callBackHash": callBackHash };
            if (method === RequestMethod.POST_FORM) {// only for citibusinessMobileLogin
                device.sendFormData(callBack, callBackFailure, json);
            }
            else {
                device.sendJsonData(callBack, callBackFailure, json);
            }
            return new Promise((resolve, reject) => {
                resolve({
                    data: { ios: true },
                    config: {},
                    headers: {},
                    status: WAIT_FOR_IOS_CALLBACK,
                    statusText: "",
                })
            });
        }
        switch (method) {
            case RequestMethod.POST_FORM:
                const params = new URLSearchParams()
                params.append(MODEL, JSON.stringify(body));
                return this.http.post(url, params, { headers: headers });
            case RequestMethod.POST:
                // console.log(body);
                return this.http.post(url, body, { headers: headers });
            case RequestMethod.PUT:
                return this.http.put(url, body, { headers: headers });
            case RequestMethod.DELETE:
                return this.http.delete(url, { headers: headers });
            case RequestMethod.OPTIONS:
                return this.http.options(url, { headers: headers });
            case RequestMethod.GET:
                return this.http.get(url, { headers: headers });
            default: ;
                return this.http.get(url, { headers: headers });
        }
    }

}

let tokenHttpApi = new TokenHttpApi();

export default tokenHttpApi