import _ from 'lodash';
import __constants from '../../../common/constants';
import kBIOMETRICS from '../../../common/constants/BIOMETRICS';
import __req from '../../../api/biometric.api';
import { WAIT_FOR_IOS_CALLBACK } from '../../../api/api.core';
import { TMXProfile } from '../../../js/fp_AA';
import device, { DEVICE_TYPE } from '../../../device/xdevice';

const enableAkamai = (window.isAkamaiMobile && window.THE_DEVICE_TYPE !== DEVICE_TYPE.WEB);

let eventType: string = '';
let isForgotPINFlow: boolean = false;
let successCallback: Function | undefined = undefined;
let failCallback: Function | undefined = undefined;

/**
 * init TMX, and generate new tmxSessionId to execute TMX Profiling call 
 */

const _handleinitTMXResp = (resp: any) => {
    if (resp?.toolkitFriendly) {
        TMXProfile(resp.toolkitFriendly, resp.profileUrl, resp.orgId, resp.tmxSessionId);

        if (isForgotPINFlow) {
            device?.getUserP86Id((resp: any) => {
                console.log('forgot pin | biometric - getUserP86Id succeed ', resp);

                _sendCheckAuthenticationReq(resp.businessCode, resp.userId);
            }, () => {
                console.log('forgot pin | biometric - getUserP86Id failed');
                failCallback && failCallback();
            }, []);
            return;
        }

        _sendCheckAuthenticationReq();
        return;
    }

    failCallback && failCallback();
}

const _initBiometricTMX = () => {
    getAkamai((akamaiData?: string) => {
        __req.initBiometricTMX(eventType, isForgotPINFlow, _handleinitTMXResp, akamaiData)
            .then((resp) => {
                if (resp.status !== WAIT_FOR_IOS_CALLBACK) {
                    _handleinitTMXResp(resp.data);
                } else {
                    console.log('waiting for ios call', resp);
                }
            })
            .catch((error) => {
                console.log('Transmit - Biometric - initBiometricTMX - failed ', error);

                failCallback && failCallback();
            });
    });
}

/**
 * check if the application is malware in Biocatch and TMX
 */

const _handleCheckAuthenticationResp = (resp: any) => {
    if (!_.get(resp, 'success', false)) {
        failCallback && failCallback();
        return;
    }
    if (_.get(resp, 'malware', false)) {
        failCallback && failCallback(__constants.TMX_CHALLENGE_SESSION_SUSPENDED);
        return;
    }

    if (!isForgotPINFlow) {
        const challengeType = _.get(resp, 'tmxChallengeType', '');
        if (challengeType === kBIOMETRICS.BIOMETRICS_CHALLENGE_TYPE.EMAIL || challengeType === kBIOMETRICS.BIOMETRICS_CHALLENGE_TYPE.PHONE) {
            failCallback && failCallback(challengeType);
            return;
        }
        if (_.get(resp, 'sessionSuspended', false)) {
            failCallback && failCallback(__constants.TMX_CHALLENGE_SESSION_SUSPENDED);
            return;
        }
    }

    successCallback && successCallback();
}

const _sendCheckAuthenticationReq = (businessCode?: string, userId?: string) => {
    getAkamai((akamaiData?: string) => {
        __req.checkBiometricAuthentication(eventType, isForgotPINFlow, businessCode, userId, _handleCheckAuthenticationResp, akamaiData)
            .then((resp) => {
                if (resp.status !== WAIT_FOR_IOS_CALLBACK) {
                    _handleCheckAuthenticationResp(resp.data);
                } else {
                    console.log('waiting for ios call', resp);
                }
            })
            .catch((error) => {
                console.log('Transmit - Biometric - checkBiometricAuthentication - failed ', error);

                failCallback && failCallback();
            });
    });
}

/**
 * update registration result
 */

const _handleUpdateRegistrationResult = (resp: any) => {
    console.log('Transmit - Biometric - _handleUpdateRegistrationResult - ', resp);
}

/**
 * export function
 */

export const getAkamai = (callBack: Function) => {
    if (!enableAkamai) {
        callBack('');
        return;
    }

    device?.getAkamai((resp: any) => {
        console.log('biometric - getAkamai success');

        const akamaiData = _.get(resp, 'akamaiData', '');
        callBack(akamaiData);
    }, (error: any) => {
        console.log('biometric - getAkamai failed');

        callBack('');
    }, [{ foo: 'bar' }]);
}

export const updateRegistrationResult = (success: boolean) => {
    getAkamai((akamaiData?: string) => {
        device?.getTokenSequenceAndSerialNumber((resp: any) => {
            const serialNumber = _.get(resp, 'serialNumber', '');
            const sequenceNumber = _.get(resp, 'sequenceValue', '');
            __req.updateBiometricRegistrationResult(success, _handleUpdateRegistrationResult, akamaiData, serialNumber, sequenceNumber)
                .then((resp) => {
                    if (resp.status !== WAIT_FOR_IOS_CALLBACK) {
                        _handleUpdateRegistrationResult(resp.data);
                    } else {
                        console.log('waiting for ios call', resp);
                    }
                })
                .catch((error) => {
                    console.log('Transmit - Biometric - updateBiometricRegistrationResult - failed ', error);
                });
        }, () => {
            console.log('Transmit - Biometric - getTokenSequenceAndSerialNumber failed');
        }, {});
    });
}

export const initializeTMX = (type: string, isForgotPIN: boolean, success: Function, failure: Function) => {
    eventType = type;
    isForgotPINFlow = isForgotPIN;
    successCallback = success;
    failCallback = failure;

    _initBiometricTMX();
}
