import _ from 'lodash';
import __req from '../../../api/cdcb.api';
import { WAIT_FOR_IOS_CALLBACK } from '../../../api/api.core';
import { preLogin } from '../../../utils/common.util';
import securityCtx from '../../../context/security-context';
import deviceCtx from '../../../device/xdevice';

let userId: string = '';
let password: string = '';
let autoGenerateOtp1: boolean = false;
let successCallback: Function | undefined = undefined;
let failCallback: Function | undefined = undefined;

function _handleLoginResp(resp: { [key: string]: any }) {
    if (_.get(resp, 'success', false)) {
        successCallback && successCallback(resp);
    } else {
        preLogin();
        failCallback && failCallback(_.get(resp, 'errors[0].value', null));
    }
}

function _handleEncryptionSessionResp(resp: { [key: string]: any }) {
    if (!resp) {
        failCallback && failCallback();
        return;
    }

    let encPassword = '';
    if (resp.FUNCTION === 'P') {
        if (window.JSEncrypt && window.Util) {
            let encrypt = new window.JSEncrypt();
            encrypt.setPublicKey(resp.KEY);
            let encrypted = encrypt.encrypt(password);
            encPassword = window.Util.toHexString(encrypted);
        }
    } else {
        if (window.encryptVerifyStaticRSABlock256) {
            encPassword = window.encryptVerifyStaticRSABlock256(resp.EXPONENT, resp.MODULUS, resp.USER, password, resp.SALT);
        }
    }

    if (encPassword.length === 0) {
        failCallback && failCallback();
        return
    }

    const formatPassword = 'PASSWORD=' + encPassword + '&FUNCTION=' + resp.FUNCTION + '&SESSIONID=' + resp.SESSIONID + '&KEYNAME=' + resp.KEYNAME + '&target=' + resp.target;
    if (autoGenerateOtp1) {
        deviceCtx.getOtp1((resp: object) => {
            const otp1 = _.get(resp, 'softToken', '');
            console.log('CDCB Login - autoGenerateOtp1 ', otp1);

            _sendReqForLogin(formatPassword, otp1);
        }, () => {
            console.log('CDCB Login - getOtp1 failed');
            failCallback && failCallback();
        }, window.DEVICE_INFO.pin);

        return;
    }

    _sendReqForLogin(formatPassword);
}

function _sendReqForLogin(formatPassword: string, otp1: string = '') {
    __req
        .login(userId, formatPassword, otp1, _handleLoginResp)
        .then((resp) => {
            if (resp.status !== WAIT_FOR_IOS_CALLBACK) {
                _handleLoginResp(resp.data);
            } else {
                console.log('waiting for ios call', resp);
            }
        })
        .catch(() => {
            failCallback && failCallback();
        });
}

function _sendReqForEncryptionSession() {
    const encrptUrl = securityCtx.getCdcbEncryptSessionUrl();
    if (!encrptUrl) {
        failCallback && failCallback('Invalid URL');
        return;
    }

    __req
        .getEncryptionSession(userId, encrptUrl, _handleEncryptionSessionResp)
        .then((resp) => {
            if (resp.status !== WAIT_FOR_IOS_CALLBACK) {
                _handleEncryptionSessionResp(resp.data);
            } else {
                console.log('waiting for ios call', resp);
            }
        })
        .catch(() => {
            failCallback && failCallback();
        });
}

export function doLogin(user: string, pwd: string, generateOtp1: boolean, success: Function, failure: Function) {
    userId = user;
    password = pwd;
    autoGenerateOtp1 = generateOtp1;
    successCallback = success;
    failCallback = failure;

    _sendReqForEncryptionSession()
}

export function updateCdcbLoginId(loginId: string) {
    const savedLoginId = securityCtx?.getCdcbLoginId();
    if (!_.isEqual(loginId, savedLoginId)) {
        securityCtx?.setCdcbLoginId(loginId);
        deviceCtx?.setUserP86Id(() => { }, () => { }, [{ cdcbLoginId: loginId }]);
    }
}