import { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Button from '../../../ui-kit/button/button';
import LandingHeader from '../../header/header.component';
import Alert from '../../../ui-kit/alert/alert.component';
import _ from '../../../lodash';
import __constants from '../../../common/constants';
import kCONSTANTS from '../../../common/constants/GENERAL';
import { APP_LOCATIONS } from "../../../routes/routes";
import __req, { ForgotPasswordResponse } from '../../../api/forgot-password';
import './send-email.style.scss';
import device, { DEVICE_TYPE } from '../../../device/xdevice';
import { WAIT_FOR_IOS_CALLBACK } from '../../../api/api.core';
import ApplicationContext from '../../../context/application-context';
import { focusToElementWithClassName } from '../../../utils/common.util';

function SendEmailPage() {
    let history = useHistory();
    let location = useLocation();
    const context = useContext(ApplicationContext);

    const [showSuccess, setShowSuccess] = useState(false);
    const [showLoading, setShowLoading] = useState(false);

    useEffect(() => {
        console.log('send-email-page->useEffect', location)
        if (_.get(location, 'state.emailId', '') === '') {
            context?.Router.navigate(APP_LOCATIONS.HomePage);
        }
    });

    useEffect(() => {
        //ADA | focus on header on first page load
        focusToElementWithClassName('.header-content .header-text', 400);
    }, []);

    function _onSubmitBtnClick1(this: any) {
        if (window.isAkamaiMobile && window.THE_DEVICE_TYPE !== DEVICE_TYPE.WEB) {
            const args = [{ "foo": "bar" }];
            context?.DEVICE.getAkamai((response: any) => {
                console.log('handleChangePinClick1 GET AKAMAI DATA Successful ........... ');
                const akamaiData = _.get(response, 'akamaiData', "");
                _onSubmitBtnClick2(akamaiData);
            }, (fail: any) => {
                console.log("AKAMAI DATA RETRIVAL FAILED");
            }, args);
        } else {
            _onSubmitBtnClick2();
        }
    }

    function _onSubmitBtnClick2(akamaiData?: string) {
        setShowLoading(true);

        const key = _.get(location, 'state.key', '');
        const citiKey = _.get(location, 'state.citiKey', '');
        __req
            .sendForgotPasswordEmail(citiKey, key, successSendForgotPasswordEmail, akamaiData)
            .then((resp) => {
                //console.log('====>', resp)
                if (resp.status !== WAIT_FOR_IOS_CALLBACK)
                    successSendForgotPasswordEmail(resp.data)
                else
                    console.log('waiting for ios call', resp)
            })
            .catch((error: any) => errorSendForgotPasswordEmail(error));
    }

    function successSendForgotPasswordEmail(resp: ForgotPasswordResponse) {
        if (_.get(resp, 'success', false)) {
            setShowSuccess(true);
        }
        finallySendForgotPasswordEmail();
    }

    function errorSendForgotPasswordEmail(error: any) {
        console.log("errorSendForgotPasswordEmail error::" + error);
        console.log(error);
        finallySendForgotPasswordEmail();
    }

    function finallySendForgotPasswordEmail() {
        console.log("finallySendForgotPasswordEmail::");
        setShowLoading(false);
    }

    function _onCancelBtnClick() {
        if (window.THE_DEVICE_TYPE !== DEVICE_TYPE.WEB) {
            var args = [{ "foo": "bar" }];
            device.mobileTokenProvisioned((response: any) => {

                if (!window.DEVICE_INFO_BAK)
                    window.DEVICE_INFO_BAK = { ...response }
                // console.log('common mobileTokenProvisioned SUCCESS ...', response);
                console.log('common mobileTokenProvisioned SUCCESS ...');
                if (response.success) {
                    const pathname = APP_LOCATIONS.MfaHome;
                    history.push({
                        pathname
                    });
                }
                else {
                    const pathname = APP_LOCATIONS.SignOnPage;
                    history.push({
                        pathname
                    });
                }
            }, (fail: any) => {
                console.log("common mobileTokenProvisioned FAILED");
                const pathname = APP_LOCATIONS.SignOnPage;
                history.push({
                    pathname
                });
            }, args);
        }
        else {
            const pathname = APP_LOCATIONS.SignOnPage;
            history.push({
                pathname
            });
        }
    }

    function _renderBtn() {
        if (showSuccess) {
            return (
                <Button
                    color="primary"
                    className='buttonWithMargin'
                    onClick={_onCancelBtnClick}>
                    {kCONSTANTS.DONE}
                </Button>
            );
        }

        return (
            <>
                <div className="floating-bottom-container">
                    <Button
                        color="primary"
                        className='buttonWithMargin'
                        showLoading={showLoading}
                        onClick={_onSubmitBtnClick1}>
                        {kCONSTANTS.SUBMIT}
                    </Button>
                    <Button
                        color="outline"
                        className='buttonWithMargin'
                        style={{ border: 'none', boxShadow: 'none' }}
                        onClick={_onCancelBtnClick}>
                        {kCONSTANTS.CANCEL}
                    </Button>
                </div>
            </>
        );
    }

    function _renderContentView() {
        return (
            <div className='react-container top-40 auto-overflow'>
                {
                    showSuccess
                        ? <Alert type='success' content={__constants.SEND_EMAIL_SUCCEED} style={{ marginTop: 20 }} />
                        : <>
                            <p className='text-medium'>{__constants.SEND_EMAIL_PASSWORD}</p>
                            <p className='email-text'>{_.get(location, 'state.emailId', '')}</p>
                        </>
                }
                {_renderBtn()}
            </div>
        );
    }

    return (
        <>
            <LandingHeader title={__constants.FORGOT_PASSWORD} />
            {_renderContentView()}
        </>
    );
}

export default SendEmailPage;