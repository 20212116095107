const kPUSH_NOTIFICATIONS = {
    PUSH_NOTIF: "Notifications",
    PUSH_NOTIF_HEADER: "Push Notifications",
    PUSH_NOTIF_AUTH: {
        NOT_DETERMINED: "notDetermined",
        DENIED: "denied",
        AUTHORIZED: "authorized"
    },
    PUSH_NOTIF_SOURCES: {
        CCB: "Citi Commercial Bank"
    },
    PUSH_NOTIF_ENROLL: {
        PROGRESS: "Enroll Notifications in progress...",
        SUCCESS: "You have been enrolled for Notifications",
    },
    PUSH_NOTIF_UNENROLL: {
        PROGRESS: "Unenroll Notifications in progress...",
        CONFIRM: "You're being unenrolled from Notifications",
        SUCCESS: "You have been Unenrolled for Notifications",
    },
    PUSH_NOTIF_HISTORY: {
        DEINED: {
            WARNING: "Warning",
            CONTENT: "Push Notifications are disabled on your device. Update your notification settings to receive push notifications.",
            CHANGE_BTN: "Change Settings"
        }
    },
    PUSH_NOTIF_STATUS: 'Push Notification Device Enrollment Status',
    FILTER: {
        ALL: "All",
        SECURITY: "Security",
        WIRES: "Wires",
        BILL_PAYMENTS: "Bill Payments"
    },
    FILTER_VALUE: {
        ALL: "ALL",
        SECURITY: "SECURITY",
        WIRES: "WIRES",
        BILL_PAYMENTS: "BILL_PAYMENTS"
    },
}

export default kPUSH_NOTIFICATIONS;