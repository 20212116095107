
const Message={
    
}

export const ErrorMessages={
    LOGIN_ERROR:"You cannot sign on using the data you entered. Please make corrections and try again. For assistance call customer service (800)285-1709."
}

export default Message;
