const kWIRES = {
    WIRES_TRANSACTION_HEADER: "Wires - Transaction Details",
    DEFAULT_AMOUNT: "0.00",
    INFORMATION: 'Information',
    SELECT: 'Select',
    DOWNLOADING_TEMPLATE: 'Downloading Template...',
    SEARCH_TEMPLATE_PLACEHOLDER: 'Search Template or Beneficiary Name',
    USD: 'USD',

    TYPE: [
        "Domestic",
        "Foreign",
        "FX Rate Lookup"
    ],

    THIRD_PARTY_SITE_NOTICE: "NOTICE: You are leaving a Citibank Website and going to a third party site.",

    WARNING_MESSAGES: {
        NO_FROM_SELECTION: "You must select a From account.",
        NO_COUNTRY_SELECTION: "Please select destination country.",
        NO_CURRENCY_SELECTION: "Please select Currency.",
        INVALID_SWIFT_BIC: "The SWIFT/BIC value you entered is invalid or not supported.",
        INVALID_CHIPS: "The CHIPS UID must be a 6-digit numeric value.",
        NO_FULL_BANK_INFO: "Please enter bank information.",
        NO_FULL_ADDRESS: "Sending this without an accurate routing code may DELAY the execution of this wire.<br><br>If you do not enter a routing code you must provide the full name and address of the bank.",
        INVALID_ABA_NUMBER: "Please enter valid Bank Routing Number (ABA).",
        COUNTRY_VAILD_INFORMATION: "A wire from a US-based bank account to a beneficiary in a U.S. territory (Puerto Rico, Guam, U.S. Virgin Islands, America Samoa or Northern Mariana Islands) can be sent as a Domestic wire, at the Domestic wire fee rate, if you can provide the ABA # for the beneficiary. Otherwise, such wire will be processed as a Foreign wire and the Foreign wire fee will be charged. ",
        ROUTE_CODE_WARNING_UNKNOWN: "Sending this without an accurate routing code may DELAY the execution of this wire.<br><br>Click OK to change the routing code.<br><br>Clicking Cancel will allow you to proceed without correcting the routing code.",
        NO_CASHED_COUNTRY_SELECTION: "Please select a cashed-in country.",
        NO_BENE_ADDRESS: "Beneficiary Address is a required entry.",
        BENE_PHONE_REQUIRED: "Beneficiary Phone is a required entry.",
        AMOUNT_RADIO_SELECTION_ERROR: "Please use the radio buttons to indicate the currency designation of the amount",
        NO_PURPOSE_CODE_SELECTION: "Select a Purpose Code",
        NO_PURPOSE_CODE_ACT_SELECTION: "Select a Company Industry",
        NO_PURPOSE_CODE_FREE_FORM: "Please enter reason for payment",
        NO_NUMBER_OF_TRANSFERS: "The number of transfers must be specified. Please enter a number greater than one",
        WIRE_TRANSFER_NO_COUNTRY: "Destination country is not supported, please update beneficiary details to proceed with this payment.",
        TMX_INTERNAL_ERROR: "Internal error/server unavailability",
        TMX_INTERNAL_ERROR_CONTENT:
            "I am sorry But I am unable to do that right now Contact Customer Service at (800) 285 1709 and provide the following information so we might better assist you: Identifier:401, Resp Code: API Failure. For hearing impaired call (800) 788 0002.",
        CONSUMER_PRIVATE_BANK_USER: 'To process your foreign wire request for the selected account, please contact your Citi Private Bank representative.',
        CONSUMER_NON_PRIVATE_BANK_USER: 'The selected source account cannot be used for foreign wires.      Contact customer service for more information.'
    },

    WIRES_INITIATE: {
        WIRES_INITIATE_TITLE: "Wires - Initiate",

        ERRORS: {
            GETTING_USERINFO_FAILED: "Failed to fetch User Information",
            BENEFICIARY_SETUP_FAILED: "Failed to setup beneficiary",
            ACCOUNT_SETUP_FAILED: "Failed to fetch accounts",
            SCHEDULING_SETUP_FAILED: "Failed to fetch scheduling data",
            GETTING_TEMPLATES_FAILED: "Failed to load template data",
            IRS_ERROR:'US Federal Tax payments must be initiated on CitiBusiness Online desktop.'
        },
        PROGRESS_TEXT: {
            CONTACTING_SERVER: "Contacting Server...",
            SETUP_ACCOUNTS: "Fetching acccounts...",
            SETUP_BENEFICIARY: "Fetching beneficiary details...",
            SETUP_SCHEDULING: "Setting up scheduling data...",
            GET_TEMPLATES: "Fetching templates...",
            GET_COUNTRY_SPECIFIC_INFORMATION: "Fetching Country Specific Information",
            GET_ROUTE_INFORMATION: "Fetching Route Information"
        },
        BTN_START_FROM_TEMPLATE: "Start from template",
        ICON_START_FROM_TEMPLATE: "lmnicon lmnicon-cloud-download",
        SCHEDULING_OPTIONS: {
            SCHEDULING_TITLE: "Schedule",
            SCHEDULING_OPTION_ONCE_VALUE: "once",
            SCHEDULING_OPTION_RECURRING_VALUE: "recurring",
        },
        TRANSFER_DATE_OPTIONS: {
            TRANSFER_DATE_TITLE: "Transfer Date",
            TRANSFER_DATE_OPTIONS_IMMEDIATE_TITLE: "Immediate",
            TRANSFER_DATE_OPTIONS_IMMEDIATE_VALUE: "immediate",
            TRANSFER_DATE_OPTIONS_FUTURE_TITLE: "Future",
            TRANSFER_DATE_OPTIONS_FUTURE_VALUE: "future",
            INFO: "Wires are not sent on weekends or CitiBank holidays.",
            FOREIGN_WIRE_INFO: "One time wire transfers are sent in specified currency upon approval. \nReccuring wire transfers are sent in USD.",
        },
        ADDITIONAL_INFO: {
            TITLE: "Additional Information",
            INFO: "Always verify new or updated payment instructions and beneficiary account information with a phone call to a trusted source before sending a wire transfer",
        },
        AMOUNT: "Amount",
        ACTION_PROCEED_TO_REVIEW: "Proceed to Review",
        CANCEL: "Cancel",
        TO: "To",
        BENEFICIARY: "Beneficiary",
        COUNTRY: 'Country',
        FROM: "From",
        SELECT_AN_ACCOUNT: "Select an Account",
        INPUT_BENEFICIARY_INFO: "Input Beneficiary Information",
        MAKE_ANOTHER_BUTTON: "Setup Another Wire",
        BACK: "Back",
        SUBMIT: "Submit",
        DETAILS: "Details",
        SECOND_DATE: "Second Date",
        TRANSACTION_FREQUENCY: "Transaction Frequency",
        STOP: "Stop",
        SELECT_TRANSACTION_FREQUENCY: "Select Transaction Frequency",
        ALERT_SECTION: {
            NOTIFICATION_ONCE: "\nWires instructions must be received by 6:45 PM EDT for same day processing. Wires initiated on a non-business day will be sent the next business day.\n",
            NOTIFICATION_RECURRING: "\nThe first transfer instruction in a series of wires must be received by 6:30 PM EDT. Any scheduled recurring wire transfers that fall on a non-business day will be sent the next business day.\n",
            ERROR: "The Amount is over the limit allowed for the Account",
            PENDING: "Wire is Pending \n Confirmation # ",
            APPROVAL: "Sent to Approvals",
            FRAUD_QUEUE:
                "Thank you for transfer request but your transaction is still pending. All transactions are subject to routine review and approval before they can be release. No further action is required on your part. Should we require more information in order to approve the transfer, we will contact you by telephone. If we are unable to reach you or if your transaction cannot be approved for the other reasons we will notify you of such action",
        },
        INSUFFICIENT_SECTION: {
            INSUFFICIENT_FUNDS_TITLE: "Insufficient Funds",
            INSUFFICIENT_FUNDS:
                "There are insufficient funds available in the selected account to process this transfer at this time.\n\n If you still wish to continue with this transfer as soon as sufficient funds are available in this account press Continue, otherwise press Cancel\n\n",
        },

        BENEFICIARY_SELECTION: {
            BENEFICIARY_RADIO_USBANK_TITLE: "US Bank or Credit Union",
            BENEFICIARY_RADIO_USBANK_VALUE: "usbank",
            BENEFICIARY_RADIO_INTERMEDIARY_TITLE: "Intermediary Bank",
            BENEFICIARY_RADIO_INTERMEDIARY_VALUE: "interbank",
            SEARCH_BY_ABA_LABEL: "Search by ABA Number",
            SEARCH_BANK_NAME_LABEL: "Bank Name",

            OPTION_STARTS_WITH_NAME: "Starts With",
            OPTION_STARTS_WITH_VALUE: "STARTS_WITH",
            OPTION_CONTAINS_NAME: "Contains",
            OPTION_CONTAINS_VALUE: "CONTAINS",

            BENEFICIARY_SEARCH_BUTTON_TEXT: "Search",
        },

        BENEFICIARY_WARNING: {
            BENEFICIARY_WARNING_TITLE: "Changing Beneficiary Account Number",
            BENEFICIARY_WARNING_SUBTITLE: "Have you verified this payee?",
            BENEFICIARY_WARNING_DETAIL: "Follow this simple step to help protect your business from FRAUD. If you received the new payee details via email, call the sender back via phone-on a known/trusted number- to verify the request before sending any funds."
        },

        AMOUNT_FIELD: {
            AMOUNT_ENTERED_IN: "Amount entered in ",
            TO_BE_CONVERTED_TO: " to be converted to ",
            RADIO_CONTROLS: {
                RADIO_NON_FLEXIBLE_TEXT: "Fixed",
                RADIO_NON_FLEXIBLE_VALUE: "NON_FLEX",
                RADIO_FLEXIBLE_TEXT: "Flexible",
                RADIO_FLEXIBLE_VALUE: "FLEXIBLE",
            }
        },
        DAYS: [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
        ],

        FOREIGN_WIRE_NOTE_1: "When initiating a wire transfer in a foreign currency, the funds will be converted from U.S. dollars into the specified foreign currency before sending based on our exchange rate, which includes a commission for the conversion service.",
        FOREIGN_WIRE_NOTE_2: "US law, including Office of Foreign Assets Control (OFAC) regulations, restricts funds transfers to certain countries, organizations, and individuals.  Attempted transfers to these recipients may be subject to seizure unless the transfer is authorized by OFAC license.  The restricted countries, organizations, and individuals are subject to change.  For more information, contact your Citibank representative.",
    },

    WIRES_TRANSACTION_DETAIL: {
        FROM: 'FROM',
        BENEFICIARY: 'Beneficiary',
        TYPE: 'Type',
        STATUS: {
            INITIATOR_NOT_AUTHORIZED_FOR_TRANSACTION:
                "You are not an authorized initiator for this transaction",
            ACC_BATCH_TRF_APP_WORKFLOW_NOT_CONFIGURED_FOR_SELECTED_BUSINESS:
                "Batch Account Transfer Approval Workflow is not configured for this transaction amount.",
            ACC_BATCH_TRF_APP_WORKFLOW_MISSING_FOR_SELECTED_BUSINESS:
                "Batch Account Transfer Approval Workflow is not present for Account Transfers.",
            APPRV_WORKFLOW_NOT_CONFGD_FOR_TRN_AMT_33:
                "The Approval Workflow on this account is not configured for this transaction amount",
            NO_APPROV_WORKFLOW_FOR_ACC_34:
                "The Account is not linked to any Approval Workflow for ",
            TECHNICAL_ERROR:
                "The system is temporarily unable to complete this request.Contact Customer Service at 1-800-285-1709.For hearing impaired call 1 (800) 788 0002",
            HELD_FOR_SIGNATURE_APRROVAL:
                "Your request is being held for signature in Approvals.",
            ACCOUNT_UNAUTHORIZED_SIGNER:
                "You are not an authorized signer for this account.",
            AMOUNT_UNAUTHORIZED_SIGNER:
                "You are not an authorized signer for this amount.",
            UNDER_PROCESS:
                "Daily end-of-day processing is underway. Please wait a minute and try again.",
            ACCOUNT_CLOSED: "The selected account is closed.",
            CUSTOMER_SERVICE_FOR_CANCELLATION:
                "To cancel this request, you must call customer service.",
            ACCOUNT_INACTIVE: "Cannot complete request. Your account is in-active.",
            ALREADY_IN_APPROVAL: "Pending in Approval.",
        },
        ALERT: {
            // REVIEW_HEADER: 'Waiting for Review',
            REVIEW_HEADER: "Warning",
            REVIEW_TEXT: "Call Customer Service at 1-866-583-2354",
            REACTIVATE_SUCCESS_TEXT: "Your wire has been reactivated",
            SUSPEND_SUCCESS_TEXT:
                "This recurring transfer instruction will remain suspended until you reactivate it. If you do not reactivate this transaction within two years, it will be cancelled",
            CANCEL_SUCCESS_TEXT: "Your wire has been canceled",
            NOT_ENTITLED_TO_USE_ACCOUNT:
                "You are not entitled to use the account that is used by this model",
        },
    },

    FOREIGN_WIRES_INITIATE: {
        INPUT_COUNTRY_CURRENCY_BANK_INFORMATION: 'Input Country, Currency and Bank Information',
        FETCHING_ROUTE_INFORMATION: 'Fetching Route Information',
        FETCHING_COUNTRIES: 'Fetching Countries',
        US_DOLLARS: 'US Dollars',
        CASHED_IN_COUNTRY: 'Cashed in Country',
        SELECT_ONE: 'Select One',
        USDObj: {
            "currencyCode": "US Dollars",
            "currency": "USD",
            "transferType": "T"
        },

        ADDRESS: {
            ADDRESS: "Address",
            CITY: "City",
            POSTAL_CODE: "Postal Code",
        },

        ROUTE_TYPE: {
            SWIFT: 'swift',
            CHIPS: 'chips',
            OTHER: 'othercode',
        },

        ACTION_CODE_MESSAGES: {
            ACTION_CODE_SEVEN_DEFAULT: "The routing code entered is not in our records.<br><br>Sending this wire without an accurate routing code may DELAY the execution of this wire.<br><br>Click Ok to correct.<br><br> Clicking Cancel will allow you to use this routing code but you must fill the in the complete bank name and address.",
            ACTION_CODE_SEVEN: "The {{routingCode}} entered is not in our records.<br><br>Click Ok to use {{routeType}}.<br><br> Click Cancel to correct the routing information",
            ACTION_CODE_TWO: " entered is not in our records.",
            ACTION_CODE_THREE: "does not correspond to a bank in the destination country",
            ACTION_CODE_FOUR: "The routing codes do not correspond to the same bank.",
            ACTION_CODE_SIX: "The 'sortName from response' is not in our records.<br>CHIPS/UID is the preferred routing code for this transaction.<br>Correct the 'sortName from response' or erase it and use a CHIPS/UID or SWIFT/BIC.<br> clear foriegn Bank fields"
        },

        PURPOSE_CODE: "Purpose Code",
        PURPOSE_CODE_ACT: "Your Company Industry",

        READ_MORE: 'Read More',
        AMOUNT_NOTE_TITLE: "By sending a wire in a foreign currency, you: ",
        AMOUNT_NOTE_DESCRIPTION_ARRAY: ["Know the amount in local currency being sent.",
            "Lock in the exchange rate to protect against rate fluctuations",
            "Avoid delays at the recipient bank for currency exchange"],

        SPECIAL_INS_MESSAGE: {
            RFED: "Enter the reason for sending the foreign wire in Russian Federation in the special instructions section below.",
            CA: "Enter the bank's transit number in the Special Instructions.",
            ISRAEL: "Enter the Bank Number Code and the Branch Number Code in the Special Instructions.<br/><br/>Enter the reason for  the transfer in the Special Instructions.",
            OTHER: "Enter the reason for the transfer in the Special Instructions.",
        },

        WIRE_DELIVERY_METHOD_OPTIONS: {
            WIRE_DELIVERY_METHOD_TITLE: "Wire Delivery Method",
            INT_MONEY_TRANSFER: "International Money Transfer",
            INT_CHECK: "International Check"
        },
        WIRE_TO_COUNTRY_OPTIONS: {
            WIRE_TO_COUNTRY_TITLE: 'Wire to Country',
            WIRE_TO_NOTE: "Always verify new or updated payment instructions and beneficiary account information with a phone call to a trusted source before sending a wire transfer.",
            DESTINATION_COUNTRY: "Destination Country",
            COUNTRY: "Country",
            SWIFT_BIC: "SWIFT/BIC",
            SWIFT_TOOLTIP_BOLD: "You’re leaving CitiBusiness Online now and are about to open  the SWIFT/BIC website .",
            SWIFT_TOOLTIP: "The Society for Worldwide Interbank Financial Telecommunications/Bank Identifier Code (SWIFT/BIC) site may have a different privacy policy with less security than Citibank. Please note that Citibank & its affiliates are not responsible for the products, services and content on third party websites.",
            CHIPS_TOOLTIP_BOLD: "You’re leaving CitiBusiness Online now and are about to open  the CHIPS/UID website .",
            CHIPS_TOOLTIP: "The Clearing House Interbank Payments System /Universal Identifier (CHIPS/UID) site may have a different privacy policy with less security than Citibank. Please note that Citibank & its affiliates are not responsible for the products, services and content on third party websites.",
            INTERMEDIARY_OPTIONS: {
                INTERMEDIARY_BANK: "Intermediary Bank Routing Number(ABA)",
                SEARCH: 'Search'
            }
        },

        FX_SWIFT: {
            DESCRIPTION: "<strong>SWIFT/BIC - Society for Worldwide Interbank Financial Telecommunication/Bank Identifier Code</strong> <br>The SWIFT/BIC is used to help identify the wire destination. Providing this code may speed the processing of your wire transfer.<br>If you do not know the SWIFT/BIC of the destination bank, you can search for it using the SWIFT website. Just click “More search criteria?” and then enter the bank’s name, city and country.",
            LOOKUP_SWIFT: "Lookup SWIFT/BIC Code",
            Link: 'https://www2.swift.com/bsl/'
        },

        FX_CHIPS: {
            DESCRIPTION: "<b>CHIPS/UID - Clearing House Interbank Payments System/Universal Identifier</b><br>The CHIPS/UID is used to help identify the wire destination. Providing this code may speed the processing of your wire transfer.<br>If you do not know the CHIPS/UID of the destination bank, you can look it up on the CHIPS website.",
            LOOKUP_CHIPS: "Lookup CHIPS/UID Code",
            Link: 'https://www.theclearinghouse.org/uid-lookup'
        },

        TOOL_TIP_TEXT: {
            INI_MONEY_TR: "The fastest way to make payments in large denominations to recipients in major countries.  Transfers can be made in selected foreign currencies.  The usual delivery time is 2-5 business days, but can be longer, based on the final destination and banking policies abroad.",
            INI_CHECK: "Drawn on a major bank in the currency of the destination country so funds will be available to the recipient as quickly as possible.  You can designate more than one recipient.  International Checks are available in select foreign currencies and will be addressed by Citibank to the recipient and dropped in the local mail in 2-4 days.  Select this method if you know the address but not the account number of the recipient.",
            SWIFT: "The Society for Worldwide Interbank Financial Telecommunications/Bank Identifier Code (SWIFT/BIC) site may have a different privacy policy with less security than Citibank. Please note that Citibank & its affiliates are not responsible for the products, services and content on third party websites.",
            CHIPS: "The Clearing House Interbank Payments System /Universal Identifier (CHIPS/UID) site may have a different privacy policy with less security than Citibank. Please note that Citibank & its affiliates are not responsible for the products, services and content on third party websites.",
        },

        ACC_NUM_NOTES: [
            "Use care to enter the correct ",
            " in the Account Number field.  Not using a correct ",
            " may result in delayed or rejected wires and/or additional processing fees."
        ]
    },

    EXCHANGE_RATE_MESSAGES: {
        FETCHING_EXCHANGE_RATE: 'Fetching Exchange Rate',
        AT_THE_RATE_OF: 'at the rate of',
        US_DOLLARS_PER: 'US Dollars per',
        RATE_EXPIRES_IN: "Exchange rate expires in ",
        EXCHANGE_RATE_EXPIRE_NOTE: "After four minutes, this exchange rate will expire and\n a new rate will need to be obtained to continue.",
        EXCHANGE_RATE_INFO: "When initiating a wire in a foreign currency, be aware that the US Dollar equivalent due to fluctuations in FX rates may exceed the entitlement limits for the initiating user.",
        ONE_TEN_ONE_THREE: "Online rates are not available. Please contact your WorldLink trading Representative.",
        ONE_FOUR: "Online rates are not available. Please try again later or contact your WorldLink Trading Representative.",
        THREE_SIX: "The USD equivalent exceeds the maximum. Try another amount.",
        THREE_NINE: "The USD equivalent is less than the minimum. Try another amount."
    },

    PASSWORD_AND_TOKEN_ERROR_MESSAGE: {
        THESECURITYINFORMATION: "The security information you entered is not valid. Please try again.",
        THERESEEMSTOBEAPROBLEM: "There seems to be a problem. Please try again, or call 1-800-285-1709 for assistance.",
        THESECURITYREASONS: "For security reasons you will be logged off. Please call +1 (859) 283 3960, email to ccbserviceteam@citi.com for assistance",
    },

    WIRE_TRANSFERS_STATUS: {
        WIRE_INSTRUCTION_SETUP: "Your Wire Transfer ",
        REFERENCE_CHIPS: "Fed Ref/CHIPS: ",
        GLOBAL_ID: "Global ID: ",
        DODD_FRANK: "Held For Dodd Frank until ",
        FRAUD: "Waiting for Review. Call Customer Service at ",
        SUSPENDED_DESCRIPTION: "Suspended on {0} by {1}",
        DETAIL_INSUFFICIENT_FUNDS: "There are not sufficient available funds in the originating account to complete this wire transfer.",
        DETAIL_SUSPEND: "The recurring wire transfer has been suspended and will no longer be processed unless reactivated.",
        PLEASE_NOTE: 'All Transactions are subject to routine review and approval before they can be released. No further action is required from you at this time. Should we require more information in order to approve the transfer, we will contact by telephone. If we are unable to reach you , or if your transaction cannot be approved for other reasons, we will notify you.'
    },

    PAYMENTS_AND_TRANSFERS_STATUS: {
        STATUS_SUSPENDED: "Suspended",
        STATUS_SCHEDULED: "Scheduled",
        STATUS_COMPLETED: "Completed",
        STATUS_SUBMITTED: "Submitted",
        STATUS_AWAITING_APPROVAL: "Awaiting Approvals",
        STATUS_PENDING_VERIFICATION: "Pending Verification",
        STATUS_DATA_LAKE: "Completed-DataLake",
        STATUS_HELD_FOR_APPROVAL: "pending approval in the approvals queue",
        STATUS_HELD_FOR_WIRE_APPROVAL: "pending approval in the",
        APPROVALS_QUEUE: "Approvals Queue",
        STATUS_PENDING: "Pending",
        STATUS_COMPLETE: "Complete",
        STATUS_CANCELED: "Canceled",
        STATUS_INSUFFICIENT_FUNDS: "Insufficient funds",
        STATUS_REJECTED: "Declined",
        STATUS_PARTIALLY_PAID: "Partially Paid",
        STATUS_SENT: "Sent",
    },

    FX_RATE_LOOKUP: {
        FROM: 'From',
        TO: 'To',
        AMOUNT: 'Amount',
        CALCULATE: 'Calculate',
        SELECT_FROM_CURRENCY: 'Select From Currency',
        SELECT_TO_CURRENCY: 'Select To Currency',
    },
    VALIDATION_MESSAGES: {
        EGYPT: "Invalid IBAN format for Egypt.<br> Valid Account# is like: EG620001000100000004004095444. <br> Do you want to Proceed?",
        ISRAEL: "Invalid IBAN format for Israel.",
        UKRAINE: "Invalid IBAN format for Ukraine.",
        LIBYA: "Invalid IBAN format for Libya.",
        COSTA_RICA: "Invalid IBAN format for Costa Rica.",
        DOMINICAN_REPUBLIC: "Invalid IBAN format for Dominican Republic.",
    }
};

export default kWIRES;
