import { isValidElement, useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import Button from '../../../ui-kit/button/button';
import { TMXChallengePhoneOTP, TMXChallengeEmailOTP } from '../../pre-signon.chunk.index';
import __constants from '../../../common/constants';
import kCONSTANTS from '../../../common/constants/GENERAL';
import kBIOMETRICS from '../../../common/constants/BIOMETRICS';
import ApplicationContext from '../../../context/application-context';
import { DEVICE_TYPE } from '../../../device/xdevice';
import { initializeTMX, updateRegistrationResult } from './biometrics.util';
import faceIcon from '../../../assets/images/face_id.svg';
import fingerprintIcon from '../../../assets/images/fingerprint.png';
import './biometrics.style.scss';
import { renderSessionSuspended } from '../../common-ui/common-ui.components';
import { logOut } from '../../../utils/common.util';
import CustomModal from '../../../ui-kit/custom-modal/custom-modal.component';
import { analyticsBranded } from '../../../utils/event-tracking.util';

interface Props {
    onClose?: Function;
}

function BiometricsBindView(props: Props) {
    const context = useContext(ApplicationContext);
    const biometricsInfo = context?.Security.getBiometricsInfo();
    const defaultContent = (biometricsInfo?.supportedBiometricID?.includes(kBIOMETRICS.BIOMETRICS_ID.FINGERPRINT))
        ? kBIOMETRICS.BIOMETRICS_ENROLL.CONTENT.replace(/Face ID/g, window.THE_DEVICE_TYPE === DEVICE_TYPE.IOS ? kBIOMETRICS.BIOMETRICS_ID.TOUCH_ID : kBIOMETRICS.BIOMETRICS_ID.FINGERPRINT)
        : kBIOMETRICS.BIOMETRICS_ENROLL.CONTENT;

    const [showModal, setShowModal] = useState(true);
    const [showSessionSuspended, setShowSessionSuspended] = useState(false);

    const [title, setTitle] = useState(kBIOMETRICS.BIOMETRICS_ENROLL.TITLE);
    const [content, setContent] = useState(defaultContent);
    const [footer, setFooter] = useState<string>(kBIOMETRICS.BIOMETRICS_FOOTER.ENROLL);
    const [isEmailPhChallengeType, setEmailPhChallengeType] = useState(false)

    const [showLoading, setShowLoading] = useState(false);

    useEffect(() => {
        context?.DEVICE.changeBioCatchContext(kBIOMETRICS.BIOMETRICS_CONTEXT.ENROLL);

        if (context.Security?.isBiometricsDown()) {
            setContent(kBIOMETRICS.BIOMETRICS_OFFLINE.ENROLL);
            setFooter(kCONSTANTS.OK);
        }
    }, []);

    /**
    * ADA Implementation to hide the cbusol view when modal is open
    * 
    */
    useEffect(() => {
        const ele: Element | null = document.querySelector('#cbusol-view');
        if (ele) {
            if (showModal) {
                ele.setAttribute('aria-hidden', 'true');
            } else {
                ele.removeAttribute('aria-hidden');
            }
        }
        return () => {
            if (ele) {
                ele.removeAttribute('aria-hidden');
            }
        }

    }, [showModal]);

    function _onClose() {
        setShowModal(false);

        if (_.isFunction(props.onClose)) {
            props.onClose();
        }
    }

    function _onTMXChallengeVerifyDone() {
        setShowLoading(true);
        setTitle(kBIOMETRICS.BIOMETRICS_ENROLL.TITLE);
        setContent(defaultContent);
        setFooter(kBIOMETRICS.BIOMETRICS_FOOTER.ENROLL);

        _sendEnollReq();
    }

    function _enrollBiometryIdFailed(challengeType?: string) {
        setShowLoading(false);

        let content: any = kBIOMETRICS.BIOMETRICS_ENROLL.ERROR_CONTENT;
        if (challengeType) {
            if (challengeType === kBIOMETRICS.BIOMETRICS_CHALLENGE_TYPE.EMAIL) {
                setEmailPhChallengeType(true)
                content = <TMXChallengeEmailOTP onDone={_onTMXChallengeVerifyDone} onCanceled={_onClose} />;
            } else if (challengeType === kBIOMETRICS.BIOMETRICS_CHALLENGE_TYPE.PHONE) {
                setEmailPhChallengeType(true)
                content = <TMXChallengePhoneOTP onDone={_onTMXChallengeVerifyDone} onCanceled={_onClose} />;
            } else if (challengeType === __constants.TMX_CHALLENGE_SESSION_SUSPENDED) {
                setEmailPhChallengeType(false)
                setShowSessionSuspended(true);
            }
        } else {
            updateRegistrationResult(false);
        }

        setContent(content);
        setFooter(kCONSTANTS.OK);

        context?.Security.setBiometricsInfo({ ...biometricsInfo, ...{ setUpBiometrics: false } });
    }

    function _sendEnollReq() {

        context?.DEVICE.bindBiometryId((resp: any) => {
            console.log('Transmit - Biometric - bindBiometryId - succeed - ', resp);
            if (_.get(resp, 'success', false)) {
                setShowLoading(false);

                setTitle(kBIOMETRICS.BIOMETRICS_ENROLL.SUCCESS_TITLE);
                setContent(kBIOMETRICS.BIOMETRICS_ENROLL.SUCCESS_CONTENT);
                setFooter(kCONSTANTS.OK);

                context?.Security.setBiometricsInfo({ ...biometricsInfo, ...{ policy: kBIOMETRICS.BIOMETRICS_POLICY.LOGIN } });

                analyticsBranded(9, "Biometrics Enrolled", "", false, false, "");

                updateRegistrationResult(true);

                return;
            }

            _enrollBiometryIdFailed();
        }, (error: any) => {
            console.log('Transmit - Biometric - bindBiometryId - failed ', error);

            _enrollBiometryIdFailed();
        });
    }

    function _handleFooterBtnClicked() {
        if (footer && footer === kBIOMETRICS.BIOMETRICS_FOOTER.ENROLL) {
            setShowLoading(true);

            initializeTMX(kBIOMETRICS.BIOMETRICS_EVENT_TYPE.ENROLL, false, _sendEnollReq, _enrollBiometryIdFailed);
            return;
        }

        _onClose();
    }

    function _renderFooter() {
        if (isValidElement(content)) {
            return null;
        }

        return <>
            {
                footer === kBIOMETRICS.BIOMETRICS_FOOTER.ENROLL
                    ? <Button
                        id={`btn-biometric-bind-${kCONSTANTS.CANCEL}`}
                        color='outline'
                        disabled={showLoading}
                        onClick={() => {
                            context?.Security.setBiometricsInfo({ ...biometricsInfo, ...{ setUpBiometrics: false } });
                            _onClose()
                        }}
                        containerView='Biometrics Bind'>
                        {kCONSTANTS.CANCEL}
                    </Button>
                    : null
            }
            <Button
                id={`btn-biometric-bind-${footer}`}
                color='primary'
                disabled={showLoading}
                showLoading={showLoading}
                onClick={_handleFooterBtnClicked}
                containerView='Biometrics Bind'>
                {footer}
            </Button>
            {showSessionSuspended ? renderSessionSuspended(logOut, false) : null}
        </>
    }

    function _renderTitle() {
        if (isValidElement(content)) {
            return null;
        }

        return <div className='biometrics-header-with-img'>
            {
                title !== kBIOMETRICS.BIOMETRICS_ENROLL.SUCCESS_TITLE
                    ? <img
                        aria-hidden='true'
                        alt=''
                        src={biometricsInfo?.supportedBiometricID?.includes(kBIOMETRICS.BIOMETRICS_ID.FINGERPRINT) ? fingerprintIcon : faceIcon}
                    />
                    : null
            }
            <p>{title}</p>
        </div>
    }


    return (
        <CustomModal
            showModal={showModal}
            isCloseable={false}
            wrapperClass={`biometrics-modal ${isEmailPhChallengeType ? 'biometrics-custom-title' : ''}`.trim()}
            title={_renderTitle()}
            renderFooter={_renderFooter}
            body={
                isValidElement(content)
                    ? content
                    : <span className='biometrics-content'>{content}</span>
            }
            bodyStyle={{ maxHeight: 'none' }}
        />
    )
}

export default BiometricsBindView;