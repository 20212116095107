import _ from 'lodash';
import __constants from '../common/constants';
import { DEVICE_TYPE } from '../device/xdevice';

import {
	BrowserDetect,
	IE_FingerPrint,
	Mozilla_FingerPrint,
	Opera_FingerPrint,
	FingerPrint,
	encode_deviceprint,
} from '../js/fp_AA';

export function getFingerPrint() {
	BrowserDetect.init();
	let fp: any;
	switch (_.get(BrowserDetect, 'browser', null) as any) {
		case 'Explorer':
			fp = new IE_FingerPrint();
			break;
		case 'Firefox':
			fp = new Mozilla_FingerPrint();
			break;
		case 'Opera':
			fp = new Opera_FingerPrint();
			break;
		default:
			fp = new FingerPrint();
	}
	const fingerPrint = {
		fp_browser: fp.deviceprint_browser(),
		fp_display: fp.deviceprint_display(),
		fp_software: fp.deviceprint_software(),
		fp_timezone: fp.deviceprint_timezone(),
		fp_language: fp.deviceprint_language(),
		fp_java: fp.deviceprint_java(),
		fp_cookie: fp.deviceprint_cookie(),
		deviceprint: encode_deviceprint()
	};
	return fingerPrint;
}

export function getUserAgent1() {
	let type = "Web";
	if (window.THE_DEVICE_TYPE === DEVICE_TYPE.ANDROID)
		type = "Android";
	else if (window.THE_DEVICE_TYPE === DEVICE_TYPE.IOS)
		type = "iOS";
	return "Citibusiness/" + __constants.APP_VERSION + "/(" + type + ")";
}