import http, { RequestMethod } from "./api.core";
import kCONSTANTS from "../common/constants/GENERAL";
import kEVENT_TRACKING from "../common/constants/EVENT_TRACKING";
import { AxiosResponse } from "axios";
import BUILD_UTIL from "../utils/build.util";
//import MOCKED_API from "./mocks/mocks";

let MOCKED_API: any

export class RemoteCheckDepositsAPI {


    constructor() {
        const isProd = (process.env.NODE_ENV === 'production');
        const m = require(isProd ? './mocks/prod.mock.ts' : './mocks/mocks.ts').default
        // const m = require(isProd ? './mocks/prod.mock.ts' : './mocks/mocks.copy.ts').default
        MOCKED_API = m
    }

    selectADepositAccount(callBack?: Function) {
        // GET 
        // https://mobileuat.citibusinessonline.com/selectADepositAccount/15/en?_=1651599239532
        return http.makeHttpRequest(
            RequestMethod.GET,
            `${window.ROOT}selectADepositAccount/${kCONSTANTS.APP_VERSION_DIR}/${kCONSTANTS.LANG}?_=${Date.now()}`,
            null,
            null,
            callBack,
            kEVENT_TRACKING.ChannelEnum.DEPOSIT_ACCOUNTS, 
            "remoteCheckDeposits",
            MOCKED_API?.REMOTE_CHECK_DEPOSITS?.SELECT_AN_ACCOUNT) as Promise<AxiosResponse<any>>;
    }

    //rcdConfirmation
    // POST https://mobileuat.citibusinessonline.com/rcdDepositConfirmationPage/15/en
    //{checkUploadStatus: true,depositAmount: depositAmount,depositToAccountKey: depositAccountKey,frontCheckBase64: checkFront,backCheckBase64: checkBack,memo: memo }
    //response {sucess:true}
    completeCheckDeposit(depositAmount: string, depositAccountKey: string, checkFront: string, checkBack: string, memo: string = '', callBack?: Function) {
        return http.makeHttpRequest(
            RequestMethod.POST,
            `${window.ROOT}rcdDepositConfirmationPage/${kCONSTANTS.APP_VERSION_DIR}/${kCONSTANTS.LANG}`,
            null,
            { checkUploadStatus: true, depositAmount: depositAmount, depositToAccountKey: depositAccountKey, frontCheckBase64: checkFront, backCheckBase64: checkBack, memo: memo },
            callBack,
            kEVENT_TRACKING.ChannelEnum.DEPOSIT_ACCOUNTS, 
            "remoteCheckDeposits",
            MOCKED_API.REMOTE_CHECK_DEPOSITS?.COMPLETE_CHECK_DEPOSIT) as Promise<AxiosResponse<any>>;
    }

    // upload check image
    // request {checkUploadStatus: true,	depositToAccountKey: depositAccountKey,			frontCheckBase64: checkFront		}
    // post https://mobileuat.citibusinessonline.com/uploadRCDFrontImage/15/en
    // response
    uploadCheckFrontImage(depositAccountKey: string, checkFront: string, callBack?: Function) {
        console.log('upload check front image...');
        return http.makeHttpRequest(
            RequestMethod.POST,
            `${window.ROOT}uploadRCDFrontImage/${kCONSTANTS.APP_VERSION_DIR}/${kCONSTANTS.LANG}`,
            null,
            { checkUploadStatus: true, depositToAccountKey: depositAccountKey, frontCheckBase64: checkFront },
            callBack,
            kEVENT_TRACKING.ChannelEnum.DEPOSIT_ACCOUNTS, 
            "remoteCheckDeposits",
            MOCKED_API?.REMOTE_CHECK_DEPOSITS?.COMPLETE_CHECK_DEPOSIT) as Promise<AxiosResponse<any>>;
    }

    // POST https://mobileuat.citibusinessonline.com/rcdTransactions/15/en
    getRCDTransactions(callBack?: Function) {
        console.log('get rcd transactions...');
        return http.makeHttpRequest(
            RequestMethod.POST,
            `${window.ROOT}rcdTransactions/${kCONSTANTS.APP_VERSION_DIR}/${kCONSTANTS.LANG}`,
            null,
            { "startRow": 0, "limit": 25 },
            callBack,
            kEVENT_TRACKING.ChannelEnum.DEPOSIT_ACCOUNTS, 
            "remoteCheckDeposits",
            MOCKED_API?.REMOTE_CHECK_DEPOSITS?.RCD_TRANSACTIONS
        ) as Promise<AxiosResponse<any>>;
    }


    // POST https://mobilestg.citibusinessonline.com/rcdTransactionItems/14/en
    getRCDTransactionItems(tid: string, callBack?: Function) {
        console.log('get transaction items...');
        return http.makeHttpRequest(
            RequestMethod.POST,
            `${window.ROOT}rcdTransactionItems/${kCONSTANTS.APP_VERSION_DIR}/${kCONSTANTS.LANG}`,
            null,
            { "transactionId": tid },
            callBack,
            kEVENT_TRACKING.ChannelEnum.DEPOSIT_ACCOUNTS, 
            "remoteCheckDeposits",
            MOCKED_API?.REMOTE_CHECK_DEPOSITS?.RCD_TRANSACTION_ITEMS
        ) as Promise<AxiosResponse<any>>;
    }



    // POST https://mobilestg.citibusinessonline.com/rcdTransactionItemCheckImage/14/en
    getCheckImage(front: boolean = true, irn: string, callBack?: Function) {
        let mocked: any = undefined
        let img = front ? "FrontImage" : "BackImage";
        if (BUILD_UTIL.isDev()) {
            console.log('rcd history..get check image...');

            mocked = {
                "success": true,
                "message": "",
                "elementType": img,
                "base64Image": front ? MOCKED_API?.REMOTE_CHECK_DEPOSITS?.CHECK_IMG_FRONT_2 : MOCKED_API?.REMOTE_CHECK_DEPOSITS?.CHECK_IMG_BACK_2
            }
        }
        return http.makeHttpRequest(
            RequestMethod.POST,
            `${window.ROOT}rcdTransactionItemCheckImage/${kCONSTANTS.APP_VERSION_DIR}/${kCONSTANTS.LANG}`,
            null,
            { "elementType": img, "id": "collapse0", "width": 800, "irn": irn },
            callBack,
            kEVENT_TRACKING.ChannelEnum.DEPOSIT_ACCOUNTS, 
            "remoteCheckDeposits",
            mocked
        ) as Promise<AxiosResponse<any>>;
    }

    // https://mobilestg.citibusinessonline.com/checkRemoteCheckDepositAccesibility/15/en
    // POST
    // request: {"deviceManufacturer":"motorola","deviceModel":"moto g pure","deviceOSVersion":"11","latitude":39.7792637,"longitude":-84.0703263}
    // response {"success":true,"message":""}
    //  getInfoForDeluxe
    // deviceManufacturer: b.manufacturer,
    // 					deviceModel: b.model,
    // 					deviceOSVersion: b.version,
    // 					latitude : b.latitude,
    // 					longitude : b.longitude
    checkRCDAccessibility(data: any, callBack?: Function) {
        console.log('check RCD Accessibility...');
        const mocked = {
            "success": true,
            "message": ""
        }
        return http.makeHttpRequest(
            RequestMethod.POST,
            `${window.ROOT}checkRemoteCheckDepositAccesibility/${kCONSTANTS.APP_VERSION_DIR}/${kCONSTANTS.LANG}`,
            null,
            data,
            callBack,
            kEVENT_TRACKING.ChannelEnum.DEPOSIT_ACCOUNTS, 
            "remoteCheckDeposits",
            mocked
        ) as Promise<AxiosResponse<any>>;
    }



}

const RemoteCheckDepositsApi = new RemoteCheckDepositsAPI();

export default RemoteCheckDepositsApi;