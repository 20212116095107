// Transmit - Biometrics
const kBIOMETRICS = {
    BIOMETRICS: "Biometrics",
    SET_UP_BIOMETRICS: "Set Up Biometrics",
    BIOMETRIC_LOGIN: "Biometric Login",
    BIOMETRICS_CONTEXT: {
        LOGIN: "Biometric Login",
        ENROLL: "Biometric Enroll",
        UNENROLL: "Biometric Unenroll"
    },
    BIOMETRICS_ID: {
        FACE_ID: "Face ID",
        TOUCH_ID: "Touch ID",
        FINGERPRINT: "Fingerprint"
    },
    BIOMETRICS_POLICY: {
        BIND: "Bind",
        LOGIN: "Login"
    },
    BIOMETRICS_ACTION: {
        CANCELED: "canceled",
        NOREGISTERED: "noRegistered"
    },
    BIOMETRICS_EVENT_TYPE: {
        ENROLL: "enroll",
        UNENROLL: "unenroll"
    },
    BIOMETRICS_CHALLENGE_TYPE: {
        EMAIL: "emailChallenge",
        PHONE: "phoneChallenge"
    },
    BIOMETRICS_FOOTER: {
        ENROLL: "Enroll"
    },
    BIOMETRICS_OFFLINE: {
        LOGIN: "Biometrics temporarily offline.  Enter password to log in or try back later for biometric log in.",
        ENROLL: "Biometrics temporarily offline.  Please try setting up biometrics later.",
        UNENROLL: "Biometrics temporarily offline.  Please try turning off biometrics later."
    },
    BIOMETRICS_LOGIN: {
        LOGIN: "Log In",
        SERVER_ERROR: "Biometric login is temporarily unavailable.",
        CHANGED: "Existing Face ID authentication is no longer valid.  It is necessary to re-enroll in Face ID on the CitiBusiness Mobile app."
    },
    BIOMETRICS_ENROLL: {
        TITLE: "Log In quickly with Biometrics",
        SUCCESS_TITLE: "You’ve turned on Biometrics",
        CONTENT: "When you Sign In to your account using Face ID, you don’t need to enter your password/credentials. Anyone enrolled into biometrics at the device level will have access to your accounts.\n\nWe don’t store your Face ID information.",
        SUCCESS_CONTENT: "You’ll be able to Sign In with Biometrics next time",
        ERROR_CONTENT: "You have opted out of biometrics"
    },
    BIOMETRICS_UNENROLL: {
        SUCCESS_TITLE: "You’ve turned off Face ID",
        CONFIRM_CONTENT: "You are about to turn off Face ID®",
        SUCCESS_CONTENT: "You’ll be able to Sign In with your username and password next time."
    },
}

export default kBIOMETRICS;
