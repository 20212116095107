export class CommonContext {

    countDownTimer?: { [key: string]: NodeJS.Timeout | undefined };

    setCountDownTimer(timer: NodeJS.Timeout | undefined, timerKey: string = 'countDownTimer') {
        if (this.countDownTimer) {
            this.countDownTimer[timerKey] = timer;
        } else {
            this.countDownTimer = { [timerKey]: timer };
        }
    }

    getCountDownTimer(timerKey: string = 'countDownTimer') {
        return this.countDownTimer?.[timerKey];
    }
}

const __instance__ = new CommonContext();
export default __instance__;