import IToastUI from "./itoast.interface";

export class ToastProvider {

    private toastUI?: IToastUI;

    public setToastUI(instance: IToastUI) {

        this.toastUI = instance;
    }

    public showToastUI(msg: string, timeout?: number, autoDismiss: boolean = true) {
        this.toastUI?.showToast(msg, timeout, autoDismiss);
    }

    public showActionToastUI(msg: string, actionText: string, actionCallBack?: Function, timeout?: number, autoDismiss: boolean = true) {
        this.toastUI?.showActionToastUI(msg, actionText, actionCallBack, timeout, autoDismiss);
    }


    public showTimerPopUp(shown?: boolean, msg?: string){
        this.toastUI?.showTimerPopUp(shown, msg);
    }

    public showPushNotifContentToast(shown: boolean, pushNotifUI?: React.ReactNode) {
        this.toastUI?.showPushNotifContentToast(shown, pushNotifUI);
    }

}

const __instance__ = new ToastProvider();
export default __instance__;