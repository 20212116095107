export function defaultData() {
    const data = {
        content1: "1. What mobile devices is the CitiBusiness&reg; Mobile App compatible with?",
        content2: "The CitiBusiness&reg; Mobile App is compatible with Apple iOS and Android mobile devices running the most current operating system of iOS and Android or one version prior.",
        content3: "2. Is the CitiBusiness Mobile App available for Tablets?",
        content4: "CitiBusiness Mobile is available for Apple iPads and Android Tablets, and on most Chromebooks on which you can install apps from Google Play Store.",
        content5: "3. How do I get access to the CitiBusiness Mobile App and Mobile Token?",
        content6: "You can download the app from the Apple App Store for iOS devices and the Google Play store for Android devices. You must be entitled for one, or both, of the following:<br>1. CitiBusiness Mobile App<br>2. Mobile Token authentication type<br><br>Your System Administrator can provide you with the necessary entitlements or you can contact your Citi Representative for further assistance. Once entitled, you will receive an email with instructions on how to install the CitiBusiness Mobile App and how to register and activate your Mobile Token within the app.",
        content7: "4. Do I need to be a CitiBusiness Online user before I can access CitiBusiness Mobile App?",
        content8: "Yes. You must be enrolled and entitled on CitiBusiness Online to access the CitiBusiness Mobile App. If you are a new user, you must first log in to CitiBusiness Online to validate and change your temporary password, set security questions, and confirm your email address.",
        content9: "5. What functionality is currently available through the CitiBusiness Mobile App?",
        content10: "&#8226;Integrated Mobile Token (One-Time Password \u2014 OTP1, OTP2 and Electronic Signature \u2014 eSIG1)<br>&#8226;Information and Balance Reporting \u2014 Account Summary, Account Balances, Transaction Details<br>&#8226;Paid Check Images<br>&#8226;Transaction Initiation  \u2014 Domestic Wire, Internal Transfer, Bill Payment<br>&#8226;Approvals \u2014Transaction types and System Administrator approvals<br>&#8226;ARP/Positive Pay \u2014 Positive Pay Decisioning<br>&#8226;	Remote Check Deposit<br>&#8226;	Secure Messages",
        content11: "6. What is a Mobile Token?",
        content12: "A Mobile Token allows you to use your mobile device to generate a token in place of a Hard Token. The Mobile Token is integrated with the CitiBusiness Mobile App and will automatically generate a token for login, transaction approval and wire electronic signature.",
        content13: "7. Can I continue to use my Hard Token once I\u2019m entitled to use the Mobile Token?",
        content14: "No, you can only be entitled to use one authentication type. Once entitled to the Mobile Token authentication type, your existing Hard Token will continue to function until you complete the Mobile Token Registration process in the downloaded CitiBusiness Mobile App. Once your Mobile Token is registered within the CitiBusiness Mobile App, your Hard Token will no longer be valid.",
        content15: "8. What if I lose or replace my mobile device?",
        content16: "If you get a new mobile device you must reinstall the CitiBusiness Mobile App on your new device and go through the Mobile Token registration process to activate your Mobile Token on your new device. Also, go to Mobile Token Management on the Citibusiness Mobile App and delete the Mobile Token assigned to your old device.",
        content17: "9. I have multiple CitiBusiness Online Business Codes with multiple Hard Tokens; can I replace all of my Hard Tokens with one Mobile Token?",
        content18: "No, a Mobile Token is valid for only one CitiBusiness Business Code/User ID.",
        content19: "10. How much does it cost to use the Mobile Token?",
        content20: "There is no additional cost to use the Mobile Token or the Mobile App.<br>Standard data rates may apply when using cellular data; check with your mobile provider for more information.",
        content21: "11. I getting the following error when I try to open the CitiBusiness Mobile app \"An error was encountered while launching the application\" with the following link: https://mobile.citibusinessonline.com/downloadableError/keyboard, how can I correct this?",
        content22: "This error refers to a non-standard keyboard app that\u2019s installed on your phone. Please change your default keyboard setting to the standard keyboard on your phone or remove the non-standard keyboard app.",
    };

    return parseData(data);
}

export function parseData(data: { [key: string]: string }) {
    let faqData = [];
    // for (let i = 1; i <= 44; i = i + 2) {
    for (let i = 1; i <= 22; i = i + 2) {
        faqData.push({
            htmlHeader: data[`content${i}`],
            htmlContent: `${data[`content${i + 1}`]}`
        });
    }

    return faqData;
}