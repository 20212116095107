import React, { CSSProperties, memo, useEffect, useState } from "react";

import "./accMask.scss";
import useHover from "./useHover";
import { maskMiddleText } from "../../utils/mask.util";

export const maskAccountNumber = (accountNumber: string, hoverAsteriskLen: number = 4) => {
  if (!accountNumber) return "";
  let AsteriskObject: any = {
    2: "**",
    4: "****",
    5: "*****",
  };

  accountNumber = accountNumber.replace(/\s+/g, "");
  return accountNumber
    ? `${AsteriskObject[hoverAsteriskLen as number]}${accountNumber.slice(-4)}`
    : "";
};

const AccMask: React.FC<{
  account: any;
  styleClass?: string;
  focusable?: boolean;
  hoverAsteriskLen?: number;
  style?: CSSProperties;
  ariaHidden?: boolean;
  ariaLabel?: string;
  isMaskMiddleText?: boolean
}> = (
  { account, styleClass, focusable = true, hoverAsteriskLen = 4, style, ariaHidden, ariaLabel, isMaskMiddleText },

) => {

    const [hoverRef, isHovered] = useHover<HTMLDivElement>();
    const [isFocused, setIsFocused] = useState<boolean>(isHovered);
    const fillZore = ["00000", "0000", "000", "00", "0"];

    useEffect(() => {
      setIsFocused(isHovered);
    }, [isHovered]);

    if (hoverAsteriskLen === 2) {
      account =
        account?.length >= 6
          ? account?.slice(-6)
          : fillZore[account?.length - 1] + account;
    } else if (hoverAsteriskLen === 5 && account?.length === 9) {
      account = account?.slice(-9);
    }

    const accountNumber = isFocused ? account : isMaskMiddleText ? maskMiddleText(account) : maskAccountNumber(account, hoverAsteriskLen)
    return (
      <>
        <div
          className={`account-number ${styleClass}`}
          tabIndex={focusable ? 0 : undefined}
          style={{ display: "block", ...style }}
          ref={hoverRef}
          onFocus={() => {
            setIsFocused(true)
          }}
          onBlur={() => {
            setIsFocused(false)
          }}
          aria-label={ariaLabel ? ariaLabel : account}
          aria-hidden={ariaHidden}
        >
          <span aria-hidden="true"
            className={styleClass}
          >
            {accountNumber}
          </span>

        </div>
      </>
    );
  };

export default memo(AccMask);