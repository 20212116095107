const loadBioCatch = (url: string, callBack: Function) => {

    const bioCatchScript = document.getElementById("jsBioCatch");

    if (url === undefined || url === null || url.trim().length <= 0) {
        console.error('Invalid biocatch url. Failed to initialize biocatch');
        if (callBack) {
            callBack(false, 'fatal');
        }
        return;
    }

    if (!bioCatchScript) {
        const script = document.createElement('script');
        script.src = url;

        script.id = 'jsBioCatch';

        document.body.appendChild(script);

        script.onload = () => {
            if (window.cdApi != null) {
                window.BioCatchApi = window.cdApi;
            }
            if (callBack) callBack(true, 'success');
        }

    }

    if (bioCatchScript && callBack) callBack(true, 'alive');

}

export default loadBioCatch;