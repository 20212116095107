import { lazy } from 'react';

const TMXChallengePhoneOTP = lazy(() => import(/* webpackChunkName:'pre-signon' */'./pre-signon/tmx-challenge-phone-otp/phone-otp.component'));
const TMXChallengeEmailOTP = lazy(() => import(/* webpackChunkName:'pre-signon' */'./pre-signon/tmx-challenge-email-otp/email-otp.component'));
const EnrollmentPage = lazy(() => import(/* webpackChunkName:'pre-signon' */'./pre-signon/enrollment/enrollment.component'));

export {
    TMXChallengePhoneOTP,
    TMXChallengeEmailOTP,
    EnrollmentPage
}