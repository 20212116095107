import { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Button from '../../../ui-kit/button/button';
import LandingHeader from '../../header/header.component';
import Alert from '../../../ui-kit/alert/alert.component';
import _ from '../../../lodash';
import __constants from '../../../common/constants';
import kCONSTANTS from '../../../common/constants/GENERAL';
import __req, { ForgotPasswordResponse } from '../../../api/forgot-password';
import __regEx from '../../../utils/CitiRegEx.util';
import { APP_LOCATIONS } from "../../../routes/routes";
import CBLInput from '../../../ui-kit/cbusol-input/CBLInput';
import { DEVICE_TYPE } from '../../../device/xdevice';
import ApplicationContext from '../../../context/application-context';
import { WAIT_FOR_IOS_CALLBACK } from '../../../api/api.core';
import { focusToElementWithClassName } from '../../../utils/common.util';

function ForgotPasswordPage() {
    let history = useHistory();
    let location = useLocation();
    let context = useContext(ApplicationContext);
    let isTokenizedUser = false;

    const [businessCode, setBusinessCode] = useState(_.get(location, 'state.businessCode', ''));
    const [userId, setUserId] = useState(_.get(location, 'state.userId', ''));
    const [otp1, setOtp1] = useState('');
    const [passcode, setPasscode] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [showOtp1Input, setShowOtp1Input] = useState(false);
    const [showPassCodeInput, setShowPasscodeInput] = useState(false);
    const [showInvalidBCode, setShowInvalidBCode] = useState(false);
    const [showInvalidUId, setShowInvalidUId] = useState(false);
    const [showInvalidOtp1, setShowInvalidOtp1] = useState(false);
    const [showLoading, setShowLoading] = useState(false);

    useEffect(() => {
        //focus to header on page load
        focusToElementWithClassName('.header-content .header-text', 400);
    }, []);


    const inputInfo: Array<Object> = [
        {
            onChangeFunc: (e: Event) => { setBusinessCode(_.get(e, 'target.value', '')) },
            label: __constants.INPUT_ENTER_BUSINESS_CODE,
            val: businessCode,
            isBusinessCode: true,
            disabled: (showOtp1Input || showPassCodeInput || showLoading) ? true : false,
            showError: showInvalidBCode ? __constants.INVALID_BCODE : null
        },
        {
            onChangeFunc: (e: Event) => { setUserId(_.get(e, 'target.value', '')) },
            label: __constants.INPUT_ENTER_USER_ID,
            val: userId,
            disabled: (showOtp1Input || showPassCodeInput || showLoading) ? true : false,
            showError: showInvalidUId ? __constants.INVALID_USER_ID : null
        },
        {
            onChangeFunc: (e: Event) => { setOtp1(_.get(e, 'target.value', '')) },
            label: __constants.INPUT_ENTER_OTP1,
            val: otp1,
            type: 'password',
            disabled: showLoading ? true : false,
            showError: showInvalidOtp1 ? __constants.INVALID_OTP1 : null,
            maxLength: "8",
            onlyNumbers: false,
            onFocus: () => { setOtp1('') }
        },
        {
            onChangeFunc: (e: Event) => { setPasscode(_.get(e, 'target.value', '')) },
            label: __constants.INPUT_ENTER_PASSCODE,
            val: passcode,
            type: 'password',
            disabled: showLoading ? true : false
        }
    ];

    function _invalidBCodeOrUserId(): Boolean {
        let invalidBCode = false;
        if (!__regEx.businessCode(businessCode)) {
            invalidBCode = true;
        }
        setShowInvalidBCode(invalidBCode);

        let invalidUserId = false;
        if (!__regEx.userCode(userId)) {
            invalidUserId = true;
        }
        setShowInvalidUId(invalidUserId);

        return (invalidBCode || invalidUserId);
    }


    function _disabledBtn() {

        if (!showOtp1Input && !showPassCodeInput && (businessCode && userId).length > 0) {
            return false;
        }

        if (showOtp1Input && (businessCode && userId && otp1).length > 0) {
            return false;
        }

        if (showPassCodeInput && (businessCode && userId && passcode).length > 0) {
            return false;
        }

        return true;
        //test commit
    }

    function _handleFailedRequest(error: Array<{ [key: string]: any }>) {
        const message = _.get(error, '[0].value', __constants.ERROR_INCORRECT_INFORMATION);
        setErrorMessage(message);
        setShowLoading(false);
    }

    function _handleDetermineIfTokenizedUser1() {
        console.log("_handleDetermineIfTokenizedUser1");
        if (window.isAkamaiMobile && window.THE_DEVICE_TYPE !== DEVICE_TYPE.WEB) {
            const args = [{ "foo": "bar" }];
            context?.DEVICE.getAkamai((response: any) => {
                console.log('_handleDetermineIfTokenizedUser1 GET AKAMAI DATA Successful ........... ');
                const akamaiData = _.get(response, 'akamaiData', "");
                handleDetermineIfTokenizedUser3(akamaiData);
            }, (fail: any) => {
                console.log("AKAMAI DATA RETRIVAL FAILED");
            }, args);
        } else {
            handleDetermineIfTokenizedUser3();
        }
    }

    function handleDetermineIfTokenizedUser3(akamaiData?: string) {
        console.log("_handleDetermineIfTokenizedUser3");
        setShowLoading(true);

        __req
            .forgotPasswordDetermineIfTokenizedUser(businessCode, userId, "", _successForgotPasswordDetermineIfTokenizedUser, akamaiData)
            .then((response) => {
                //console.log('====>', response)
                if (response.status !== WAIT_FOR_IOS_CALLBACK) {
                    console.log("forgotPasswordDetermineIfTokenizedUser", "Success");
                    _successForgotPasswordDetermineIfTokenizedUser(response.data);
                } else
                    console.log('waiting for ios call', response);
            })
            .catch((error) => {
                console.log("forgotPasswordDetermineIfTokenizedUser", "failure");
                _handleFailedRequest(error);
            });
    }

    function _successForgotPasswordDetermineIfTokenizedUser(response: ForgotPasswordResponse) {
        console.log("_successForgotPasswordDetermineIfTokenizedUser");
        if (_.get(response, 'success', false)) {
            if (_.get(response, 'tokenizedUser', false)) {
                //TODO insert auto token code here
                isTokenizedUser = true;
                console.log('LOGIN OTP1 part 1: ........... ');

                if (window.THE_DEVICE_TYPE !== DEVICE_TYPE.WEB) {
                    console.log("IS USER REGISTERED::::: " + window.DEVICE_INFO.registered);
                }
                // if (window.DEVICE_INFO.registered != true)
                setShowOtp1Input(true);
            } else {
                setShowPasscodeInput(true);
            }
        } else {
            _handleFailedRequest(_.get(response, 'errors', []));
        }
        setShowLoading(false);
    }

    function _handleSubmit1(tokenPassword: string) {
        console.log("_handleSubmit1");
        if (window.isAkamaiMobile && window.THE_DEVICE_TYPE !== DEVICE_TYPE.WEB) {
            console.log("_handleSubmit1: ANDROID");
            const args = [{ "foo": "bar" }];
            context?.DEVICE.getAkamai((response: any) => {
                console.log('handleChangePinClick1 GET AKAMAI DATA Successful ........... ');
                const akamaiData = _.get(response, 'akamaiData', "");
                _handleSubmit2(tokenPassword, akamaiData);
            }, (fail: any) => {
                console.log("AKAMAI DATA RETRIVAL FAILED");
            }, args);
        } else {
            console.log("_handleSubmit1: not ANDROID");
            _handleSubmit2(tokenPassword);
        }
    }

    function _handleSubmit2(tokenPassword: string, akamaiData?: string) {
        setShowLoading(true);

        __req
            .forgotPasswordSubmit(businessCode, userId, tokenPassword, isTokenizedUser, _successForgotPasswordSubmit, akamaiData)
            .then((response) => {
                //console.log('====>', response)
                if (response.status !== WAIT_FOR_IOS_CALLBACK)
                    _successForgotPasswordSubmit(response.data)
                else
                    console.log('waiting for ios call', response)
            })
            .catch((error) => {
                _handleFailedRequest(error);
            });
    }

    function _successForgotPasswordSubmit(response: ForgotPasswordResponse) {
        if (_.get(response, 'success', false)) {
            history.push({
                pathname: APP_LOCATIONS.SendEmailPage,
                state: {
                    citiKey: _.get(response, 'citiKey', ''),
                    key: _.get(response, 'emailList[0].key', ''),
                    emailId: _.get(response, 'emailList[0].emailId', ''),
                }
            });
        } else {
            _handleFailedRequest(_.get(response, 'errors', []));
        }
        setShowLoading(false);
    }

    function _onContinueBtnClick() {
        if (errorMessage) {
            setErrorMessage('');
        }

        if (!showOtp1Input && !showPassCodeInput) {
            if (_invalidBCodeOrUserId()) {
                return;
            }

            _handleDetermineIfTokenizedUser1();
            return;
        }

        if (showOtp1Input && otp1.length > 0) {
            /*if (_invalidOtp1()) {
                return;
            }*/

            _handleSubmit1(otp1);
            return;
        }

        if (showPassCodeInput && passcode.length > 0) {
            _handleSubmit1(passcode);
        }
    }

    function _onCancelBtnClick() {
        const pathname = APP_LOCATIONS.SignOnPage;
        history.push({
            pathname
        });
    }

    function _renderInputView(data: { [key: string]: any }) {
        const { label } = data;

        return (
            <>
                <CBLInput
                    key={label}
                    type={data.type ? (data.type) : 'text'}
                    label={label}
                    value={data.val}
                    disabled={data.disabled}
                    onChange={data.onChangeFunc}
                    onFocus={data.onFocus ? data.Focus : null}
                    numericInput={data.onlyNumbers ? data.onlyNumbers : false}
                    required
                    isBusinessCode={data.isBusinessCode}
                    maxLength={data.maxLength ? data.maxLength : null}
                    mask
                />
                {
                    data.showError
                        ? <p className='error-message'>{data.showError}</p>
                        : null
                }
            </>
        );
    }

    function _renderContentView() {
        return (
            <div className='react-container top-40 auto-overflow'>
                {
                    errorMessage.length > 0
                        ? <Alert type='error' htmlContent={errorMessage} style={{ marginBottom: '1rem' }} />
                        : null
                }

                {/* <p tabIndex={0} aria-label={`Heading 2, ${__constants.FORGOT_PASSWORD}`} className='title' style={{ fontSize: '1.1rem' }}>
                    <span aria-hidden="true">{__constants.FORGOT_PASSWORD}</span>
                </p> */}

                {_.map(inputInfo, (val: { [key: string]: any }) => {
                    if (val.label === __constants.INPUT_ENTER_PASSCODE) {
                        if (showPassCodeInput) {
                            val.label = __constants.OTP1_OR_ENTER_PASSCODE;
                            return (
                                <>
                                    <p className='text-medium'>{__constants.GENERATE_ENTER_PASSCODE}</p>
                                    {/* <p className='text-medium'>{__constants.SEND_EMAIL_PASSCODE}</p>
                                    <p className='email-text'>{_.get(noTokenUserInfo, 'emailId', '')}</p> */}
                                    {_renderInputView(val)}
                                </>)
                        } else {
                            return null;
                        }
                    }

                    if (val.label === __constants.INPUT_ENTER_OTP1 && !showOtp1Input) {
                        return null;
                    }

                    if (val.label === __constants.INPUT_ENTER_OTP1 && showOtp1Input) {
                        val.label = __constants.OTP1_OR_ENTER_PASSCODE;
                        return (
                            <>
                                <p className='text-medium'>{__constants.GENERATE_ENTER_PASSCODE}</p>
                                {_renderInputView(val)}
                            </>)
                    }

                    return _renderInputView(val);
                })}
                <div>
                    <Button
                        color="primary"
                        disabled={_disabledBtn()}
                        className='buttonWithMargin'
                        showLoading={showLoading}
                        onClick={_onContinueBtnClick}>
                        {kCONSTANTS.CONTINUE}
                    </Button>

                    <Button
                        color="outline"
                        className='buttonWithMargin'
                        style={{ border: 'none', boxShadow: 'none' }}
                        onClick={_onCancelBtnClick}>
                        {kCONSTANTS.CANCEL}
                    </Button>

                </div>
            </div>
        );
    }

    return (
        <>
            <LandingHeader title={__constants.FORGOT_PASSWORD} />
            {_renderContentView()}
        </>
    );
}

export default ForgotPasswordPage;