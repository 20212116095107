const GlassboxScriptLoader = (env: string, callBack: Function) => {

    let domainUrl = "";
    let report_URI = '';

    //for any change in Report_URI, please change in WebAPI_CBMobile/src/main/webapp/js/17_0/glassboxJavascriptDetector/config.js file as well
    if (env === 'uat') {
        domainUrl = `https://mobileuat.citibusinessonline.com/`;
        report_URI = 'https://report.citi-tts-uat.gbqofs.io/rytg6wtz/thickclient/report/d4e4da2f-e0fc-4de0-9dd1-844b726e1f4c/*'
    } else if (env === 'qstg') {
        domainUrl = `https://mobilestg.citibusinessonline.com/`
        report_URI = 'https://report.citi-tts-uat.gbqofs.io/rytg6wtz/thickclient/report/d4e4da2f-e0fc-4de0-9dd1-844b726e1f4c/*'
    } else {
        // prod
        domainUrl = `https://mobile.citibusinessonline.com/`
        report_URI= 'https://report.citi-tts-prod.gbqofs.io/4vql1yw8/thickclient/report/db3460be-cd73-4ff5-bf0b-21415244bb78/*'
    }

    let url = domainUrl + 'js/17_0/glassboxJavascriptDetector/detector-dom.min.js';
    
    const cScript = document.getElementById("_cls_detector");

    if (url === undefined || url === null || url.trim().length <= 0) {
        console.error('Invalid cookie-consent url. Failed to initialize cookie-consent');
        if (callBack) {
            callBack(false, 'fatal');
        }
        return;
    }

    if (!cScript) {
        const script = document.createElement('script');
        script.src = url;


        script.id = '_cls_detector';
        script.setAttribute('data-clsconfig', `reportURI=${report_URI}`);
        script.setAttribute("charset", "UTF-8");

        document.body.appendChild(script);

        script.onload = () => {
            console.log('Glassbox Script initialized.');
            if (callBack) callBack(true, 'success');
        }
    }

    if (cScript && callBack) callBack(true, 'alive');

}

export default GlassboxScriptLoader;
