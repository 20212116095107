import _ from 'lodash';
import http from './api.core';
import { RequestMethod } from './api.core';
import kEVENT_TRACKING from "../common/constants/EVENT_TRACKING";
import kCONSTANTS from "../common/constants/GENERAL";
import { AxiosResponse } from 'axios';
import { DEVICE_TYPE } from '../device/xdevice';
import { getUserAgent1 } from '../utils/api.util';

export interface ForgotPasswordResponse {
    success: boolean, //true
    citiKey: string, //"688f8eb0-4d87-49d1-82e8-2d93e3f0a269",
    emailList: Array<Object> //[{key: "40272cfb-6d9f-47a6-8683-792e92680df1", emailId: "y*****1@imcap.ap.ssmb.com"}]
}

export interface ChangePinResponse {
    success: string;
    citiKey: string;
    message: string;
}

export interface ValidatePinResponse {
    success: string;
    genericException: string;
    message: string;
    errors: Array<any>;
}

export class ForgotPasswordApi {

    forgotPasswordDetermineIfTokenizedUser(businessCode: string, userId: string, optionalOtp2?: string, callBack?: Function, akamaiHeader?: string): Promise<AxiosResponse<ForgotPasswordResponse>> {
        const data = {
            businessCode,
            optionalOtp2,
            username: userId
        };

        let headers: any = {
            "Accept": "application/json",
            "Content-Type": "application/json"
        };

        if (window.isAkamaiMobile && window.THE_DEVICE_TYPE !== DEVICE_TYPE.WEB && _.isString(akamaiHeader) && akamaiHeader.length > 0) {
            headers["x-acf-sensor-data"] = akamaiHeader;
            headers["user-agent1"] = getUserAgent1();
        }

        return http.makeHttpRequest(
            RequestMethod.POST,
            `${kCONSTANTS.DEMO}${kCONSTANTS.REACT_ABC}forgotPasswordDetermineIfTokenizedUserPage/${kCONSTANTS.APP_VERSION_DIR}/${kCONSTANTS.LANG}`,
            headers,
            data,
            callBack,
            kEVENT_TRACKING.ChannelEnum.LOGIN) as Promise<AxiosResponse<ForgotPasswordResponse>>;
    }

    forgotPasswordSubmit(businessCode: string, userId: string, otp1: string, isTokenizedUser: boolean, callBack?: Function, akamaiHeader?: string): Promise<AxiosResponse<ForgotPasswordResponse>> {
        const data = {
            businessCode,
            username: userId,
            tokenPassword: otp1,
        };

        let headers: any = {
            "Accept": "application/json",
            "Content-Type": "application/json"
        };

        if (window.isAkamaiMobile && window.THE_DEVICE_TYPE !== DEVICE_TYPE.WEB && _.isString(akamaiHeader) && akamaiHeader.length > 0) {
            headers["x-acf-sensor-data"] = akamaiHeader;
            headers["user-agent1"] = getUserAgent1();
        }

        return http.makeHttpRequest(
            RequestMethod.POST,
            `${kCONSTANTS.DEMO}${kCONSTANTS.REACT_ABC}forgotPasswordSubmitPage/${kCONSTANTS.APP_VERSION_DIR}/${kCONSTANTS.LANG}`,
            headers,
            data,
            callBack,
            kEVENT_TRACKING.ChannelEnum.LOGIN) as Promise<AxiosResponse<ForgotPasswordResponse>>;
    }

    sendForgotPasswordEmail(citiKey: string, key: string, callBack?: Function, akamaiHeader?: string): Promise<AxiosResponse<ForgotPasswordResponse>> {
        const data = {
            citiKey,
            key
        };

        let headers: any = {
            "Accept": "application/json",
            "Content-Type": "application/json"
        };
        if (window.isAkamaiMobile && window.THE_DEVICE_TYPE !== DEVICE_TYPE.WEB && _.isString(akamaiHeader) && akamaiHeader.length > 0) {
            headers["x-acf-sensor-data"] = akamaiHeader;
            headers["user-agent1"] = getUserAgent1();
        }

        return http.makeHttpRequest(
            RequestMethod.POST,
            `${kCONSTANTS.DEMO}${kCONSTANTS.REACT_ABC}sendForgotPasswordEmailAlert/${kCONSTANTS.APP_VERSION_DIR}/${kCONSTANTS.LANG}`,
            headers,
            data,
            callBack,
            kEVENT_TRACKING.ChannelEnum.LOGIN) as Promise<AxiosResponse<ForgotPasswordResponse>>;
    }

    //TODO changee the hardcoded value
    submitChangePin(body: any, callBack?: Function): Promise<AxiosResponse<ChangePinResponse>> {
        return http.makeHttpRequest(
            RequestMethod.POST, `${kCONSTANTS.DEMO}${kCONSTANTS.REACT_ABC}pinConfirmation/${kCONSTANTS.APP_VERSION_DIR}/${kCONSTANTS.LANG}`,
            null,//headers,
            body,
            callBack,
            kEVENT_TRACKING.ChannelEnum.MOBILE_TOKEN
        );
    }

    changePasswordPage(callBack?: Function) {

        return http.makeHttpRequest(
            RequestMethod.GET,
            `${window.ROOT}changePasswordPage/${kCONSTANTS.APP_VERSION_DIR}/${kCONSTANTS.LANG}`,
            null,
            null,
            callBack,
            kEVENT_TRACKING.ChannelEnum.EMAIL_OTP,
            'changePasswordPage') as Promise<AxiosResponse<any>>;
    }

}

const __instance__ = new ForgotPasswordApi();

export default __instance__;
