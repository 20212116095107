import "./fdic-logo.style.scss";
import FDIC from "../../assets/images/FDIC.png";

const FDICLogoComponent = () => {
    
    const createUI = () => {
        return (
            <>
                <div className="FDIC-container">
                    <div
                        className="lmn-d-flex lmn-align-items-center"
                        style={{ gap: "8px", marginBottom: "4px" }}
                    >
                        <img alt="FDIC logo" src={FDIC} style={{ width: "70px" }} />
                        <div
                            className="lmn-font-italic lmn-font-weight-normal fdic-text"
                        >
                            FDIC-Insured - Backed by the full faith
                            <br /> and credit of the U.S. Government
                        </div>
                    </div>
                    <div>
                        <div
                            className="lmn-font-weight-normal fdic-text citi-line-height"
                        >
                            Citibank, N.A.
                        </div>
                    </div>
                </div>
            </>
        );
    };

    return createUI();
};
export default FDICLogoComponent;
