import LandingHeader from '../header/header.component';
import { DEVICE_TYPE } from '../../device/xdevice';
import kLEFT_MENU from '../../common/constants/LEFT_MENU';
import __data from './terms-of-use.data';
import _ from 'lodash';
import './terms-of-use.style.scss';
import { compareVersion, focusToElementWithClassName, handleBackOnLeftMenus } from '../../utils/common.util';
import { useEffect } from 'react';

function TermsOfUsePage() {

    useEffect(() => {
        //focus to header on page load
        focusToElementWithClassName('.header-content .header-text', 400);
    }, [])

    function _handleBackButtonClick() {
        handleBackOnLeftMenus();
    }

    function _renderContentView(data: Array<{ [key: string]: string | undefined }>) {
        return (
            <>
                {_.map(data, (val) => {
                    let header = val.header;
                    if (compareVersion('17') && val.newHeader) {
                        header = val.newHeader;
                    }

                    return (
                        <>
                            {header ? <span className='text-medium terms-header'>{header}</span> : null}
                            {val.content ? <span className='text-medium terms-content'>{val.content}</span> : null}
                        </>
                    );
                })}
            </>
        );
    }

    return (
        <>
            <LandingHeader title={kLEFT_MENU.TERM_OF_USE} showBackBtn onBackClick={_handleBackButtonClick} />
            <div className='react-container top-40 auto-overflow' tabIndex={0}>
                {
                    window.THE_DEVICE_TYPE === DEVICE_TYPE.IOS
                        ? _renderContentView(__data.ios)
                        : _renderContentView(__data.web)
                }
            </div>
        </>
    );
}

export default TermsOfUsePage;