import _ from 'lodash';
import deviceCtx from '../../device/xdevice';
import securityCtx from '../../context/security-context';
import { WAIT_FOR_IOS_CALLBACK } from '../../api/api.core';
import __req from '../../api/push-notif.api';

let deviceInfo: { [key: string]: any } = {};
let registerCallback: Function | undefined = undefined;

/**
 * enroll/unenroll notification on server
 */

const _handleCallback = (register: boolean, success: boolean) => {
    registerCallback && registerCallback(register, success, deviceInfo);
}

const _handleUnRegisterResp = (resp: { [key: string]: any }) => {
    console.log('Push Notification - unregisterPushNotif success: ', _.get(resp, 'success', false));

    const success = _.get(resp, 'success', false);
    if (success) {
        deviceCtx.setPushNotifEnroll({
            enrolled: false,
            enrolledToken: ''
        });
    }
    _handleCallback(false, success);
}

const _handleRegisterResp = (resp: { [key: string]: any }) => {
    console.log('Push Notification - registerPushNotif success: ', _.get(resp, 'success', false));

    const success = _.get(resp, 'success', false);
    if (success) {
        deviceCtx.setPushNotifEnroll({
            enrolled: true,
            enrolledToken: _.get(deviceInfo, 'deviceToken', ''),
        });
    }
    _handleCallback(true, success);
}

const _sendUnRegisterNotifReq = () => {
    __req.unregisterPushNotif(deviceInfo, _handleUnRegisterResp)
        .then((resp) => {
            if (resp.status !== WAIT_FOR_IOS_CALLBACK) {
                _handleUnRegisterResp(resp.data);
            } else {
                console.log('waiting for ios call', resp);
            }
        })
        .catch((error) => {
            _handleUnRegisterResp(error);
        });
}

const _sendRegisterNotifReq = () => {
    __req.registerPushNotif(deviceInfo, _handleRegisterResp)
        .then((resp) => {
            if (resp.status !== WAIT_FOR_IOS_CALLBACK) {
                _handleRegisterResp(resp.data);
            } else {
                console.log('waiting for ios call', resp);
            }
        })
        .catch((error) => {
            _handleRegisterResp(error);
        });
}

export const getP86IdIfNeeded = (onDone: Function) => {
    const userP86Id = securityCtx?.getUserP86Id();
    if (!userP86Id || userP86Id.length === 0 || window.DEVICE_INFO.autoGenerateSoftToken === undefined) {
        deviceCtx.getUserP86Id((s: any) => {
            console.log('Push Notifications - getUserP86Id succeed: ', s.success);

            window.DEVICE_INFO.autoGenerateSoftToken = s.success;
            securityCtx.setUserP86Id(s.userP86Id);

            onDone();
        }, (error: object) => {
            console.log('Push Notifications - getUserP86Id failed ', error);

            window.DEVICE_INFO.autoGenerateSoftToken = false;
            onDone()
        }, []);

        return;
    }

    onDone();
}

export const getNotifDeviceInfo = (callback: Function) => {
    deviceInfo = {};

    deviceCtx.getPushNotifDeviceInfo((info: object) => {
        if (!info) {
            callback(false);
            return;
        }

        console.log('Push Notifications - getPushNotifDeviceInfo succeed ');
        deviceInfo = info;

        getP86IdIfNeeded(() => {
            deviceCtx.getTokenSequenceAndSerialNumber((resp: any) => {
                const serialNumber = _.get(resp, 'serialNumber', '');
                const sequenceNumber = _.get(resp, 'sequenceValue', '');

                deviceInfo = { ...deviceInfo, ...{ serialNumber, sequenceNumber } };

                callback(true, deviceInfo);
            }, (error: object) => {
                console.log('Push Notifications - getTokenSequenceAndSerialNumber failed ', error);
                callback(false);
            }, {});
        });
    }, (error: object) => {
        console.log('Push Notifications - getPushNotifDeviceInfo failed ', error);
        callback(false);
    });
}

export const registerNotifIfNeeded = (register: boolean, registerFun?: Function) => {
    registerCallback = registerFun;

    getNotifDeviceInfo((success: boolean) => {
        if (success) {
            if (register) {
                _sendRegisterNotifReq();
            } else {
                _sendUnRegisterNotifReq();
            }
        } else {
            _handleCallback(register, false);
        }
    });
}

