import JSEncrypt from 'jsencrypt';

const ENCRYPT = function (message: string) {

    const publicKey = `MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEA3XKTH6DLBRaq8RrD9aGI\nZqM6T37VduXpAEl92oaFbY1J4GjxRsHp2P+ULDBDemiy8nL9Jf0HCN8NKnfYSzjM\nt7rC9o1MauE0c7c7DfYOp/OfzRbvpHwmDrbAejnK4LsFVmbPMBL4SJs80gmW50JX\nle6YjSYtbQLUFrAZDm+D087wrnmYRv+NmAVHw0lhE8hS7R01IoR8zq42nI/Jv30v\n1Ogw+jSDqHhobi5SPDNLG9xDLY2G7nypnj6c2icoN2GtFFqJwmLEYXWkrDesufeG\ngSKO0pIoZXIr5ckIP79xU3U9m4ch0FbyusMD/NNWK2864WDI4qfNmcbP3coTQozP\nmtHv1IheJg5jTiMepa1oFDKSl7MOi+dVToWt8UobbSr0V+K8WgIVGPyVaWPYkF55\nEzwIWW4q+vKWCoiN2SVAJcKUCKjbOXfJ2HsvXCHI81yGX4jkWl7bposPCpay6WOW\nHEJGPA1WTFTNj7N9QjAv4bEZiUvURyXZuN2fxJp2oNtYnmP4mOXCX4Ki63VfSH8U\nGrizZ3A1ffEjdwoBNRyZE2+NDbzr6OeG/vqrvSBfbMhVM3CDNYXXpPTjoJ0ynAV8\nzr30ZnIXE45uNq5V4PT3gDABzJbPj5iaq7FAu0PLJTWjvXXhIDoX+Pt3f9vqw1B0\nwknagutgbhhVEPY2ilBdE3cCAwEAAQ==`;
    const rsaEncypt = new JSEncrypt();
    rsaEncypt.setPublicKey(publicKey);
    const encr = rsaEncypt.encrypt(message);
    //console.log('Encrypt Test',encr);
    return encr;
};

export const ENCRYPT_INSTANT_PAY_APPROVAL = function (message: string) {

    console.log('The environment is ----> ', window.GLOBAL_ENVIRONMENT);
    const UAT_PUBLIC_KEY = `MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAuobDOR5+y1f72BEPGGsyX4emfr7xy4SPl/bH2APca4zA81Zxa51NSXmYnW2mQ2bKf7nDs8lKy2aXWSQjlNy9MzRIT5hggmIsiO/0eDkvh2lVFzYt1WnTOakIjaY6qoXq+yJaHwtNaJD9xWzwBJrzY796+UxefN3sn1W9Df669HM3VLEPFhgxS0sMTepYcs7I56fv/66bPMNDpVeHtU2W1imZ/RYr1THO020dS+CyMci8ae0wzS/G6wX2ejfoovE7D+fEX6Vbu657m/DcD6weBgtrKbcvtZLo4mmtTGAO+setHPcMol4BgpMCjfsRP8a3KrCbaQxbsn3C8f9Hu8BZlxOEMisp2torE0WZg8hWWj98i0Y3TqAdehgy4zboXQ9CfKYJh/f6QMKNoM7Nv5e4fGb0Vw+DffkNKOSUjYkWBYwIPArjWHdB6TA57aqp+rfyIoeaUhT2IAqUYbPcRHKz3T29Hy8WHIRWYu8XjnLqXVykCTon3VfkSDddVqYfA9Usep0nsGBoPjNZYcYCFBQ8PnA1iAOBaUMcgwwRQCg0wdZ4orDkEmImO+ns0Tum2WJkEluc4GPE/iLwZKig42dHyV9URNf+ssrMdhv0GNWlFDv+PUeJzRRPD8jKjF2n93Ia4BBtqynfPPMzo92JuPU84PuMMQwzel2/MNTbQbS2yzcCAwEAAQ==`;
    const PROD_PUBLIC_KEY = `MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEA3AUyOkZL7uGPtULee5sZTXm09LUXEWTZhw6LOkXQTMTtwe0qYjQeuO30XJcUcoV7cPWoolc8FoPkTeqWGWV6ks7cQbqffmxTyyrBu0Ttf/SynOj9szlO58ulWo2CN4DaQqp0evFTW3SoapRRlzy0ZSH5B0ohj8X/g1fyF4tuV7wHLxlvLisBkASSN7twxM0RK0uCVROJBdCOzZyKLevNri+Ze7VsI6yyiCFDgLkXOwL2Sd/kjPsHiePoWMpozhLQ56MFppcur4ebww6zLAyujKdCjBULDH2vZN04sRwtzQ69mNMaFK7j59k07wy9Dkmj5i5i+uUEMFobxxYEnmPS91jI7AJiVDX896TylUc8XnaO6feh5cFKHN+urKxqtyJ1cwX6cPn7BfsJqdilrRAMyR7/Dq69NcM8nRmgqM3j+vDQkDEL1++Z9ujpJWwHKMzLhPlukxM+gHs83Lw4DM04FpJqd9eJ2Omvvy9q+ewD+Q3zedhS6wSM3O5xQ/eNE4/JwkqXPDTPomNxcLKy5hR1Z7F76okSXjmOcQyVUzzRaENzfCRGotzGMIgaSCSLiMVZtSwHf7GA+CPfY6C/UlVhWQwDxc/uuneXXEE9bYFsDpSxckVYJU+XGMuZbYhZxNwRhDoWmUYTHLY0f96Hg6Jx0SLVH7MIJ583T9R/W0rNpesCAwEAAQ==`;
    let publicKey;
    if (window.GLOBAL_ENVIRONMENT === 'prod') {
        publicKey = PROD_PUBLIC_KEY;
    } else {
        publicKey = UAT_PUBLIC_KEY;
    }
    const rsaEncypt = new JSEncrypt();
    rsaEncypt.setPublicKey(publicKey);
    const encr = rsaEncypt.encrypt(message);
    //console.log('Encrypt Test inst pay approval--->', encr);
    return encr;
};

export default ENCRYPT;