import { lazy } from 'react';

const ChangePin = lazy(() => import(/* webpackChunkName:'mfa' */'./mfa/change-pin/change-pin.component'));
const VerifyTokenRegistration = lazy(() => import(/* webpackChunkName:'mfa' */'./mfa/verify-token/verify-token-registration.component'));
const PushNotifLanding = lazy(() => import(/* webpackChunkName:'mfa' */'./push-notifications/landing'));

export {
    ChangePin,
    VerifyTokenRegistration,
    PushNotifLanding
}