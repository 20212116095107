import React, { useContext } from 'react';
import ApplicationContext from '../../context/application-context';
import ContextData from '../../context/context-data.interface';
import { APP_LOCATIONS } from '../../routes/routes';
import { logOut } from "../../utils/common.util";
import LandingHeader from '../header/header.component';

export  class XPage404 extends React.Component {

    context: ContextData | undefined;

    onBackClick = () => {
        const hash: string = window.location.hash;
        if (this.context?.Security.isHomePage() && !(hash.includes(APP_LOCATIONS.SignInHome))) {
            this.context?.Router.navigate(APP_LOCATIONS.SignInHome);
        } else {
            logOut();
        }
    }

    render() {
        return (
            <>
                <LandingHeader title={'Page Not Found'} showBackBtn onBackClick={this.onBackClick} />

                <div className="react-container top-40 auto-overflow" tabIndex={0} style={{ paddingTop: '15%', color: '#840606' }}>
                    <p className="page-not-found-icon" style={{ fontSize: '5rem', textAlign: 'center' }}>
                        <i className="lmnicon lmnicon-unlink-alt"></i>
                    </p>
                    <p className="not-found-text" style={{ fontSize: '1rem', textAlign: 'center' }}>
                        Page not found
                    </p>

                </div>
            </>
        )
    }
}


const Page404 = ()=>{

    const context = useContext(ApplicationContext);

    const onBackClick = () => {
        const hash: string = window.location.hash;
        if (context?.Security.isHomePage() && !(hash.includes(APP_LOCATIONS.SignInHome))) {
            context?.Router.navigate(APP_LOCATIONS.SignInHome);
        } else {
            logOut();
        }
    }

    return (
        <>
            <LandingHeader title={'Page Not Found'} showBackBtn onBackClick={onBackClick} />

            <div className="react-container top-40 auto-overflow" tabIndex={0} style={{ paddingTop: '15%', color: '#840606' }}>
                <p className="page-not-found-icon" style={{ fontSize: '5rem', textAlign: 'center' }}>
                    <i className="lmnicon lmnicon-unlink-alt"></i>
                </p>
                <p className="not-found-text" style={{ fontSize: '1rem', textAlign: 'center' }}>
                    Page not found
                </p>

            </div>
        </>
    )

}

export default Page404;

Page404.contextType = ApplicationContext