
export class LoanContext {

    CDCB_LOAN_TOGGLE: boolean = false;
    loanDashboardResp?: any;
    businessInfo: { gfcId: string; companyName: string; } | undefined | null;
    loanTypeData?: any;
    LoCTypeData?: any;
    loanDetails?: any;
    loanType?: any;

    setCDCB_LOAN_TOGGLE(CDCB_LOAN_TOGGLE: boolean){
        this.CDCB_LOAN_TOGGLE = CDCB_LOAN_TOGGLE;
    }
     
    getCDCB_LOAN_TOGGLE(): boolean{
        return this.CDCB_LOAN_TOGGLE;
    }

    setLoanDashboardResp(loanDashboardResp: any) {
        this.loanDashboardResp = loanDashboardResp;
    }

    getLoanDashboardResp(): any {
        return this.loanDashboardResp;
    }

    setBusinessInfo(businessInfo: { gfcId: string; companyName: string }) {
        this.businessInfo = businessInfo;
    }

    getBusinessInfo(): { gfcId: string; companyName: string } | undefined | null {
        return this.businessInfo;
    }

    setLoanTypeData(loanTypeData: any) {
        this.loanTypeData = loanTypeData;
    }

    getLoanTypeData(): any {
        return this.loanTypeData;
    }

    setLoCSubTypeData(LoCTypeData: any) {
        this.LoCTypeData = LoCTypeData;
    }

    getLoCSubTypeData(): any {
        return this.LoCTypeData;
    }

    setLoanDetails(loanDetails: any) {
        this.loanDetails = loanDetails;
    }

    getLoanDetails(): any {
        return this.loanDetails;
    }

    setLoanType(loanType: any) {
        this.loanType = loanType;
    }

    getLoanType(): any {
        return this.loanType;
    }

    clearContext(){
        this.loanDashboardResp = null;
        this.businessInfo = null;
        this.loanTypeData = null;
        this.LoCTypeData = null;
        this.loanDetails = null;
        this.loanType = null;
    }
    
    resetToggle() {
        this.CDCB_LOAN_TOGGLE = false;
    }
}

const __instance__ = new LoanContext();
export default __instance__;