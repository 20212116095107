import React, { ErrorInfo } from 'react';
import ApplicationContext from '../../context/application-context';
import ContextData from '../../context/context-data.interface';

interface Props {
    fallback: React.ReactNode;
    children: React.ReactNode;
}

interface State {
    hasError: boolean;
}

export default class ErrorBoundary extends React.Component<Props, State> {
    context: ContextData | undefined;
    from: string;

    constructor(props: Props) {
        super(props);
        this.from = '';
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error: Error, info: ErrorInfo) {
        // Example "componentStack":
        //   in ComponentThatThrows (created by App)
        //   in ErrorBoundary (created by App)
        //   in div (created by App)
        //   in App
        console.warn('componentDidCatch - error - ', error);
        console.warn('componentDidCatch - info - ', info.componentStack);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return this.props.fallback;
        }

        return this.props.children;
    }
}

ErrorBoundary.contextType = ApplicationContext;