import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import './checkbox.style.scss';

interface Props {
    label?: string,
    checked?: boolean,
    name?: string,
    onCheckedChange?: any
}


export const FCheckBox= (props: Props) => {

    const [checked,setChecked] = useState(props.checked);


    useEffect(()=>{
        setChecked(props.checked)
    },[props.checked])

    const handleOnCheckChanged = (e: any) => {
        const checked = e.target.checked;
        setChecked(checked)
        if (_.isFunction(props.onCheckedChange)) {
            props.onCheckedChange(checked);
        }

    }

    return (
        <div className="input-checkbox-group">
            <input tabIndex={0} type="checkbox" className="input-checkbox" name="" id={props.label} checked={checked} onChange={handleOnCheckChanged} />
            <label className="input-checkbox-label" htmlFor={props.label} >{props.label}</label>
        </div>
    );


}