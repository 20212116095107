import { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import Button from "../../../ui-kit/button/button";
import { APP_LOCATIONS } from "../../../routes/routes";
import Loading from "../../../ui-kit/loading-modal/loadingComponent.component";
import __req from '../../../api/system-administration.api';
import { WAIT_FOR_IOS_CALLBACK } from '../../../api/api.core';
import Alert from '../../..//ui-kit/alert/alert.component';
import { MandatorySignSpan } from '../../common-ui/common-ui.components';
import moment from 'moment';
import '../styles.scss';
import LandingHeader from "../../header/header.component";
import ApplicationContext from '../../../context/application-context';
import DatePicker from "../../../ui-kit/datepicker/datepicker.component";
import Dropdown from "../../../ui-kit/dropdown/dropdown.component";
import kSYSTEM_ADMINISTRATION from "../../../common/constants/SYSTEM_ADMINISTRATION";
import kCONSTANTS from "../../../common/constants/GENERAL";
import { focusToElementWithClassName } from "../../../utils/common.util";
import RadioGroupView from "../../../ui-kit/radio-group/radio-group.component";
import { extendSession } from "../../../utils/timer.util";

function SuspendReactivateUserPage() {
    const context = useContext(ApplicationContext);

    const constants_SA = kSYSTEM_ADMINISTRATION;
    const constants = constants_SA.SUSPEND_REACTIVATE_USER;
    const constants_error = constants_SA.SUSPEND_REACTIVATE_USER.ERRORS;

    const [showLoading, setShowLoading] = useState(true);
    const [showUsersList, setShowUsersList] = useState(false);
    const [selectedUser, setSelectedUser] = useState(constants_SA.SELECT_USER);
    const [sortOption, setSortOption] = useState(constants_SA.SORT_BY_USER_ID);
    const [usersList, setUsersList] = useState([]);

    const [errorInfo, setErrorInfo] = useState('');
    const [statusMsg, setStatusMsg] = useState<string>();
    const [userInfo, setUserInfo] = useState<any>();
    const [suspensionDate, setSuspensionDate] = useState('');
    const [suspensionDateError, setSuspensionDateError] = useState<string>('');
    const [reactivationDateError, setReactivationDateError] = useState<string>('');
    const [reactivationDate, setReactivationDate] = useState('');
    const [isFetchingUserDetails, setIsFetchingUserDetails] = useState(true);
    const [disableForm, setDisableForm] = useState(false);

    let history = useHistory();

    useEffect(() => {
        extendSession()
        _sendReqForUsersList();
    }, []);

    useEffect(() => {
        if (!showLoading) {
            //ADA | Focus on header on initial load
            focusToElementWithClassName('.header-content .header-text', 400);
        }
    }, [showLoading]);

    useEffect(() => {
        extendSession()
        _sendReqForUsersList();
    }, [sortOption]);

    useEffect(() => {
        extendSession()
        if (selectedUser !== constants_SA.SELECT_USER) {
            _getSuspendUserOldDetails();
        }
    }, [selectedUser]);


    function resetPage() {
        setErrorInfo('');
        setStatusMsg('');
        setSuspensionDate('');
        setReactivationDate('');
        setSelectedUser(constants_SA.SELECT_USER);
        if (sortOption !== constants_SA.SORT_BY_USER_ID) {
            //On sort option change, API will be called for userList, no need to make API call explicitly here
            setSortOption(constants_SA.SORT_BY_USER_ID);
        } else {
            _sendReqForUsersList();
        }
    }

    function _onUserSelect(value: any) {
        setShowUsersList(!showUsersList);
        focusToSelectUserEle();

        if (value === selectedUser) {
            return;
        }
        setSelectedUser(value);
    }

    const _handleOnUserListVisibleChange = (visible: boolean) => {
        if (!visible) {
            focusToSelectUserEle();
            setShowUsersList(visible)
        }
    };

    const focusToSelectUserEle = () => {
        focusToElementWithClassName('.users-list', 150);
    }

    const onSortOptionChanged = (e: any) => {
        const sortOption = e.target.value;
        setSortOption(sortOption);

        setSuspensionDate('');
        setReactivationDate('');
    }

    /**
     * After user selection
     */
    function _getSuspendUserOldDetails() {
        setErrorInfo('');
        setIsFetchingUserDetails(true);
        const userDetails = getUserDetailsForUser();
        const userId = userDetails.userId;

        const params = { "userId": userId };
        __req
            .updateSuspendUserOldDetails(params, _handleSuspendUserOldDetailsResp)
            .then((resp) => {
                if (resp.status !== WAIT_FOR_IOS_CALLBACK) {
                    _handleSuspendUserOldDetailsResp(resp.data);
                } else {
                    console.log('waiting for ios call', resp);
                }
            })
            .catch(() => {
                setIsFetchingUserDetails(false);
                setErrorInfo(kCONSTANTS.REQ_FAILED);
            });
    }

    function _handleSuspendUserOldDetailsResp(resp: { [key: string]: any }) {
        setIsFetchingUserDetails(false);

        if (resp && resp.success && resp.body && resp.body.userInfo) {
            const respUserInfo = resp.body.userInfo;
            if (respUserInfo.suspendDate !== null && respUserInfo.suspendDate.length > 0) {
                setSuspensionDate(respUserInfo.suspendDate);
            } else {
                setSuspensionDate('');
            }

            if (respUserInfo.reactivateDate !== null && respUserInfo.reactivateDate.length > 0) {
                setReactivationDate(respUserInfo.reactivateDate);
            } else {
                setReactivationDate('');
            }

            setUserInfo(respUserInfo);
            setSuspensionDateError('');
            setReactivationDateError('');

        } else {
            setErrorInfo(kCONSTANTS.REQ_FAILED);
        }
    }

    const disableSuspensionDate = () => {
        if (disableForm || (userInfo !== null && userInfo !== undefined && userInfo.suspended)) {
            return true;
        }

        return false;
    }

    const disableReactivationDate = () => {
        if (disableForm ? disableForm : suspensionDate === '') {
            return true;
        }

        return false;
    }

    function handleCancelClick() {
        const pathname = APP_LOCATIONS.SignInHome;
        history.push({
            pathname,
        });
    }

    const disableSubmit = () => {

        if (suspensionDate.length > 0) {
            return false;
        }

        return true;
    }

    function handleSubmitClick() {
        extendSession()
        setSuspensionDateError('');
        setReactivationDateError('');

        if (!_isValidSuspensionDate() || !_isValidReactivationDate()) {
            focusToElementWithClassName('.error-message', 150)
            return;
        }
        setErrorInfo('');
        const userDetails = getUserDetailsForUser();
        const userId = userDetails.userId;

        const params = {
            "userId": userId,
            "suspendDate": userInfo.suspended ? '' : suspensionDate,
            "reactivateDate": reactivationDate,
            "suspended": userInfo.suspended
        };

        setDisableForm(true);
        __req
            .submitUpdateSuspendUserNewDetails(params, _handleUpdateSuspendUserResp)
            .then((resp) => {
                if (resp.status !== WAIT_FOR_IOS_CALLBACK) {
                    _handleUpdateSuspendUserResp(resp.data);
                } else {
                    console.log('waiting for ios call', resp);
                }
            })
            .catch(() => {
                setDisableForm(false);
                setErrorInfo(kCONSTANTS.REQ_FAILED);
            });
    }

    function _handleUpdateSuspendUserResp(resp: { [key: string]: any }) {
        setDisableForm(false);

        if (resp && resp.success && resp.body) {
            if (resp.body.status) {
                setStatusMsg(resp.body.status);
            } else {
                setErrorInfo(kCONSTANTS.REQ_FAILED);
            }
        } else if (resp && !resp.success && resp.errors && resp.errors[0].value) {
            setErrorInfo(resp.errors[0].value);
        } else {
            setErrorInfo(kCONSTANTS.REQ_FAILED);
        }
    }

    function _getDisabledReactivationDate(date?: any) {
        const c = moment(date)
        if (!c) {
            return false;
        }

        //For suspended user, allow today date selection for reactivation
        if (userInfo.suspended) {
            return c < moment().startOf("day");
        }
        //


        var suspendedDateObj = new Date(suspensionDate);
        const disable = moment(suspendedDateObj, 'MM/dd/yyyy')

        const today = moment().startOf('day');

        if (disable >= c.startOf('day')) return (true)

        return today >= c
    }

    function _getDisabledSuspensionDate(date?: any) {
        const c = moment(date);
        if (!c) {
            return false;
        }
        return c < moment().startOf("day");
    }

    const _isValidSuspensionDate = () => {
        const suspensionDateFormatted = suspensionDate ? new Date(suspensionDate) : new Date();
        const todayDate = new Date();

        let Difference_in_Time = suspensionDateFormatted.getTime() - todayDate.getTime();
        let Difference_in_Days = Difference_in_Time / (1000 * 3600 * 24);

        if (Difference_in_Days > 90) {
            setSuspensionDateError(constants_error.SUSPENSION_DATE_90_DAYS_IN_ADVANCE_OF_CURRENT_DATE);
            return false
        }
        if (reactivationDate.length > 0 && userInfo !== null && !userInfo.suspended && reactivationDate === suspensionDate) {
            setSuspensionDateError(constants_error.SUSPENSION_DATE_REACTIVATION_DATE_CANNOT_SAME);
            setReactivationDateError('');
            return false
        }
        if (reactivationDate.length > 0) {
            const reactivationDateFormatted = reactivationDate ? new Date(reactivationDate) : new Date();
            Difference_in_Time = suspensionDateFormatted.getTime() - reactivationDateFormatted.getTime();
            Difference_in_Days = Difference_in_Time / (1000 * 3600 * 24);
            if (Difference_in_Days > 0) {
                setSuspensionDateError(constants_error.SUSPENSION_DATE_SHOULD_BEFORE_REACTIVATION_DATE);
                return false
            }
        }

        setSuspensionDateError('');
        return true
    }

    const _isValidReactivationDate = () => {
        if (reactivationDate.length === 0) {
            return true
        }

        const suspensionDateFormatted = suspensionDate ? new Date(suspensionDate) : new Date();
        const reactivationDateFormatted = reactivationDate ? new Date(reactivationDate) : new Date();

        let Difference_in_Time = reactivationDateFormatted.getTime() - suspensionDateFormatted.getTime();
        let Difference_in_Days = Difference_in_Time / (1000 * 3600 * 24);

        if (Difference_in_Days > 365) {
            setReactivationDateError(constants_error.REACTIVATION_DATE_IS_WITHIN_ONE_YEAR_OF_SUSPENSION_DATE);
            return false
        }
        if (suspensionDate.length > 0 && userInfo !== null && !userInfo.suspended && reactivationDate === suspensionDate) {
            setReactivationDateError(constants_error.SUSPENSION_DATE_REACTIVATION_DATE_CANNOT_SAME);
            setSuspensionDateError('');
            return false
        }

        setReactivationDateError('');
        return true
    }

    const onSuspensionDateChanged = (dateString: string) => {
        extendSession()
        if (dateString && dateString.length > 0) {
            setSuspensionDate(dateString);
        }
    }

    const onReactivationDateChanged = (dateString: string) => {
        extendSession()
        if (dateString && dateString.length > 0) {
            setReactivationDate(dateString);
        }
    }

    /**
     * API calls
     */
    function _sendReqForUsersList() {
        setSelectedUser(constants_SA.SELECT_USER);

        const params = {
            sortOrder: sortOption === constants_SA.SORT_BY_USER_ID ? "userId" : "userName",
            issuePassword: "false"
        };

        setShowLoading(true);
        __req
            .getUsersList(params, _handleUsersListResp)
            .then((resp) => {
                if (resp.status !== WAIT_FOR_IOS_CALLBACK) {
                    _handleUsersListResp(resp.data);
                } else {
                    console.log('waiting for ios call', resp);
                }
            })
            .catch(() => {
                setShowLoading(false);
                setErrorInfo(kCONSTANTS.REQ_FAILED);
            });
    }

    function _handleUsersListResp(resp: { [key: string]: any }) {
        setShowLoading(false);

        if (resp && resp.success && resp.body && resp.body.userInfo) {
            const usersList = resp.body.userInfo;
            setUsersList(usersList);
        }
    }

    function getUserDetailsForUser() {
        const user = selectedUser;
        const indexOfDash = user.indexOf('-');
        let userId = '';
        if (indexOfDash > 0 && indexOfDash < user.length) {
            userId = user.substring(0, indexOfDash - 1)
        }

        return { userId };
    }

    /**
     * UI
     */
    const uiSortOptions = () => {
        return <>
            <RadioGroupView
                parentClassName="sort-type-radio-wrapper"
                radioGroupName='sort-type'
                radioGroupClassName="sort-type"
                disable={disableForm || showLoading}
                inline={true}
                options={[constants_SA.SORT_BY_USER_ID, constants_SA.SORT_BY_USER_NAME]}
                onChange={onSortOptionChanged}
                value={sortOption}
                radioChildren={(val: string) => {
                    return <span aria-hidden="true">{val}</span>
                }}
            />
        </>
    }

    function _renderUserListDropdown() {
        return (
            <>
                <div className="users-list-dropdown-container" onClick={() => setShowUsersList(!showUsersList)}>
                    <Dropdown
                        className="users-list"
                        disabled={usersList.length <= 0 || disableForm}
                        visible={showUsersList}
                        defaultLabel={constants_SA.SELECT_USER}
                        value={selectedUser}
                        onChange={_onUserSelect}
                        onVisibleChange={_handleOnUserListVisibleChange}
                        itemArray={usersList}
                        showLoading={showLoading}
                    />

                    {selectedUser === constants_SA.SELECT_USER ?
                        <div className="instruction-container">
                            <p className="instruction-select-user" tabIndex={0}>
                                {constants.INSTRUCTION_SELECT_USER}
                            </p>
                        </div>
                        : null
                    }
                </div>

                {selectedUser !== constants_SA.SELECT_USER ?
                    isFetchingUserDetails ?
                        <div className="loading-container">
                            <Loading
                                tip={kCONSTANTS.LOADING}
                                horizontal
                                className="loading-center"
                            />
                        </div>
                        : _renderDateSelectionView()
                    : null
                }
            </>
        )
    }

    function _renderUserSelectionContentView() {
        return (
            <div>
                {errorInfo.length > 0 ?
                    <div style={{ marginBottom: '20px' }} aria-label={`Error ${errorInfo}`}>
                        <Alert
                            type='error'
                            content={errorInfo}
                        />
                    </div> : null
                }
                {!errorInfo &&
                    <div>

                        <p tabIndex={0} aria-label={`Heading 2, ${constants_SA.USER_SELECTION}`} className='title title-text'>
                            <span aria-hidden="true">{constants_SA.USER_SELECTION}</span>
                        </p>

                        <div className="sort-type-radio-wrapper">
                            {uiSortOptions()}
                        </div>

                        {!showLoading
                            ? _renderUserListDropdown()
                            :
                            <div className="loading-container">
                                <Loading
                                    tip={kCONSTANTS.LOADING}
                                    horizontal
                                    className="loading-center"
                                />
                            </div>
                        }
                    </div>
                }

            </div>
        );
    }

    function _renderDateSelectionView() {
        return (
            <div className="date-selection-container">
                <div className="instruction-container">
                    <p className="instruction-date" tabIndex={0}>
                        {constants.INSTRUCTION_DATE}
                    </p>
                </div>

                <div className={`date-field-container date-view suspension-date-container`}>

                    <label
                        tabIndex={0}
                        aria-label={constants.SUSPENSION_DATE + ' ' + kCONSTANTS.REQUIRED}
                        htmlFor={constants.SUSPENSION_DATE}
                        className='text-small select-label label-view'>
                        <MandatorySignSpan />
                        <span aria-hidden='true'>{constants.SUSPENSION_DATE}</span>
                    </label>

                    <DatePicker
                        disabled={disableSuspensionDate()}
                        format={constants.DATE_FORMAT}
                        placeholder={constants.DATE_FORMAT.toUpperCase()}
                        triggerClass='date-picker-trigger'
                        className='suspension-date'
                        disabledDate={_getDisabledSuspensionDate}
                        defaultValue={suspensionDate ? moment(suspensionDate, 'MM/DD/YYYY') : undefined}
                        showToday={false}
                        onChange={onSuspensionDateChanged}
                        id="suspension-date"
                        label="Suspension Date"
                        allowClear
                    />

                    {suspensionDateError.length > 0 ? <span className="error-message">{suspensionDateError}</span> : null}

                </div>

                <div className={`date-field-container date-view reactivation-date-container`}>

                    <label
                        tabIndex={0}
                        aria-label={constants.REACTIVATION_DATE}
                        htmlFor={constants.REACTIVATION_DATE}
                        className='text-small select-label label-view'>
                        <span aria-hidden="true">{constants.REACTIVATION_DATE}</span>
                    </label>

                    <DatePicker
                        disabled={disableReactivationDate()}
                        format={constants.DATE_FORMAT}
                        placeholder={constants.DATE_FORMAT.toUpperCase()}
                        triggerClass='date-picker-trigger'
                        className='reactivation-date'
                        disabledDate={_getDisabledReactivationDate}
                        defaultValue={reactivationDate ? moment(reactivationDate, 'MM/DD/YYYY') : undefined}
                        showToday={false}
                        onChange={onReactivationDateChanged}
                        id="reactivation-date"
                        label="Reactivation Date"
                        allowClear
                    />

                    {reactivationDateError.length > 0 ? <span className="error-message">{reactivationDateError}</span> : null}
                </div>

                <div className="lmn-col">
                    <Button
                        showLoading={disableForm}
                        color="primary"
                        disabled={disableSubmit() || disableForm}
                        className='buttonWithMargin'
                        onClick={handleSubmitClick}>
                        {
                            kCONSTANTS.SUBMIT
                        }
                    </Button>
                    <Button
                        onClick={handleCancelClick}
                        color="ghost"
                        className='buttonWithMargin'>
                        {
                            kCONSTANTS.CANCEL
                        }
                    </Button>
                </div>
            </div>
        );
    }

    function _renderStatusUI() {
        let userName = '';
        if (userInfo.firstName.trim().length > 0) {
            userName = userName + userInfo.firstName.trim();
        }
        if (userInfo.lastName.trim().length > 0) {
            userName = userName + ' ' + userInfo.lastName.trim();
        }

        const userInfoArray = {
            [constants_SA.USER_NAME]: userName,
            [constants_SA.USER_ID]: userInfo.userId,
            [constants.SUSPENSION_DATE]: suspensionDate,
            [constants.REACTIVATION_DATE]: reactivationDate === '' ? '-' : reactivationDate
        };

        return (
            <div className="status-container">

                <div>
                    <Alert
                        type={'success'}
                        title={constants_SA.STATUS}
                        content={statusMsg}
                    />
                </div>

                <div className="user-info-container-bg">
                    {
                        _.map(userInfoArray, (val: { [key: string]: any }, key: number) => {
                            return (
                                <div className="user-info-container"
                                    tabIndex={0}
                                    key={key}
                                    aria-label={`${key} ${val.toString()}`}>
                                    <label aria-hidden="true" className="label-title">
                                        {key}
                                    </label>

                                    <div aria-hidden="true" className="label-value-container">
                                        <label className="label-separator">
                                            |
                                        </label>
                                        <label className="label-value">
                                            {val.toString()}
                                        </label>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

                <Button
                    color={'primary'}
                    onClick={resetPage}
                    className='buttonWithMargin'>
                    {
                        constants_SA.OK
                    }
                </Button>
            </div>
        )
    }

    return (
        <>
            <LandingHeader
                title={constants.TITLE}
                showBackBtn
                onBackClick={() => { context?.Router.navigate(APP_LOCATIONS.SignInHome) }}
            />
            <div className="react-container bg-gray top-40 auto-overflow suspend-reactivate-user-container">

                {statusMsg ? _renderStatusUI() : _renderUserSelectionContentView()}
            </div>

        </>
    );
}

export default SuspendReactivateUserPage;