import _ from 'lodash';
import { ChangeEventHandler, ReactNode, useEffect } from 'react'
import { Radio, RadioGroup } from '@citi-icg-172888/icgds-react';
import { analyticsBranded } from '../../utils/event-tracking.util';
import { camelizeWithSpace } from '../../utils/common.util';

interface RadioGroupViewProps {
    parentClassName?: string,
    radioGroupName: string,
    radioGroupClassName?: string,
    disable?: boolean,
    inline?: boolean,
    options: any,
    customAriaLabels?: any,
    radioId?: string,
    defaultValue?: string,
    value?: string | { [key: string]: any },
    onChange: ChangeEventHandler,
    radioDisableCondition?: Function,
    radioChildren?: (val: any, index: any) => ReactNode;
}

const RadioGroupView = (p: RadioGroupViewProps) => {

    useEffect(() => {
        _setADALabelForRadioGroup();
    }, []);

    useEffect(() => {
        _setADALabelForRadioGroup();
    }, [p.value]);


    const onChange = (e: any) => {
        const val = e.target ? e.target.value : e;
        analyticsBranded(1, val);
        p.onChange(e);
    }

    function _setADALabelForRadioGroup() {
        const sortTypeRadioGroup: Element | null = document.querySelector(`.${p.radioGroupClassName}`);
        if (sortTypeRadioGroup) {
            sortTypeRadioGroup.setAttribute('role', 'radiogroup');

            p.options.forEach((option: any, index: number) => {
                const sortTypeRadio: Element | null = document.querySelector(`.${p.radioGroupName}-radio-${index}`);
                if (sortTypeRadio) {
                    sortTypeRadio.setAttribute('role', 'radio');
                    sortTypeRadio.setAttribute('tabindex', '0');
                    sortTypeRadio.setAttribute('aria-checked', `${p.value === option ? true : false}`);
                    if (p.customAriaLabels) {
                        sortTypeRadio.setAttribute('aria-label', `Radio button, ${p.customAriaLabels[index]} ${p.value === option ? ' Checked' : ' Not Checked'}, In group, Option ${index + 1} of ${p.options.length}`);
                    } else {
                        sortTypeRadio.setAttribute('aria-label', `Radio button, ${camelizeWithSpace(option)} ${p.value === option ? ' Checked' : ' Not Checked'}, In group, Option ${index + 1} of ${p.options.length}`);
                    }
                }
            });
        }
    }

    /**
     * Render the picker control
     * @returns 
     */
    const createUI = () => {

        return <RadioGroup
            disabled={p.disable}
            name={p.radioGroupName}
            className={p?.radioGroupClassName}
            defaultValue={p.defaultValue}
            value={p.value}
            onChange={onChange}
        >
            {
                _.map(p.options, (val, key) => {
                    return <Radio
                        inline={p.inline}
                        tabIndex={0}
                        id={p.radioId}
                        className={`${p.radioGroupName}-radio-${key}`}
                        key={key}
                        value={typeof val === 'string' ? val : (typeof val === 'object' && val !== null ? JSON.stringify(val) : '')}
                        disabled={p.radioDisableCondition && p.radioDisableCondition(val)}
                    >
                        {p.radioChildren ? p.radioChildren(val, key) : null}
                    </Radio>
                })}
        </RadioGroup>

    }

    return createUI();

}


export default RadioGroupView;