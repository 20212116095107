import moment from "moment";

export const MONTHS = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"];

function getFullMonth(k: number) {
    return MONTHS[k];
}

export function getDateCitiFormat(d: string) {
    var date = new Date(d);
    return `${getFullMonth(date.getMonth())} ${date.getDate()}, ${date.getFullYear()}`
}

/**
 * Convert timestamp to MM/DD/YYYY HH:MM AM/PM ET format
 * @param timestamp 
 * @returns 
 */
export function convertToEasternTime(timestamp: string | number | Date): string {
    return moment(timestamp).tz("America/New_York").format("MM/DD/YYYY hh:mm A") + " ET";
}


export function getDateCitiFormatL(d: number) {
    var date = new Date(d);
    return `${getFullMonth(date.getMonth())} ${date.getDate()}, ${date.getFullYear()}`
}


/**
 * Error check when future date is current date
 * @param date 
 * @param futureCheck 
 * @returns 
 */
export const isValidDate = (date: string, futureCheck: boolean = false): boolean => {
    if (date === undefined || date === '') return false;

    const d = moment(date, 'MM/DD/YYYY');

    const c = moment().endOf('day')

    if (futureCheck) {
        return d > c
    }
    return d.isValid()
}

export const disableDatesAfter = moment().tz("America/New_York").add(1, 'years').format('MM/DD/YYYY');

export const disableDatesBefore = moment().tz("America/New_York").format('MM/DD/YYYY');