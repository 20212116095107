export const feedbackLink = 'https://www.citibank.com/commercialbank/surveys/citibusiness-mobile-app-feedback/form.jsp';

export function defaultData() {
    const data = {
        bankingGold: "Business Banking Services Team",
        bankingGold1: "1-888-484-2484",
        bankingGoldPhoneNo: "18884842484",
        citiBusinessMobileFeedback: "CitiBusiness&#174; Mobile Feedback",
        forAssistance: "For assistance or to get more information about our products and services, please utilize one of the following phone numbers",
        header: "Contact Us",
        mondayFriday: "Mon-Fri",
        mondayFriday1: "7:00 AM - 11:00 PM ET",
        onlineSupport: "CitiBusiness&#174; Online Support",
        onlineSupport1: "1-800-285-1709, Option 1",
        onlineSupportPhoneNo: "18002851709",
        paymentSupport: "Bill Payment Support",
        paymentSupport1: "1-800-285-1709",
        paymentSupportPhoneNo: "18002851709",
        privateBank: "Private Bank clients call",
        privateBank1: "1-866-513-7802",
        privateBankPhoneNo: "18665137802",
        saturdaySunday: "Sat-Sun",
        saturdaySunday1: "9:00 AM - 5:30 PM ET",
        serviceCenter: "CitiBusiness Service Center",
        serviceCenter1: "1-877-528-0990",
        serviceCenterPhoneNo: "18775280990",
        smallBusiness: "Small Business",
        specialtyServicesUnit: "CitiBusiness Online Wires",
        specialtyServicesUnit1: "1-877-528-0990, Option 5",
        specialtyServicesUnitPhoneNo: "18775280990"
    }

    return parseData(data);
}

export function parseData(data: { [key: string]: string }) {
    const content = [
        {
            htmlHeader: data.onlineSupport,
            content: <>
                <a href={`tel:${data.onlineSupport1}`.split(',')[0]}>
                    {data.onlineSupport1.split(',')[0]}
                </a>
                {` (${data.onlineSupport1.split(',')[1]})`}<br />
                {data.mondayFriday}:{data.mondayFriday1}<br />
                {data.saturdaySunday}:{data.saturdaySunday1}
            </>
        },
        {
            header: <>{data.serviceCenter}<br /><small>({data.smallBusiness})</small></>,
            content: <a href={`tel:${data.serviceCenter1}`}>{data.serviceCenter1}</a>
        },
        {
            header: data.paymentSupport,
            content: <a href={`tel:${data.paymentSupport1}`}>{data.paymentSupport1}</a>
        },
        {
            header: data.specialtyServicesUnit,
            content: <>
                <a href={`tel:${data.specialtyServicesUnit1}`.split(',')[0]}>
                    {data.specialtyServicesUnit1.split(',')[0]}
                </a>
                {` (${data.specialtyServicesUnit1.split(',')[1]})`}
            </>
        },
        {
            header: data.privateBank,
            content: <a href={`tel:${data.privateBank1}`}>{data.privateBank1}</a>
        },
        {
            htmlHeader: data.citiBusinessMobileFeedback
        }
        // ,
        // {
        //     header: data.bankingGold,
        //     content: <a href={`tel:${data.bankingGold1}`}>{data.bankingGold1}</a>
        // }
    ];

    return {
        forAssistance: data.forAssistance,
        content
    }
}