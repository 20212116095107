import React from "react";
import _ from 'lodash';
import './CBLInput.style.scss';

interface Props {
    type?: string,
    label?: string
    required?: boolean
    showHiddenIcon?: boolean
    value?: string
    placeholder?: string
    onChange: any
    onBlur?: any
    onFocus?: any,
    onKeyDown?: React.KeyboardEventHandler
    disabled?: boolean,
    maxLength?: number,
    autoFocus?: boolean,
    isBusinessCode?: boolean
    mask?: boolean
    numericInput?: boolean,
    biometricIcon?: string,
    onBiometricIconClick?: Function,
    isSMSOtp?: boolean
    hideBiometricsIcon?: boolean
}

interface State {
    hidden?: boolean,
    type?: string
}

export default class CBOLPassword extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            hidden: true,
            type: this.props.type === 'password' ? this.props.type : 'text'
        };
        this.toggleShow = this.toggleShow.bind(this);
    }


    /**
     * When the type is OTP, the input type is set to password by default
     */
    componentDidMount(): void {
        if (this.props.value !== '' && this.props.type === 'OTP') {
            if (this.state.type !== 'password') {
                this.setState({ type: 'password' })
            }
        }
    }

    /**
     * This is to dynamically change the input field type between 'text' and 'password' based on the value and type props.
    */

    componentDidUpdate(): void {
        if (this.props.value === '' && this.props.type === 'OTP') {
            if (this.state.type !== 'text') {
                this.setState({ type: 'text' })
            }
        }
        else if (this.props.value !== '' && this.props.type === 'OTP') {
            if (this.state.type !== 'password') {
                this.setState({ type: 'password' })
            }
        }
    }

    toggleShow() {
        const isHidden = this.state.hidden;
        this.setState({ hidden: !this.state.hidden });
        if (isHidden) {
            this.setState({ type: 'text' });
        } else {
            this.setState({ type: this.props.type === 'password' ? this.props.type : 'text' });
        }
    }

    generateInputId() {
        const customId = 'input-' + _.get(this.props, 'type', 'password') + '-' + window.location.hash + '-' + _.get(this.props, 'label', 'default');
        return customId.replace(/#|\//g, '').replace(/ /g, '-').toLocaleLowerCase();
    }

    onChange(e: any) {
        this.props.onChange(e);
        const val = (e.target as any).value;
        if (this.props.type === 'OTP') {
            if (val.length > 0) {
                this.setState({ type: 'password' });
            } else {
                this.setState({ type: 'text' });
            }
        }
    }

    render() {
        return (
            <>
                <div className="cbusol-input-group">

                    {
                        this.props.label ?
                            <label className="input-label" aria-label={`${this.props.label}${this.props.required ? ', Required' : ''} `}>
                                {
                                    this.props.required ?
                                        <span aria-hidden="true" style={{ color: '#B60000' }}>
                                            {`* `}
                                        </span>
                                        : null
                                }
                                <span aria-hidden="true"> {this.props.label}</span>
                            </label> :
                            null
                    }

                    <input
                        id={this.generateInputId()}
                        className={`cbusol-input ${this.props.isBusinessCode ? 'business-code-input' : ''} ${this.props.disabled ? 'disabled' : ''}`}
                        type={(this.state.hidden && this.props.type === 'password') ? this.props.type : this.state.type}
                        onChange={(e) => this.onChange(e)}
                        onBlur={this.props.onBlur}
                        onFocus={this.props.onFocus}
                        onKeyDown={this.props.onKeyDown}
                        value={this.props.value}
                        disabled={this.props.disabled}
                        readOnly={this.props.disabled}
                        maxLength={this.props.maxLength}
                        autoFocus={this.props.autoFocus}
                        aria-label={this.props.label}
                        alt={this.props.label}
                        inputMode={this.props.numericInput ? "numeric" : undefined}
                        autoComplete={this.props.isSMSOtp ? "one-time-code" : undefined}
                    />
                    <>
                        {
                            this.props.showHiddenIcon ?
                                <i role="img" aria-label={`${(this.state.hidden) ? `Show ${(this.props.label)}` : `Hide ${(this.props.label)}`}, double-tap to activate`}
                                    className={`input-label-icon lmnicon ${(this.state.hidden) ? 'lmnicon-view-disabled' : 'lmnicon-view'}`}
                                    onClick={this.toggleShow}
                                ></i> : null
                        }
                    </>
                    {
                        !this.props.showHiddenIcon && this.props.biometricIcon ?
                            <img
                                alt=""
                                id='biometric-login-icon'
                                src={this.props.biometricIcon}
                                className='input-biometric-icon'
                                onClick={() => _.isFunction(this.props.onBiometricIconClick) && this.props.onBiometricIconClick()}></img>
                            :
                            null
                    }
                </div>
            </>
        );
    }

}