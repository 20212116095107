const BUILD_UTIL = {

    isProduction: (): boolean => {
        return process.env.NODE_ENV === 'production';
    },

    isDev: (): boolean => {
        return process.env.NODE_ENV === 'development';
    },

    isTest: (): boolean => {
        return process.env.NODE_ENV === 'test';
    },

    environment: (): 'uat' | 'stg' | 'dev' | 'sit' | 'prod' => {
        const host = window.location.hostname
        if (host === 'localhost') return 'dev'
        const idx = host.indexOf('.')
        if (idx > -1) {
            const subdomain = host.substring(0, idx)
            if (subdomain.startsWith('mobile')) {
                const env = subdomain.replace('mobile', '')
                if (env === 'uat') return 'uat'
                if (env === 'dev') return 'dev'
                if (env === 'sit') return 'sit'
                if (env === 'stg') return 'stg'
                else return 'prod'
            }
        }
        return 'prod'
    }

};

export const disableConsoleLogs = () => {
    console.log = (...s: any) => { }
}

export default BUILD_UTIL;