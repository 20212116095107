import { useEffect, useState, useRef } from "react";
import { DatePicker as IcgdsDatePicker } from '@citi-icg-172888/icgds-react';
import _ from "lodash";
import moment from "moment";
import kWIRES from "../../common/constants/WIRES";
import "./datepicker.style.scss";
import { MONTHS } from "../../utils/date-time.util";
import { focusToElementWithClassName } from "../../utils/common.util";

interface DatePickerProps {
    allowClear?: boolean,
    disabled?: boolean,
    format?: string,
    isSecondDate?: boolean,
    placeholder?: string,
    triggerClass?: string,
    className?: string,
    showToday?: boolean,
    disabledDate?: any,
    onChange?: Function,
    dateRender?: any,
    disableToday?: boolean,

    defaultValue?: any,
    calendarClass?: string,
    label?: string,
    labelText?: string,
    id?: string,

    //For filter, need to aria-hidden for filter search modal
    classNameForAriaHidden?: string;
    renderTriggerElement?: any;
    onOpenChange?: Function;

    //For Fx-pulse
    onShowDateChange?: Function;
    disablePanelSelection?: boolean;
}

const DatePicker = (p: DatePickerProps) => {
    const allowClear = p.allowClear !== undefined ? p.allowClear : false;
    const showToday = p.showToday !== undefined ? p.showToday : true;
    const disableToday = p.disableToday !== undefined ? p.disableToday : false;

    const value = useRef<any>(p.defaultValue ? (p.format === 'dd' ? moment(p.defaultValue, p.format?.toUpperCase()).toDate() : moment(p.defaultValue).toDate()) : undefined);

    const [datePickerMode, setDatePickerMode] = useState<string>();
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [date, setDate] = useState(p.defaultValue ? moment(p.defaultValue).toDate() : undefined);
    const [uniqueId, setUniqueId] = useState('');
    const [panelMode, setPanelMode] = useState<any>();

    /*
    *Listener for Next and Previous click
    */
    const listeners = useRef<any>(new Map());
    function addListener(element: { addEventListener: (arg0: any, arg1: any) => void; } | null, event: any, listener: any) {
        element?.addEventListener(event, listener);
        if (!listeners.current.has(element)) {
            listeners.current.set(element, new Set());
        }
        listeners.current.get(element).add({ event, listener });
    }

    function hasListener(element: any, event: any, listener: any) {
        if (!listeners.current.has(element)) {
            return false;
        }

        return true;
    }

    const handleNextClick = (event: any) => {
        const currentDate = moment(value.current);

        let futureMonth = moment(currentDate).add(1, "M");
        let futureMonthEnd = moment(futureMonth).endOf("month");

        if (
            currentDate.date() !== futureMonth.date() &&
            futureMonth.isSame(futureMonthEnd.format("DD-MM-YYYY"))
        ) {
            futureMonth = futureMonth.add(1, "d");
        }

        if (p.onShowDateChange) {
            value.current = moment(futureMonth).toDate();
            p.onShowDateChange(futureMonth);
        }
        setDateADA();
    };

    const handlePrevClick = (event: any) => {
        const currentDate = moment(value.current);
        let pastMonth = moment(currentDate).subtract(1, "M");
        let pastMonthEnd = moment(pastMonth).endOf("month");

        if (
            currentDate.date() !== pastMonth.date() &&
            pastMonth.isSame(pastMonthEnd.format("DD-MM-YYYY"))
        ) {
            pastMonth = pastMonth.subtract(1, "d");
        }

        if (p.onShowDateChange) {
            value.current = new Date(moment(pastMonth).toDate());
            p.onShowDateChange(pastMonth);
        }
        setDateADA();
    }
    /*
    *Listener end
    */

    useEffect(() => {
        if (p.defaultValue) {

            if (p.format === 'dd' || p.format === 'DD') {
                value.current = moment(p.defaultValue, p.format.toUpperCase()).toDate();
                setDate(moment(p.defaultValue, p.format.toUpperCase()).toDate())
            } else {
                value.current = moment(p.defaultValue).toDate();
                setDate(moment(p.defaultValue).toDate())

            }
        }
    }, [p.defaultValue, p.format]);

    /*
    *ADA Related code ends
    */
    useEffect(() => {
        if (showDatePicker) {
            _.delay(() => {
                const nodes = document.querySelector('.lmn-calendar-panel-header')?.getElementsByTagName('a');
                if (nodes && nodes.length > 0) {
                    _.each(nodes, (val: HTMLElement) => {
                        val.role = "button";
                        if (val.className === 'lmn-calendar-my-select') {
                            val.setAttribute("tabIndex", "-1");

                            _.delay(() => {
                                val.focus();
                            }, 400);   

                        } else if (val.className === 'lmn-calendar-next-btn' || val.className === 'lmn-calendar-prev-btn') {
                            val.setAttribute("aria-hidden", "true");
                        }

                        if (p.disablePanelSelection && val.className === 'lmn-calendar-month-select') {
                            val?.classList.add('disableClick');
                        } else if (p.disablePanelSelection && val.className === 'lmn-calendar-year-select') {
                            val?.classList.add('disableClick');
                        }
                    })
                }


                const inputEle = document.querySelector('.lmn-datepicker-panel')?.getElementsByTagName('wc-picker-input');
                if (inputEle && inputEle.length > 0) {
                    _.each(inputEle, (val: HTMLElement) => {
                        val.setAttribute("aria-hidden", "true");
                    })
                }

                //if DatePickerMode is already date, set aria-label directly in setAccessibilityAttribute, else set datePickerMode to 'date'
                datePickerMode === 'date' ? setAccessibilityAttribute() : setDatePickerMode('date');
            }, 100);
        }
    }, [showDatePicker]);

    useEffect(() => {
        if (showDatePicker) {
            _.delay(() => {
                setAccessibilityAttribute();
            }, 300);
        }
    }, [datePickerMode, showDatePicker]);

    useEffect(() => {
        // Generate a unique ID for each datepicker instance
        setUniqueId(`datepicker-${Math.random().toString(36).substring(2, 11)}`);
    }, []);

    /**
    * * ADA - Set aria-label for calendar dots, clear date icon & date picker input field
    */
    useEffect(() => {
        if (!uniqueId) return;
        _.delay(() => {
            const ele: Element | null = document.querySelector(`.${uniqueId} .lmn-datepicker-holder-input`);
            if (ele) {
                if (date === undefined || date === null) {
                    ele.setAttribute('aria-label', `${p.label ? p.label : p.labelText}, ${p.disabled ? ', dimmed' : p.label === "Second Date" ? 'Select Day' : 'Select date'} `);
                } else {
                    ele.setAttribute("aria-label", `${p.label ? p.label : p.labelText}, ${p.label === "Second Date" ? moment(date).format('DD').toString() :
                        moment(date).format('MM/DD/YYYY').toString()}, selected`);
                }
                ele.setAttribute('role', 'button');
            }

            const calendarDotsEle: Element | null = document.querySelector(`.${uniqueId} .lmnicon-calendar-dots`);
            if (calendarDotsEle && (date === undefined || date === null || p.defaultValue !== undefined || p.defaultValue !== null)) {
                if (p.disabled) {
                    calendarDotsEle.setAttribute("aria-label", `Open ${p.label ? p.label : p.labelText}, Date picker, Dimmed`);
                } else {
                    calendarDotsEle.setAttribute("aria-label", `Open ${p.label ? p.label : p.labelText}, Date picker`);
                }
                calendarDotsEle.setAttribute('role', 'button');
                calendarDotsEle.setAttribute('tabIndex', '0');
            }
        }, 100);

        _.delay(() => {
            const clearDateEle: Element | null = document.querySelector(`.${uniqueId} .lmnicon-close`);
            if (clearDateEle && (date !== undefined || date !== null)) {
                clearDateEle.setAttribute('aria-label', `Clear ${p.label ? p.label : p.labelText}, double tap to activate`);
                clearDateEle.setAttribute('role', 'button');
                clearDateEle.setAttribute('tabIndex', '0');
            }
        }, 100);
    }, [date, p.defaultValue, p.disabled, uniqueId, p.label, p.labelText]);


    /*
    *ADA | Android - fix Able to browse outside modal 
    */

    useEffect(() => {
        const wrappers = document.getElementsByClassName('lmn-context-menu');
        for (let e of wrappers) {
            e.setAttribute("role", "dialog");
            e.setAttribute('aria-modal', 'true');
            e.setAttribute('aria-live', "assertive");
        }

    }, [showDatePicker])


    /**
     * Set ADA for Date elements
     */
    const setDateADA = (currentMode?: string) => {
        const mode = currentMode === null || currentMode === undefined ? panelMode : currentMode;

        _.delay(() => {
            const datePickerEle: HTMLElement | null = document.querySelector(`.lmn-datepicker`);
            const dateSelectionBodyChildEles = datePickerEle?.querySelector(".lmn-calendar-panel .lmn-calendar-table .lmn-calendar-tbody")?.getElementsByTagName("td")
            if (dateSelectionBodyChildEles) {

                for (var r = 0; r < dateSelectionBodyChildEles.length; r++) {
                    var tdElement = dateSelectionBodyChildEles[r];

                    tdElement.setAttribute("aria-hidden", "false");
                    const className = tdElement.classList;

                    let ariaLabel = tdElement.title;
                    ariaLabel = ariaLabel + ' ' + kWIRES.WIRES_INITIATE.DAYS[tdElement.cellIndex];

                    if (className.contains('lmn-calendar-next-month-cell')) {
                        ariaLabel = ariaLabel + ' Next month date, please select next month first';
                    }

                    if (className.contains('lmn-calendar-today')) {
                        if (disableToday) {
                            ariaLabel = ariaLabel + ' Today button disabled';
                        }
                        else {
                            ariaLabel = ariaLabel + ' Current date';
                        }
                    }

                    if (className.contains('lmn-calendar-selected-date')) {
                        ariaLabel = ariaLabel + ' Selected';
                    }

                    if (className.contains('lmn-calendar-disabled-cell')) {
                        ariaLabel = ariaLabel + ' Disable';
                    }

                    const divTagEle = tdElement.querySelector('.lmn-calendar-date');
                    if (divTagEle) {
                        if (mode !== 'year' && mode !== 'month') {
                            divTagEle.setAttribute("aria-label", ariaLabel);
                            divTagEle.setAttribute("tabIndex", "0");
                            divTagEle.setAttribute("role", "button");
                        } else {
                            divTagEle.removeAttribute("aria-label");
                            divTagEle.removeAttribute("tabIndex");
                        }
                    }
                }
            }
        }, 500)
    }

    /* ADA - as per mode selection, focus to respected element */
    const setAccessibilityAttribute = (currentMode?: string) => {
        const datePickerEle: HTMLElement | null = document.querySelector(`.lmn-datepicker`);

        const mode = currentMode === null || currentMode === undefined ? datePickerMode : currentMode;
        setPanelMode(mode);
        if (datePickerEle) {

            if (mode === 'year') {

                //For year selection body elements when mode is year
                const yearSelectionBodyTable: HTMLElement | null = datePickerEle.querySelector(".lmn-calendar-year-panel .lmn-calendar-table")
                if (yearSelectionBodyTable) {
                    yearSelectionBodyTable.setAttribute("role", "presentation");
                    const yearSelectionBodyChildEles = yearSelectionBodyTable.querySelector(".lmn-calendar-year-panel-tbody")?.getElementsByTagName("td")
                    if (yearSelectionBodyChildEles) {
                        for (var i = 0; i < yearSelectionBodyChildEles.length; i++) {
                            var tdTagEle = yearSelectionBodyChildEles[i];
                            tdTagEle.setAttribute("aria-hidden", "false");

                            const className = tdTagEle.classList;
                            let ariaLabel = tdTagEle.title;
                            if (className.contains('lmn-calendar-year-panel-selected-cell')) {
                                ariaLabel = ariaLabel + ' Selected';
                            }
                            if (className.contains('lmn-calendar-year-cell-disabled')) {
                                ariaLabel = ariaLabel + ' Disable';
                            }

                            const aTagEle = tdTagEle.querySelector(".lmn-calendar-year");
                            if (aTagEle) {
                                if (mode === "year") {
                                    aTagEle.setAttribute("aria-hidden", "false");
                                    aTagEle.setAttribute('role', 'button');
                                    aTagEle?.setAttribute('tabIndex', '0');
                                    aTagEle.setAttribute("aria-label", ariaLabel);
                                } else {
                                    aTagEle.removeAttribute("aria-label");

                                }
                            }
                        }
                    }
                }

                //Make Year header panel readable when mode is year
                const datePickerYearHeaderEle: Element | null = datePickerEle.querySelector('.lmn-calendar-year-panel .lmn-calendar-panel-header');
                if (datePickerYearHeaderEle) {
                    const childNodeCount = datePickerYearHeaderEle?.childNodes.length
                    if (childNodeCount) {
                        for (let i = 0; i < childNodeCount; i++) {
                            const aTagEle = datePickerYearHeaderEle.getElementsByTagName('a')[i];
                            if (aTagEle) {
                                aTagEle?.setAttribute('role', 'button');
                                //focus to header
                                if (aTagEle.className === "lmn-calendar-year-select") {
                                    if (mode === "year") {
                                        aTagEle?.setAttribute('tabIndex', '-1');

                                        _.delay(() => {
                                            aTagEle?.focus();
                                        }, 400); 
                                    }
                                }
                            }
                        }
                    }
                }
            }

            if (mode === 'month') {
                //For month selection bdoy elements when mode is month
                const monthSelectionBodyTable: HTMLElement | null = datePickerEle.querySelector(".lmn-calendar-month-panel .lmn-calendar-table")
                if (monthSelectionBodyTable) {
                    monthSelectionBodyTable.setAttribute("role", "presentation");
                    const monthSelectionBodyChildEles = monthSelectionBodyTable.querySelector(".lmn-calendar-month-panel .lmn-calendar-tbody")?.getElementsByTagName("td")
                    if (monthSelectionBodyChildEles) {
                        for (var j = 0; j < monthSelectionBodyChildEles.length; j++) {
                            var tdTagElement = monthSelectionBodyChildEles[j];
                            tdTagElement.setAttribute("aria-hidden", "false");

                            const className = tdTagElement.classList;
                            let ariaLabel = tdTagElement.title;

                            //Take full month name based on index fromt the constant file
                            const monthIndex = (3 * (tdTagElement.parentNode as HTMLTableRowElement).rowIndex) + tdTagElement.cellIndex;
                            if (monthIndex < MONTHS.length) {
                                ariaLabel = MONTHS[monthIndex];
                            }

                            if (className.contains('lmn-calendar-month-panel-current-cell')) {
                                ariaLabel = ariaLabel + ' Current month';
                            }
                            if (className.contains('lmn-calendar-month-panel-selected-cell')) {
                                ariaLabel = ariaLabel + ' Selected';
                            }
                            if (className.contains('lmn-calendar-month-cell-disabled')) {
                                ariaLabel = ariaLabel + ' Disable';
                            }

                            const aTagEle = tdTagElement.querySelector(".lmn-calendar-month");
                            if (aTagEle) {
                                if (mode === "month") {
                                    aTagEle.setAttribute("aria-hidden", "false");
                                    aTagEle.setAttribute('role', 'button');
                                    aTagEle?.setAttribute('tabIndex', '0');
                                    aTagEle.setAttribute("aria-label", ariaLabel);
                                } else {
                                    aTagEle.removeAttribute("aria-label");
                                }
                            }
                        }
                    }
                }

                //Make Month header panel readable when mode is month
                const datePickerMonthHeaderEle: Element | null = datePickerEle.querySelector('.lmn-calendar-month-panel .lmn-calendar-panel-header');
                if (datePickerMonthHeaderEle) {
                    const childNodeCount = datePickerMonthHeaderEle?.childNodes.length
                    if (childNodeCount) {
                        for (let i = 0; i < childNodeCount; i++) {
                            const aTagEle = datePickerMonthHeaderEle.getElementsByTagName('a')[i];
                            if (aTagEle) {
                                aTagEle?.setAttribute('role', 'button');
                                //focus to header
                                if (aTagEle.className === "lmn-calendar-year-select") {
                                    if (mode === "month") {
                                        aTagEle?.setAttribute('tabIndex', '-1');

                                        _.delay(() => {
                                            aTagEle?.focus();
                                        }, 400);
                                    }
                                }
                            }
                        }

                    }
                }
            }


            const datePickerBodyEle: Element | null = datePickerEle.querySelector(`.lmn-calendar .lmn-calendar-panel`);
            const datePickerHeaderMonYearSelectEle: HTMLElement | null = datePickerEle.querySelector('.lmn-calendar .lmn-calendar-panel .lmn-calendar-my-select');
            const dateSelectionHeaderEleClassNames = ['.lmn-calendar-prev-btn', '.lmn-calendar-month-select',
                '.lmn-calendar-year-select', '.lmn-calendar-next-btn', '.lmn-calendar-my-select'];

            if (mode !== null && mode !== undefined && mode !== 'date') {
                //When mode is year/month, date selection header should not be accessible
                if (datePickerBodyEle) {
                    datePickerBodyEle.setAttribute("aria-hidden", "true");
                }
                if (datePickerHeaderMonYearSelectEle) {
                    datePickerHeaderMonYearSelectEle.setAttribute("aria-hidden", "true");

                    //All <a> child of date selection header
                    const dateSelectionHeaderAllChild = document.querySelector(".lmn-calendar-panel .lmn-calendar-panel-header")?.getElementsByTagName("a");
                    if (dateSelectionHeaderAllChild) {

                        for (var k = 0; k < dateSelectionHeaderEleClassNames.length; k++) {
                            var aTagEle = datePickerBodyEle?.querySelector(dateSelectionHeaderEleClassNames[k]);
                            if (aTagEle) {
                                aTagEle.setAttribute("aria-hidden", "true");
                            }
                        }
                    }
                }
            } else {
                //When mode is date, date selection header should be accessible
                if (datePickerBodyEle) {
                    datePickerBodyEle.setAttribute("aria-hidden", "false");
                }
                if (datePickerHeaderMonYearSelectEle) {
                    datePickerHeaderMonYearSelectEle.setAttribute("aria-hidden", "false");
                    datePickerHeaderMonYearSelectEle.setAttribute("tabIndex", "-1");

                    _.delay(() => {
                        datePickerHeaderMonYearSelectEle.focus();
                    }, 600);

                    //All <a> child of date selection header
                    const dateSelectionHeaderAllChild = datePickerEle.querySelector(".lmn-calendar-panel .lmn-calendar-panel-header")?.getElementsByTagName("a")
                    if (dateSelectionHeaderAllChild) {
                        for (var s = 0; s < dateSelectionHeaderEleClassNames.length; s++) {
                            var aTagElement = document.querySelector(dateSelectionHeaderEleClassNames[s]);

                            if (dateSelectionHeaderEleClassNames[s] === '.lmn-calendar-next-btn') {
                                if (!hasListener(aTagElement, 'click', handleNextClick)) {
                                    addListener(aTagElement, 'click', handleNextClick);
                                }
                            } else if (dateSelectionHeaderEleClassNames[s] === '.lmn-calendar-prev-btn') {
                                if (!hasListener(aTagElement, 'click', handlePrevClick)) {
                                    addListener(aTagElement, 'click', handlePrevClick);
                                }
                            }

                            if (aTagElement) {
                                aTagElement.setAttribute("aria-hidden", "false");
                                if (dateSelectionHeaderEleClassNames[s] !== '.lmn-calendar-my-select') {
                                    aTagElement.setAttribute("role", "button");
                                    aTagElement?.setAttribute("tabIndex", "0");
                                }
                            }
                        }
                    }

                    const dateSelectionHeaderAllIcon = datePickerEle.querySelector(".lmn-calendar-panel .lmn-calendar-panel-header")?.getElementsByTagName("wc-icon")
                    if (dateSelectionHeaderAllIcon) {
                        for (var s = 0; s < dateSelectionHeaderAllIcon.length; s++) {
                            var wcIconTagElement = dateSelectionHeaderAllIcon[s];

                            if (wcIconTagElement) {
                                wcIconTagElement.setAttribute("aria-hidden", "true");
                            }
                        }
                    }

                }
            }

            const tableEle: Element | null = datePickerEle.querySelector(".lmn-calendar-panel .lmn-calendar-table");
            //For weekdays header
            if (tableEle) {
                const tHeadEle = tableEle.getElementsByTagName('thead')[0];
                if (tHeadEle) {
                    const subEle = tHeadEle.getElementsByTagName('tr')[0];
                    if (subEle) {
                        const subThEle = subEle.getElementsByTagName('th');
                        if (subThEle) {
                            for (let i = 0; i < subThEle.length; i++) {
                                const thEle = subThEle[i];
                                thEle?.removeAttribute("title");
                                if (mode !== null && mode !== undefined && mode !== 'date') {
                                    thEle?.setAttribute("aria-hidden", "true");
                                    thEle?.removeAttribute("tabIndex");
                                } else {
                                    thEle?.setAttribute('aria-label', `${kWIRES.WIRES_INITIATE.DAYS[i]}`)
                                    thEle?.setAttribute("tabIndex", "0");
                                    thEle?.setAttribute("aria-hidden", "false");
                                }

                                const spanEleArray = thEle.querySelector(`.lmn-calendar-column-header-inner`);
                                if (spanEleArray) {
                                    spanEleArray?.setAttribute("aria-hidden", "true");
                                }

                            }
                        }
                    }
                }
            }

            // For date elements
            setDateADA(mode)
        }
    }


    const onChange = (dateString: any) => {
        value.current = dateString;
        setDate(dateString);
        focusToElementWithClassName(`.${uniqueId} .lmn-datepicker-holder-input`, 400);

        if (dateString === null) {
            if (p.onChange) {
                p.onChange(null)
            }
            return;
        }

        const format = p.isSecondDate ? 'DD' : 'MM/DD/YYYY';
        const convertedDate = moment(dateString).format(format).toString();
        if (p.onChange) {
            p.onChange(convertedDate)
        }
    }

    const onDatePickerOpen = (status: boolean | undefined) => {
        if (_.isBoolean(status)) {
            setShowDatePicker(status);
        }

        if (p.onOpenChange) {
            p.onOpenChange(status)
        }
    }

    const onPanelChange = (mode: any) => {
        setDatePickerMode(mode);
    }

    return (
        <IcgdsDatePicker
        isAdaptive={true}
            size={'default'}
            allowClear={allowClear}
            disabled={p.disabled}
            format={p.format}
            showToday={showToday}
            placeholder={p.placeholder ? p.placeholder : 'Select date'}
            // defaultValue={p.defaultValue}
            value={value.current} // the defaultValue don't displayed on iOS 16.x app. Set value to defaultValue and Update when the selected time is changing.
            // pickerClass={p.triggerClass}
            className={`${p.className} ${uniqueId}`}
            disabledDate={p.disabledDate ? p.disabledDate : false}
            onValueChange={onChange}
            onOpenChange={onDatePickerOpen}
            onPanelChange={onPanelChange}
            dateRender={p.dateRender}
            // renderTriggerElement={p.renderTriggerElement}
        />
    );
}

export default DatePicker;
