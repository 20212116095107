export class IOSCallBackHash {
    private hashDict: { [key: string]: string } = {};

    public hashAlreadyUsed(hash: string): Boolean {
        return Object.keys(this.hashDict).includes(hash);
    }

    public addHash(hash: string, url: string) {
        if(this.hashAlreadyUsed(hash)){
            this.warn(hash, url);   
        }
        this.hashDict[hash] = url;
    }

    public warn(hash: string, url: string) {
        console.warn(`Potential iOS issue detected: Call back hash ${hash} already implemented for ${url}`);
        console.info(`Potential iOS issue detected: Call back hash ${hash} already implemented for ${url}`);
        console.error(`Potential iOS issue detected: Call back hash ${hash} already implemented for ${url}`);
    }

}

const iosCallBackHash = new IOSCallBackHash();

export default iosCallBackHash;