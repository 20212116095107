const kAPPROVALS = {
    
    //Approvals
    APPROVALS: "Approvals",
    APPROVALS_HEADER: {
        APPROVALS: "Approvals",
        TRANSACTION_AWAITING: "Transactions Awaiting Approval",
        DETAILS: "Approval Details",
    },
    APPROVALS_TOTAL: "Total Pending",

    //Approvals - Transaction Awaiting Approval
    APPROVALS_TRANSACTION_TOTAL: "Total Approvals Pending",
    APPROVALS_SORT_ORDER: {
        AMOUNT_HIGH_FIRST: "AMOUNT_HIGH_FIRST",
        AMOUNT_LOW_FIRST: "AMOUNT_LOW_FIRST",
        PROCESSING_DATE_NEW_FIRST: "PROCESSING_DATE_NEW_FIRST",
        PROCESSING_DATE_OLD_FIRST: "PROCESSING_DATE_OLD_FIRST",
        SETUP_DATE_NEW_FIRST: "SETUP_DATE_NEW_FIRST",
        SETUP_DATE_OLD_FIRST: "SETUP_DATE_OLD_FIRST",
        ADDITIONAL_REFERENCE_HIGH_FIRST: "ADDITIONAL_REFERENCE_HIGH_FIRST",
        ADDITIONAL_REFERENCE_LOW_FIRST: "ADDITIONAL_REFERENCE_LOW_FIRST",
    },
    APPROVALS_TRANSACTION_TYPES: {
        WIRES_NON_BATCH: "WIRES_NON_BATCH",
        WIRES_FX: "WIRES_FX",
        WIRES_BATCH: "WIRES_BATCH",
        WIRE_MODELS_INDIVIDUAL: "WIRE_MODELS_INDIVIDUAL",
        WIRE_DIRECT_FILE: "WIRE_DIRECT_FILE",
        TRANSFER_NON_BATCH: "TRANSFER_NON_BATCH",
        TRANSFER_BATCH: "TRANSFER_BATCH",
        BILL_PAYMENTS: "BILL_PAYMENTS",
        ACH: "ACH",
        OPEN_ESCROW: "OPEN_ESCROW",
        OPEN_IMMA: "OPEN_IMMA",
        OPEN_CD: "OPEN_CD",
        LIQUIDATE: "LIQUIDATE",
        INSTANT_PAYMENTS_UNHANDLED: "INSTANT_PAYMENTS_UNHANDLED",
        INSTANT_PAYMENTS: "INSTANT_PAYMENTS",
    },
    APPROVALS_SORT_LABEL: "Sort Order",
    APPROVALS_EXPAND_LABEL: "Expand",
    APPROVALS_DEFAULT_SORT: "Amount - Highest First",
    APPROVALS_CUTOFF_TIME: "Cutoff time is",
    APPROVALS_VIEW_DETAILS: "View Additional Details",
    APPROVALS_NOTIFY: "Chase Approval",
    APPROVALS_DEFER_WARNING: "* All approvals are set to defer",
    APPROVALS_FX_FIELD: "Y",
    APPROVALS_RECURRING: "Recurring",
    APPROVALS_SCHEDULING: "Scheduling and Amount",
    APPROVALS_WIRES_FX: {
        INFO_KEY: "**FX Wires must be approved one at a time",
        INFO_VALUE: `To proceed, select "View Additional Details" option to view and/or approve an instruction.`,
        EXCHANGE_RATES_MESSAGE: "When this exchange rate expires, a new rate will be applied.\n\nFluctuation in FX rates may cause the US Dollar equivalent for this transaction to change. As a result, if you are the transaction approver, please be aware that the US Dollar equivalent may now exceed the entitlement limits for the initiating user."
    },
    APPROVALS_LIQUIDATION: {
        MESSAGE1: "This account has a balance of",
        MESSAGE2:
            "and cannot be liquidated at this time. You may leave this instruction pending approval until the balances are sufficient for liquidation or you may reject this instruction. Instructions left pending approval will be automatically rejected after 90 days.",
    },
    APPROVALS_REQUESTED_ACTION: {
        CANCEL: "cancel",
        SUSPEND: "suspend",
    },
    APPROVALS_SWITCH: {
        DEFER: "defer",
        APPROVE: "approve",
        REJECT: "reject",
        DEFER_ALL: "Defer All",
        APPROVE_ALL: "Approve All",
        REJECT_ALL: "Reject All",
    },
    APPROVALS_SUBMIT: {
        HEADER: {
            CREDENTIALS: "Enter your credentials",
            SUCCESS: "Success",
        },
        LABEL: {
            OTP2: "OTP2",
            PASSWORD: "Password",
        },
        FOOTER: {
            APPLY: "Apply",
            NEW: "New Approval",
            CLOSE: "Close",
        },
        STATUS: {
            FRAUD_QUEUE:
                "The following actions require immediate attention. To complete the execution of your wire request, contact CitiBusiness Online Customer Service at 1-866-583-2354 between 8AM and 8PM ET. Your wire could be delayed or canceled if you do not contact Customer Service. If the wire is canceled, the funds will be returned to the source account.",
        },
        INPUT_ERROR:
            "The security information you entered is not valid. Please try again.",
        RESPONSE_CODE: "APPROVED_6",
        NSF_RESPONSE_CODE: "INSUFFICIENT_FUNDS_3",
        ADDITIONAL_APPROVAL_CODE: "NEEDS_ADDITIONAL_7",
        FRAUD_QUEUE: "FRAUD_QUEUE_0",
        OPEN_ESCROW_NSF: "OPEN_ESCROW_NSF_4",
        NSF_MESSAGE: "The source account has insufficient fund",
        TOKEN_INSTRUCTIONS: "Token Instructions:",
        PRESS_OTP_BUTTON: "Press/touch OTP2 button",
        TOKEN_PASSCODE: "Enter the Token passcode into the Token field",
        APPROVALS_CONFIRMATION: "Approvals Confirmation",
        APPROVALS_DETAILS: "Details",
        BAD_REQUEST: "BAD_REQUEST"
    },
}

export default kAPPROVALS;
