
const data = {
    userId: ""
}

export function maskUserId(s: string) {
    data.userId = s;
    return maskMiddleExceptBoundaryOneOrTwo(s);
}

export function unMaskUserId() {
    return data.userId;
}


export function maskMiddleText(s: string): string {
    let asteriskCount = s.length - 2;
    let asterisk = '';
    let regex = /(\d{1})(\d{1,})(\d{1})/;
    if (s.length > 7) {
        regex = /(\d{2})(\d{1,})(\d{2})/;
        asteriskCount -= 2;
    }
    for (let i = 0; i < asteriskCount; i++)asterisk += '*';

    return s.replace(regex, '$1' + asterisk + '$3');
}

// Masking Rules
// 12 -> 12   123456 -> 1****6   1234567 -> 12***67
function maskMiddleExceptBoundaryOneOrTwo(s: string) {
    const len = s.length;
    if (len <= 2) {
        return s;
    } else if (len <= 6) {
        return subReplace(s, 1, len - 1);
    } else {
        return subReplace(s, 2, len - 2);
    }
}

// 1121 -> 1121   1234567891011121 -> ************1121
/* function maskAllExceptLastFour(s: string) {
    return subReplace(s, 0, s.length - 4);
} */

// a@citi.com -> a@citi.com   a1@citi.com -> a*@citi.com   a12@citi.com -> a**@citi.com   a123@citi.com -> a**3@citi.com
/* function maskPartOfPrefix(s: string) {
    const len = s.length;
    if (len == 0) {
        console.warn("empty string");
        return s;
    }
    const emailPrefix = s.split("@")[0];
    const emailPrefixLen = emailPrefix.length;
    const emailSuffix = s.split("@")[1];
    if (emailPrefixLen <= 1) {
        return s;
    } else if (emailPrefixLen <= 3) {
        return subReplace(emailPrefix, 1, len) + "@" + emailSuffix;
    } else {
        return subReplace(emailPrefix, 1, len - 1) + "@" + emailSuffix;
    }
} */

// 111-111-1111 -> ***-***-1111
export function maskAllExceptUnderLineAndLastFour(s: string) {
    const len = s.length;
    if (len <= 4) {
        console.warn("invalid telephone:" + s);
    }
    return subReplace(s, 0, s.length - 4, true);
}

//
function subReplace(s: string, start: number, end: number, remainUnderLine?: boolean) {
    if (start < 0 || start > s.length || end < 0 || end > s.length) {
        console.warn("invalid index:" + start + " & " + end + " for string" + s);
        return s;
    }
    var replacement = s.substring(start, end);
    if (!remainUnderLine) {
        replacement = replacement.replace(/\D/g, "*").replace(/\d/g, "*");
    } else {
        replacement = replacement.replace(/[^-]/g, '*');
    }

    replacement = s.substring(0, start) + replacement + s.substring(end);
    return replacement;
}



