import React from "react";
import loginService from "../api/login.api";
import preLoginService from "../api/pre-login.api";
import ContextData from "./context-data.interface";
import forgotPasswordService from '../api/forgot-password';
import verifyTokenService from '../api/verify-token';
import commonApiService from '../api/common.api'
import device from "../device/xdevice";
import securityContext from "./security-context";
import router from './router-context';
import actionRequiredContext from "./action-required-context";
import commonContext from "./common-context";
import tokenManagementApi from "../api/token-management.api";
import transfersPaymentsApi from "../api/payments-transfer.api";
import RemoteCheckDepositsApi from "../api/check-deposits.api";
import toastProvider from "./toast-provider.context";
import internalTransfersApi from '../api/internal-transfers.api';
import wiresAPI from "../api/wires.api";
import loanContext from "./loan-context";
import fxPulseContext from "./fx-pulse-context";
import wireInitiateContext from "./wire-initiate-context";

const ctxData: ContextData = {

    API: {
        PRE_LOGIN_SERVICE: preLoginService,
        LOGIN_SERVICE: loginService,
        FORGOT_PASSWORD_SERVICE: forgotPasswordService,
        VERIFY_TOKEN_SERVICE: verifyTokenService,
        COMMON_API: commonApiService,
        MOBILE_TOKEN_MANAGEMENT: tokenManagementApi,
        PAYMENTS_TRANSFERS: transfersPaymentsApi,
        REMOTE_CHECK_DEPOSITS: RemoteCheckDepositsApi,
        INTERNAL_TRANSFERS: internalTransfersApi,
        WIRES: wiresAPI
        
    },
    DEVICE: device,
    Router: router,
    Security: securityContext,
    ActionRequired: actionRequiredContext,
    CommonContext: commonContext,
    LoanContext: loanContext,
    ToastService: toastProvider,
    FXPulseContext: fxPulseContext,
    WireInitiateContext: wireInitiateContext
}

const ApplicationContext = React.createContext<ContextData>(ctxData);

export default ApplicationContext;