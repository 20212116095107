import http from './api.core';
import { RequestMethod } from './api.core';
import kCONSTANTS from "../common/constants/GENERAL";
import kEVENT_TRACKING from "../common/constants/EVENT_TRACKING";
import { AxiosResponse } from 'axios';


export class TokenManagementAPI {

    tokenManagementSummary(callBack?: Function) {
        // https://mobileuat.citibusinessonline.com/mobileTokenManagementSummaryPage/15/en?_=1650643784890
        // GET https://mobileuat.citibusinessonline.com/mobileTokenManagementSummaryPage/15/en?_=1650650357434
        return http.makeHttpRequest(
            RequestMethod.GET,
            `${window.ROOT}mobileTokenManagementSummaryPage/${kCONSTANTS.APP_VERSION_DIR}/${kCONSTANTS.LANG}?_=${Date.now()}`,
            null,
            null,
            callBack,
            kEVENT_TRACKING.ChannelEnum.MOBILE_TOKEN, "mobileTokenManagement",
            process.env.NODE_ENV === 'production' ? {} : mockTokenManagementSummaryResponse) as Promise<AxiosResponse<any>>;
    }

    tokenRegistrationDetail(callBack?: Function) {
        // https://mobileuat.citibusinessonline.com/mobileTokenManagementListPage/15/en?_=1650643784891
        return http.makeHttpRequest(
            RequestMethod.GET,
            `${window.ROOT}mobileTokenManagementListPage/${kCONSTANTS.APP_VERSION_DIR}/${kCONSTANTS.LANG}?_=${Date.now()}`,
            null,
            null,
            callBack,
            kEVENT_TRACKING.ChannelEnum.MOBILE_TOKEN, "mobileTokenManagement",
            process.env.NODE_ENV === 'production' ? {} : mockTokenRegistrationDetailResponse) as Promise<AxiosResponse<any>>;
    }

    // POST https://mobileuat.citibusinessonline.com/unRegisterTokenSequence/15/en
    // request 
    // {softTokenSerialNumber: "FDM4537645", deviceCode: "34687706935", sequenceNumber: "05"}
    // response
    // {"success":true,"message":""}
    deleteTokenRegistration(postData: any, callBack?: Function) {
        // https://mobileuat.citibusinessonline.com/mobileTokenManagementListPage/15/en?_=1650643784891
        return http.makeHttpRequest(
            RequestMethod.POST,
            `${window.ROOT}unRegisterTokenSequence/${kCONSTANTS.APP_VERSION_DIR}/${kCONSTANTS.LANG}`,
            null,
            { softTokenSerialNumber: postData.serialNumber, deviceCode: postData.deviceCode, sequenceNumber: postData.sequenceNumber },
            callBack,
            kEVENT_TRACKING.ChannelEnum.MOBILE_TOKEN, "mobileTokenManagement",
            process.env.NODE_ENV === 'production' ? {} : { "success": true, "message": "" }) as Promise<AxiosResponse<any>>;
    }

}

const mockTokenRegistrationDetailResponse = {
    "success": true,
    "message": "",
    "body": {
        "header": "Active Registrations",
        "registeredDateHeader": "Registered Date",
        "lastSuccessdulPinAttemptHeader": "Last Successful PIN Attempt",
        "deviceTypeHeader": "Device Type",
        "deviceNameHeader": "Device Name",
        "actionHeader": "Action",
        "notAvailableMeaning": "*Not Available - Please refer to the help for more details",
        "deleteText": "Delete",
        "notAvailable": "Not Available",
        "cancelText": "Cancel",
        "noItemsToDisplay": "No items to display",
        "currentlyActiveList": [
            {
                "registeredDate": 1738082616539,
                "lastSuccessfulPinAttempt": 1738082617998,
                "sortKeylastSuccessfulPinAttempt": 1738082617998,
                "deviceName": "Google Pixel 8 Pro",
                "deviceType": "Android",
                "screenRowIdentifier": -1,
                "serialNumber": "FDX1574272",
                "deviceCode": "05786104115",
                "sequenceNumber": "65",
                "deleteWarning": "You have selected to delete the Mobile Token Registration from device Google Pixel 8 Pro. Select Delete to confirm."
            },
            {
                "registeredDate": 1737481105135,
                "lastSuccessfulPinAttempt": 1738772646264,
                "sortKeylastSuccessfulPinAttempt": 1738772646264,
                "deviceName": "OnePlus HD1901",
                "deviceType": "HD1901",
                "screenRowIdentifier": -1,
                "serialNumber": "FDX1574272",
                "deviceCode": "03603725302",
                "sequenceNumber": "57",
                "deleteWarning": "You have selected to delete the Mobile Token Registration from device OnePlus HD1901. Select Delete to confirm.",
                "pushNotificationStatus": "ENROLLED",
                // "pushNotificationStatusUpdateDatetime": 1738259859510
            },
            {
                "registeredDate": 1738870049273,
                "lastSuccessfulPinAttempt": 1738966440888,
                "sortKeylastSuccessfulPinAttempt": 1738966440888,
                "deviceName": "iPhone",
                "deviceType": "iPhone16 1",
                "screenRowIdentifier": -1,
                "serialNumber": "FDX1574272",
                "deviceCode": "22994070034",
                "sequenceNumber": "75",
                "deleteWarning": "You have selected to delete the Mobile Token Registration from device iPhone. Select Delete to confirm."
            },
            {
                "registeredDate": 1740511623138,
                "lastSuccessfulPinAttempt": 1740674031749,
                "sortKeylastSuccessfulPinAttempt": 1740674031749,
                "deviceName": "iPhone",
                "deviceType": "iPhone14 3",
                "screenRowIdentifier": -1,
                "serialNumber": "FDX1574272",
                "deviceCode": "61694219881",
                "sequenceNumber": "79",
                "deleteWarning": "You have selected to delete the Mobile Token Registration from device iPhone. Select Delete to confirm.",
                "pushNotificationStatus": "ENROLLED",
                "pushNotificationStatusUpdateDatetime": 1740511623975
            },
            {
                "registeredDate": 1740558599927,
                "lastSuccessfulPinAttempt": 1741006951567,
                "sortKeylastSuccessfulPinAttempt": 1741006951567,
                "deviceName": "Xiaomi M2007J1SC",
                "deviceType": "M2007J1SC",
                "screenRowIdentifier": -1,
                "serialNumber": "FDX1574272",
                "deviceCode": "17589272152",
                "sequenceNumber": "80",
                "deleteWarning": "You have selected to delete the Mobile Token Registration from device Xiaomi M2007J1SC. Select Delete to confirm.",
                "pushNotificationStatus": "ENROLLED",
                "pushNotificationStatusUpdateDatetime": 1740558601995
            },
            {
                "registeredDate": 1738966612086,
                "lastSuccessfulPinAttempt": 1741011389808,
                "sortKeylastSuccessfulPinAttempt": 1741011389808,
                "deviceName": "iPhone",
                "deviceType": "iPhone16 1",
                "screenRowIdentifier": -1,
                "serialNumber": "FDX1574272",
                "deviceCode": "08150788639",
                "sequenceNumber": "76",
                "deleteWarning": "You have selected to delete the Mobile Token Registration from device iPhone. Select Delete to confirm."
            },
            {
                "registeredDate": 1739288508321,
                "lastSuccessfulPinAttempt": 1741018911995,
                "sortKeylastSuccessfulPinAttempt": 1741018911995,
                "deviceName": "OnePlus HD1901",
                "deviceType": "HD1901",
                "screenRowIdentifier": -1,
                "serialNumber": "FDX1574272",
                "deviceCode": "05511160404",
                "sequenceNumber": "77",
                "deleteWarning": "You have selected to delete the Mobile Token Registration from device OnePlus HD1901. Select Delete to confirm.",
                "pushNotificationStatus": "ENROLLED",
                "pushNotificationStatusUpdateDatetime": 1739415240367
            }
        ]
    }
}

const mockTokenManagementSummaryResponse = {
    "success": true, "message": "", "body": {
        "header": "Mobile Token Management",
        "summaryText": "Mobile Token Registration Summary", "currentlyActiveText": "Currently Active",
        "availableForUseText": "Available for use",
        "warningMessageText": "Please note: You can only limit of 10, you wi.",
        "buttonText": "View Details", "currentlyActive": 1, "availableForUse": 9
    }
}

const tokenManagementApi = new TokenManagementAPI()

export default tokenManagementApi;