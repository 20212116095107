import { Loading as IcgdsLoading, LoadingProps } from "@citi-icg-172888/icgds-react";
import { useEffect } from "react";
import kCONSTANTS from "../../common/constants/GENERAL";
import _ from "lodash";

const Loading = (p: LoadingProps) => {

    /**
     * ADA Implementation to focus Loading component when it is visible
     */
    useEffect(() => {
        //Add aria label for Spinner image if tip is not there
        if(p.tip === undefined || p.tip === null){
            const loadingSpinnerEle: HTMLElement | null = document.querySelector(".lmn-loading-icon");
            if (loadingSpinnerEle) {
                loadingSpinnerEle.setAttribute('aria-label', kCONSTANTS.LOADING);
            }
        }

        _.delay(() => {
            const loadingEle: HTMLElement | null = document.querySelector(".lmn-loading-horizontal");
            if (loadingEle) {
                loadingEle.setAttribute("tabIndex", "0");
                loadingEle.focus();
            }
        }, 410);

    }, []);

    const ui = () => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <div aria-live="polite" className="loading-tab-container">
                    <IcgdsLoading {...p} />
                </div>
            </div>)
    }

    return ui();
}


export default Loading;