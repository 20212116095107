import { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';
import ApplicationContext from '../../context/application-context';

interface Props {
    value: number;
    timerKey?: string; // If you use multiple countdowns at the same time, please pass a unique timerKey to distinguish countdown timers
    format?: string;
    className?: string;
    reset?: boolean; // reset count down by change this flag
    onFinish?: Function;
    onTick?: (timeLeft: number) => void; // Callback function when the countdown is running
}

const CountDown = (props: Props) => {
    const context = useContext(ApplicationContext);

    const {
        value,
        timerKey,
        format,
        className,
        reset,
        onFinish,
        onTick
    } = props;

    let currentTime = value;

    const [time, setUpdateTime] = useState(value);

    useEffect(() => {
        _startTimer();

        return () => {
            _stopTimer();
        }
    }, []);

    useEffect(() => {
        if (reset === undefined) {
            return;
        }

        console.log('count down - reset count down ', context?.CommonContext.getCountDownTimer(timerKey));

        _stopTimer();
        _startTimer();
    }, [reset]);

    const _formatTime = (time: number) => {
        return moment(time).format(format);
    }

    const _startTimer = () => {
        console.log('count down - start timer');
        const timer = setInterval(() => {
            currentTime = currentTime - 1000;
            if (onTick) {
                onTick(currentTime); // Call the callback with the current time left
            }
            if (currentTime <= 0) {
                if (_.isFunction(onFinish)) {
                    onFinish();
                }

                _stopTimer();
            }

            setUpdateTime(currentTime);
        }, 1000);
        context?.CommonContext.setCountDownTimer(timer, timerKey);

        console.log('count down - start timer success ', timer);
    }

    const _stopTimer = () => {
        console.log('count down - stop timer');

        const timer = context?.CommonContext.getCountDownTimer(timerKey);
        if (!timer) {
            return;
        }

        console.log('count down - stop timer sucess ', timer);
        clearInterval(timer);
        context?.CommonContext.setCountDownTimer(undefined, timerKey);
    }

    return <span aria-hidden="true" className={className}>{_formatTime(time)}</span>

}

CountDown.defaultProps = {
    format: 'HH:mm:ss'
}

export default CountDown;